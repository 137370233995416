import { React, useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import validator from 'validator'
import LoginForm from './AuthenticationComponent'


function ForgotPassword() {
  const [email, setEmail] = useState('');
  const auth = getAuth();
  // const handleChangeEmail=(event)=>{
  //   setEmail(event.target.value);
  //  }
  const triggerResetEmail = async () => {
   
try {
   await sendPasswordResetEmail(auth, email);
    setMailsendalert(true)
    setTimeout(() => {
      setMailsendalert(false) 
    }, 1500);
} catch (error) {
  if (error.code === "auth/user-not-found") {
    setUsernotfound('User not found. Please check your email.');
  } else {
    setUsernotfound ('An error occurred. Please Enter Valid Email.');
  }
}

  };
  const [usernotfound , setUsernotfound] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [mailsendalert, setMailsendalert] = useState(false)
  const loginformdata = [
    {
      commonbtntext: "Click here for",
      commonbtn: "Back to login",
      titletext: "Forgot",
      maintitle: "Password",
      loginbtn: " Forgot Password",
      navLinkforgot: { to: "/forgot" },
      navLinkcommonbtn: { to: "/" },
      onClick: triggerResetEmail,
      handleChangeEmail: onChangeEmail,
      confermerroremail: usernotfound,
      emailvalidator: emailError,
      setopen:mailsendalert  ,
      // disable: isdisable,
    }
  ]
  function onChangeEmail(event) {
    setEmail(event.target.value);
    const email = event.target.value

    if (validator.isEmail(email)) {
      setEmailError(false)
      setUsernotfound('')
    } else {
      setEmailError(true)
      setUsernotfound('Please Enter Valid Email.')
    }
  }
  return (  
    <div>
      <LoginForm
        loginformdata={loginformdata}
      // handleChangeEmail={handleChangeEmail}
      />
    </div>
  )
}

export default ForgotPassword