// import React from 'react'

// const Share = () => 
//     <div>Share page</div>
// export default Share


import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAltSquare } from '@fortawesome/free-solid-svg-icons';


import GeneralScreen from '../Create-Survey-Folder/Update_survey_tabs/GeneralScreen';
import WelComeScreen from '../Create-Survey-Folder/Update_survey_tabs/WelComeScreen';
import NotificationScreen from '../Create-Survey-Folder/Update_survey_tabs/NotificationScreen';
import TeamsScreen from '../Create-Survey-Folder/Update_survey_tabs/TeamsScreen';
import Tabpanel from '../../components/NavigateTab';
import ShareLinks from '../Create-Survey-Folder/Update_survey_tabs/sharelinks';

// const tabdata = [
//   {
//     // itemone: <GeneralScreen />,
//     // itemtwo: <WelComeScreen />,
//     // itemthree: <NotificationScreen />,
//     // itemfourth: <TeamsScreen />,
//     // itemtabone: 'Dark link',
//     // itemtabtwo: 'Embed',
//     // itemtabthree: 'Notification',
//     // itemtabfourth: 'Teams',
   
//   },

// ];
const Share = () => (
  <div>
    <div className='iconcontainer'>
      <FontAwesomeIcon icon={faShareAltSquare} className='iconOfTab' />
      <h1 className='headingofTab'>Share</h1>
    </div>

    <ShareLinks/>
  </div>
);

export default Share;