import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Link,
  Container,
  Typography,
  Stack,
  Divider,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Box from '@mui/material/Box';
import { IoIosEye, IoMdEyeOff } from 'react-icons/io';

import Snackbar from '@mui/material/Snackbar';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import '../../Style/Signup.css';
import { height, width } from '@mui/system';
import { BsApple } from 'react-icons/bs';
import Logo from '../../components/logo/Logo';
import Iconify from '../../components/iconify';
import useResponsive from '../../hooks/useResponsive';
import '../../Style/LoginForm.css';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));
export default function LoginForm({ loginformdata, checkedata }) {
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfermPassword, setShowConfermPassword] = useState(false);
  const [disable, setDisable] = useState(true);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [selection, setSelection] = useState();
  // const isDivVisible = useSelector((state) => state.isDivVisible);

 
  const [state] = useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;

  const YourComponent = () => {
    const [checked, setChecked] = useState(false);
    // const [shakeAnimation, setShakeAnimation] = useState(false);

    const handleChange = () => {
      setChecked(!checked);
    };

    function handleFocus() {
      // Enable the button when either field is focused
      setDisable(false);
    }
    const handleButtonClick = (provider) => {
      // Add logic for Google, Apple, and Facebook button clicks

      // Check if checkbox is unchecked
      if (!checked) {
        // Trigger shake animation
        // setShakeAnimation(true);

        // Reset shake animation after a short delay
        setTimeout(() => {
          // setShakeAnimation(false);
        }, 500); // Adjust the delay as needed
      }
    };
  };

  useEffect(() => {
    // Set focus to the email field when the component is mounted
    if (emailRef.current) {
      setTimeout(() => {
        emailRef.current.focus();
      }, 0);
      // Trigger animation or any other logic for the email field here
    }
  }, []);

  useLayoutEffect(() => {
    // Set focus to the password field when the location changes
    if (passwordRef.current && (location.pathname === '/login' || location.pathname === '/signup')) {
      passwordRef.current.focus();
      passwordRef.current.value = ''; // Set the initial value to an empty string
      // Trigger animation or any other logic for the password field here
    }
  }, [location.pathname]);

  const buttons = (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {/* <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>
          Top-Center
        </Button> */}
      </Box>
    </>
  );
  //   const [checked, setChecked] = useState(true);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };
  return (
    <>
      {loginformdata.map((getdata, id) => (
        <div key={id}>
          {/* <Helmet>
            <title> Login | Survey Slack </title>
          </Helmet> */}
          <StyledRoot>
            {/* {mdUp && (
              <StyledSection sx={{ ml: 'auto' }}>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                  Hi, Welcome Back
                </Typography>
                <img
                  src="https://minimal-kit-react.vercel.app/assets/illustrations/illustration_login.png"
                  alt="login"
                />
              </StyledSection>
            )} */}
            <Container style={{ display: 'contents' }} maxWidth="sm" sx={{ ml: 'unset' }}>
              <StyledContent>
                {/* <div>
              <Logo
              sx={{
                
              }}
            />
                <Typography variant="h4" gutterBottom>
                  {getdata.titletext} {getdata.maintitle}
                </Typography>
                <Typography variant="body2" sx={{ mb: 5 }}>
                  {getdata.commonbtntext} {''}
                  <Link underline="none" variant="subtitle2" {...getdata.navLinkcommonbtn} component={RouterLink}>
                    {getdata.commonbtn}
                  </Link>
                </Typography>
                </div> */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  <Logo sx={{ height: '100%', width: '100px', flexGrow: 0 }} />
                  <Box sx={{ flexBasis: '80%', flexGrow: 1, textAlign: 'left' }}>
                    <Typography variant="h4">
                      {getdata.titletext} {getdata.maintitle}
                    </Typography>
                    <Typography variant="body2">
                      {getdata.commonbtntext} {''}
                      <Link
                        underline="none"
                        className="navlink"
                        variant="subtitle2"
                        {...getdata.navLinkcommonbtn}
                        component={RouterLink}
                      >
                        {getdata.commonbtn}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: 500 }}>
                  {buttons}
                  <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={getdata.setopen}
                    message="An email has been sent with a link to reset your SurveySlack password."
                    key={vertical + horizontal}
                  />
                </Box>
                <Stack style={{ display: location.pathname === '/forgot' ? 'none' : '' }} direction="row" spacing={2}>
                  <Button onClick={getdata.googleLoginin} fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                  </Button>
                  <Button
                    className="d-none"
                    onClick={getdata.faceBook}
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                  >
                    <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                  </Button>
                  <Button
                    className="d-none"
                    onClick={getdata.apple}
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                  >
                    <BsApple icon="eva:twitter-fill" color="black" width={25} height={25} />
                  </Button>
                </Stack>
                <Divider style={{ display: location.pathname === '/forgot' ? 'none' : '' }} sx={{ my: 3 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    OR
                  </Typography>
                </Divider>
                <div>
                  <Stack spacing={3}>
                    <TextField
                      onChange={getdata.handleChangeEmail}
                      value={getdata.emailid}
                      name="email"
                      label="Email address"
                      type="email"
                      inputRef={emailRef}
                    />
                    {getdata.emailvalidator && (
                      <div className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
                        {getdata.ConfermEmail}
                      </div>
                    )}
                    <TextField
                      style={{
                        display: location.pathname !== '/login' && location.pathname !== '/signup' ? 'none' : '/',
                        transition: 'background-color 50000s ease-in-out 0s',
                      }}
                      onChange={getdata.handleChangePassword}
                      name="password"
                      label="password"
                      value={getdata.Password}
                      inputRef={passwordRef}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="conferm_passowrd"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <IoIosEye /> : <IoMdEyeOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
                      {getdata.passwordError}
                    </div>

                    <TextField
                      style={{
                        display: location.pathname !== '/signup' && location.pathname !== '/signup' ? 'none' : '',
                        transition: 'background-color 50000s ease-in-out 0s',
                      }}
                      onChange={getdata.handleCheckPassword}
                      name="password"
                      label="Confirm password"
                      id="cPassword"
                      value={getdata.ConfermPasswordValue}
                      type={showconfermPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="conferm_passowrd"
                              onClick={() => setShowConfermPassword(!showconfermPassword)}
                              edge="end"
                            >
                              {showconfermPassword ? <IoIosEye /> : <IoMdEyeOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {getdata.ConfermPasswordError && (
                      <div id="checkpassword" className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
                        {/* {getdata.ConfermPasswordError} */}
                        Passwords do not match
                      </div>
                    )}
                    <FormGroup
                      style={{
                        display: location.pathname !== '/signup' && location.pathname !== '/signup' ? 'none' : '',
                      }}
                    >
                      {/* <Checkbox
                        checked={getdata.checked}
                        onChange={getdata.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }} 
                        label= {
                          <Typography>
                            You agree to the Terms of <Link href="/terms-of-use">Survey Slack</Link> and <Link href="/terms-of-use">Privacy Notice</Link>.
                          </Typography>
                        }                       
                      /> */}

                      {/* <FormControlLabel
                        
                        control={ <Checkbox
                        checked={getdata.checked}
                        onChange={getdata.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }} 
                        className={getdata.shake}
                        />}
                        label={
                          <Typography className={getdata.shake}>
                            You agree to the Terms of <Link href="/terms-of-use">Survey Slack</Link> and <Link href="/terms-of-use">Privacy Notice</Link>.
                          </Typography>
                        }
                      /> */}
                      <Typography className={getdata.shake} style={{ marginBottom: '10px' }}>
                        I've read and accept the{' '}
                        <Link className="navlink" href="https://app.surveyslack.com/terms">
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link className="navlink" href="https://app.surveyslack.com/privacy">
                          Privacy Notice
                        </Link>
                        .
                      </Typography>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Receive helpful tips, updates, and exclusive offers. You can easily opt-out of these emails at any time from your account page."
                      />
                    </FormGroup>
                    {/* {isDivVisible && (
                      <div className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
                        {getdata.confermerror}
                      </div>
                    )} */}
                    {/* <div className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
                        {getdata.confermerror}
                      </div> */}
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <Link
                      style={{ display: location.pathname !== '/login' ? 'none' : '' }}
                      variant="subtitle2"
                      underline="none"
                      className="navlink"
                      {...getdata.navLinkforgot}
                      component={RouterLink}
                    >
                      {getdata.forgotbtn}
                    </Link>
                  </Stack>

                  <LoadingButton
                    className="bgButton"
                    disabled={getdata.disable}
                    fullWidth
                    size="large"
                    type="submit"
                    color="success"
                    variant="contained"
                    onClick={getdata.onClick}
                  >
                    {getdata.loginbtn}
                  </LoadingButton>
                </div>
              </StyledContent>
            </Container>
          </StyledRoot>
        </div>
      ))}
    </>
  );
}
