import * as React from 'react';
import { useState } from 'react';
// import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';
import InputLabel from '@mui/material/InputLabel';
// import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
// import Box from '@mui/material/Box';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';


export default function DropDown({ dropdown }) {
    const [checked, setLabel] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(false);

    // console.log(selectedOptions)
    const handleLabel = () => {
        setLabel(!checked);
    };

    return (
        <>
            <div className='dropdown-component d-flex '>
                {dropdown.map((getdropdown, index) =>
                    <>
                        {/* <div className={` ${getdropdown.dropdownclass}   `}> */}
                        <FormControl style={{ display: 'flex', marginLeft: '0px' }} className={getdropdown.dropdownclass} key={index} sx={{ m: 1, minWidth: getdropdown.width }}>
                            <InputLabel id={getdropdown.id1}>{getdropdown.title}</InputLabel>

                            <Select className=' dropdown-select justify-space-evenly rounded-0'
                                labelId="demo-controlled-open-select-label"

                                id={getdropdown.id2}

                                // onClose={handleClose}
                                onClose={getdropdown.handleClose}
                                onOpen={getdropdown.handleOpen}
                                label={getdropdown.label}
                                onChange={getdropdown.handleChange}
                                value={getdropdown.selectvalue}
                            >

                                <MenuItem value="">
                                    {/* <em>None</em> */}
                                </MenuItem>
                                {/* <div className={` ${getdropdown.dropdownclass}   `}> */}
                                {/* <MenuItem className='justify-content-between' value={10}>{getdropdown.val1}<GradeTwoToneIcon/></MenuItem> */}
                                <MenuItem className={` ${getdropdown.spacebetween} ${getdropdown.dropdownclass}  `} value={getdropdown.value1}>{getdropdown.val1}{getdropdown.icon1}</MenuItem>
                                <MenuItem className={` ${getdropdown.spacebetween} ${getdropdown.dropdownclass}`} value={getdropdown.value2}>{getdropdown.val2}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.spacebetween} ${getdropdown.dropdownclass} `} value={getdropdown.value3}>{getdropdown.val3}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value4}>{getdropdown.val4}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value5}>{getdropdown.val5}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value6}>{getdropdown.val6}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value7}>{getdropdown.val7}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value8}>{getdropdown.val8}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value9}>{getdropdown.val9}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value10}>{getdropdown.val10}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value11}>{getdropdown.val11}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value12}>{getdropdown.val12}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value13}>{getdropdown.val13}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value14}>{getdropdown.val14}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value15}>{getdropdown.val15}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value16}>{getdropdown.val16}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value17}>{getdropdown.val17}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value18}>{getdropdown.val18}{getdropdown.icon2}</MenuItem>
                                <MenuItem className={`${getdropdown.select} ${getdropdown.dropdownclass}`} value={getdropdown.value19}>{getdropdown.val19}{getdropdown.icon2}</MenuItem>
                            </Select>

                        </FormControl>


                    </>
                )}
                {/* <div className='d-flex'>
                    <FormGroup className='justify-content-center'>
                        <FormControlLabel checked={checked}
                            onChange={handleLabel} className='m-0' control={<Checkbox defaultChecked />} label="Add rating Label" />
                    </FormGroup>

                    {checked &&
                        <>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField id="outlined-basic" label="label" variant="outlined" />
                            </Box>
                            <Button className='m-1 p-1' variant="outlined">Create</Button>
                        </>
                    }

                </div> */}
            </div>
            <div className='d-flex align-items-center justify-content-evenly'>
                {/* <div>scale</div> */}
                {/* <Box
                    sx={{
                        '& > legend': { mt: 2 },
                    }}
                >

                    <Typography component="legend">10 stars</Typography>
                    <Rating name="customized-10" defaultValue={2} max={10} />
                </Box> */}
                {/* <div>label</div> */}
            </div>
            {/* {selectedOptions &&  <div>Div 1</div>} */}
            {/* {selectedOptions === 20 && <div>Div 2</div>}
            {selectedOptions === 30 && <div>Div 3</div>}
            {selectedOptions === 40 && <div>Div 3</div>}
            {selectedOptions === 50 && <div>Div 3</div>}
            {selectedOptions === 60 && <div>Div 3</div>}
            {selectedOptions === 70 && <div>Div 3</div>} */}

        </>
    );
}