
import { RiSurveyLine } from 'react-icons/ri';
import { RxDashboard } from 'react-icons/rx';
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: 'app',
  //   icon: <RxDashboard style={{fontSize:'20px'}}/>,
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: ' My Surveys',
    path: '/my-surveys',
    icon:<RiSurveyLine style={{fontSize:'20px'}}/>,
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];


export default navConfig;

