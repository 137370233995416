// import React from 'react'

// const TeamsScreen = () =>
//     <div style={{display:"grid",placeItems:"center" ,alignContent:"center",marginTop:"30px"}}>
//         <h3 style={{color:"lightblue"}} >Teams Is an Enterprise Feature</h3>
//         <div>Upgrade to Enterprise and collaborate with other users on SurveyPlanet.</div>
//     </div>


// export default TeamsScreen


import React from 'react'
import General from './Generalcomponent'

const NotificationScreen = () => {
  const formdata = [
    {
      mainheading: 'Teams Is an Enterprise Feature',
      subheading: 'Upgrade to Enterprise and collaborate with other users on SurveyPlanet.',
      input15:'d-none',
      input12:'d-none',
      input3:'d-none',
      input4:'d-none',
      input6:'d-none',
      input13:'d-none',
      input14:'d-none',
      input7:'d-none',
      input8:'d-none',
      input16:'d-none',
      Save: 'Save Settings',
    },
  ];
  return (
    <div>
      <General formdata={formdata}/>
    </div>
  )
}

export default NotificationScreen