


import React from 'react';
import { MdOutlineIncompleteCircle, MdPreview } from 'react-icons/md';
import Tabpanel from '../../components/NavigateTab';
import GeneralScreen from '../Create-Survey-Folder/Update_survey_tabs/GeneralScreen';

// import WelComeScreen from '../Create-Survey-Folder/settingtabs/WelComeScreen';
// import NotificationScreen from '../Create-Survey-Folder/settingtabs/NotificationScreen';
// import TeamsScreen from '../Create-Survey-Folder/settingtabs/TeamsScreen';

const tabdata = [
  {
    itemone: <GeneralScreen />,
    // itemtwo: <WelComeScreen />,
    // itemthree: <NotificationScreen />,
    // itemfourth: <TeamsScreen />,
    itemtabone: 'Desktop',
    itemtabtwo: 'Tablet',
    itemtabthree: 'Mobile',
    itemtabfourth: 'Teams',
  },

];
const Preview = () => 
<div>
<div className='iconcontainer'>
  <MdPreview icon={MdPreview} className='iconOfTab' />
  <h1 className='headingofTab'>Preview</h1>
</div>
<Tabpanel tabdata={tabdata} />
</div>

    // <div>
    //   <div > <Tabpanel tabdata={tabdata} /></div>
    // </div>



export default Preview