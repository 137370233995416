import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', width: '100%', zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }} >
      <CircularProgress />
    </Box>
  );
}
