import React, { useState, useEffect, memo } from 'react';
import { FcMenu, FcEmptyTrash } from 'react-icons/fc';
import { useParams } from 'react-router';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { FaStarOfLife, FaCopy, FaTrash } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import DeleteModal from '../../DeleteModal';
import PositionedSnackbar from '../../../components/component-part/Alert';
// import SurveyViewpopoup from '../../../components/Popup-folder/Question_section/EditQuestionmodal';
import CreateSurveyPage from '../../../components/Popup-folder/Question_section/CreateQuestionmodal';
import Loader from '../../../components/component-part/Loader';
import { createSurveypopup, callSurveyview, updateQuestionList } from '../../../Redux/actions';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';

const QuestionList = () => {
  const { id } = useParams();
  const refreshquestiondata = useSelector((state) => state.refreshquestion);
  const createquesvariable = useSelector((state) => state.createQueAlert);
  const isEditMode = useSelector((state) => state.isEditMode);
  // const questionId = useSelector((state) => state.questionId);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const createsurvey = [
    {
      surveytitle: isEditMode ? 'Edit Question' : 'Create new Question',
      input1placeholder: 'Enter Your Question',
      inputlabel1: 'Question',
      inputlabel2: 'Answer type',
      inputlabel3: 'Who Answer?',
      textareadisplay: 'd-none',
      widthclass: '80%',
      display: 'inline-block',
    },
  ];

  const surveyClick = () => {
    dispatch(createSurveypopup());
  };
  // const [questionId, setQuestionId] = useState(null);

  // const openSurveyview = (surveyData) => {
  //   dispatch(callSurveyview(surveyData));
  // };
  const [questionId, setQuestionId] = useState(null);
  const openSurveyview = (setQuestionId, surveyData) => {
    setQuestionId(surveyData.questionid);
    dispatch(createSurveypopup(surveyData, true));
  };

  const successdeleteAlertforDelete = [
    {
      type: 'Question Deleted Successfully',
    },
  ];
  const getupdatedlist = useSelector((state) => state.updatequestionlist);
  const [deletquestion, setDeletequestion] = useState(0);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);

  const [deleteState, setdeleteState] = useState(false);
  const [queslist, setQueslist] = useState([]);
  const [surveyTitle, setSurveyTitle] = useState('');
  const [quesorder, setQuesOrder] = useState([]);
  const [Noquestion, setNoQuestion] = useState(false);
  const [orderval, setOrderval] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionListGET}`);
        if (response.ok) {
          const data = await response.json();
          setQueslist(data.data);
          console.log(data.data);
          fetchOrder();
        } else {
          throw new Error('Failed to fetch questions');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuestions();
  }, [getupdatedlist, deletquestion, refreshquestiondata, createquesvariable]);

  // const fetchQuestions = async () => {
  //   try {
  //     const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionListGET}`);
  //     if (response.ok) {
  //       const data = await response.json();
  //       setQueslist(data.data);
  //       fetchOrder();
  //     } else {
  //       console.error('Failed to fetch questions. Status code:', response.status);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching questions:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchQuestions();
  // }, [getupdatedlist, deletquestion, refreshquestiondata, createquesvariable]);

  const filteredQuestionList = queslist.filter((survey) => survey.surveyid === id);

  const fetchOrder = async () => {
    try {
      const response = await fetch(`https://app.surveyslack.com/api/surveytask/api/v1/surveylist.php?surveyid=${id}`);
      if (response.ok) {
        const data = await response.json();
        setSurveyTitle(data.surveyid.survey_title);
        setQuesOrder(JSON.parse(data.surveyid.question_order));
      } else {
        throw new Error('Failed to fetch survey');
      }
    } catch (error) {
      console.error('Error fetching survey:', error);
    }
  };

  // question order update and post oredr to survey api
  // const handleDragEnd = async (result) => {
  //   if (!result.destination) {
  //     return;
  //   }

  //   const updatedSurveyList = Array.from(filteredQuestionList);
  //   const [reorderedSurvey] = updatedSurveyList.splice(result.source.index, 1);
  //   updatedSurveyList.splice(result.destination.index, 0, reorderedSurvey);

  //   const questionIds = updatedSurveyList.map((obj) => obj.questionid);
  //   setQuesOrder(questionIds);

  //   try {
  //     const payload = {
  //       question_order: JSON.stringify(questionIds),
  //     };

  //     const endpoint = `${BASE_URL}${API_ENDPOINTS.surveyEdit}?surveyid=${id}`;
  //     const response = await fetch(endpoint, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (response.ok) {
  //       console.log(`Survey with ID ${id} question order saved successfully.`);
  //     } else {
  //       console.error('Failed to save question order:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while saving the question order:', error);
  //   }
  // };
  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const updatedList = Array.from(filteredQuestionList);
    const [reorderedItem] = updatedList.splice(result.source.index, 1);
    updatedList.splice(result.destination.index, 0, reorderedItem);

    const questionIds = updatedList.map((obj) => obj.questionid);
    setQuesOrder(questionIds);

    try {
      const payload = {
        question_order: JSON.stringify(questionIds),
      };

      const endpoint = `${BASE_URL}${API_ENDPOINTS.surveyEdit}?surveyid=${id}`;
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log(`Survey with ID ${id} question order saved successfully.`);
      } else {
        console.error('Failed to save question order:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while saving the question order:', error);
    }
  };

  const handelCloneQues = async (
    originalQuestionId,
    surveyID,
    questext,
    anser,
    ansType,
    createdDate,
    modifiedDate,
    compulsory,
    multipleAnswer
  ) => {
    function generateQuestionid() {
      return Math.random().toString().slice(2, 7);
    }
    const setquestionid = generateQuestionid();

    try {
      const endpoint = API_ENDPOINTS.questionCreatePOST;
      console.log('Cloning question with ID:', setquestionid); // Log question ID for debugging
      const res = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          questionid: setquestionid,
          surveyid: surveyID,
          question_text: questext,
          question_type: ansType,
          created_date: createdDate,
          modified_date: modifiedDate,
          multiple_answer: multipleAnswer,
          answer: anser,
          compulsory,
        }),
      });

      if (res.ok) {
        // Fetch latest questions after successful clone
        const fetchQuestions = async () => {
          try {
            const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionListGET}`);
            if (response.ok) {
              const data = await response.json();
              setQueslist(data.data);
              fetchOrder();
            } else {
              throw new Error('Failed to fetch questions');
            }
          } catch (error) {
            console.error(error);
          }
        };

        await fetchQuestions();

        dispatch(updateQuestionList());

        // Update the order of questions
        const updatedOrder = [...quesorder];
        const originalQuestionIndex = updatedOrder.findIndex((questionId) => questionId === originalQuestionId);
        updatedOrder.splice(originalQuestionIndex + 1, 0, setquestionid);
        setQuesOrder(updatedOrder);

        // Update the backend with the new question order
        const payload = {
          question_order: JSON.stringify(updatedOrder),
        };
        const updateEndpoint = `${BASE_URL}${API_ENDPOINTS.surveyEdit}?surveyid=${surveyID}`;
        const updateResponse = await fetch(updateEndpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (updateResponse.ok) {
          fetchOrder();
          console.log(`Survey with ID ${surveyID} question order updated successfully.`);
        } else {
          console.error('Failed to update question order:', updateResponse.statusText);
        }

        console.log('Survey Data posted successfully');
      } else {
        const errorText = await res.text();
        console.error('Survey not created:', res.statusText, errorText);
      }
    } catch (err) {
      console.error('Error cloning question:', err);
    }
  };

  // const handelCloneQues = async (
  //   id,
  //   surveyID,
  //   questext,
  //   anser,
  //   ansType,
  //   createdDate,
  //   modifiedDate,
  //   compulsory
  // ) => {
  //   console.log(id, surveyID, questext, anser, ansType, compulsory);

  //   function generateQuestionid() {
  //     return Math.random().toString().slice(2, 7);
  //   }
  //   const setquestionid = generateQuestionid();

  //   try {
  //     const endpoint = API_ENDPOINTS.questionCreatePOST;
  //     const res = await fetch(`${BASE_URL}${endpoint}`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         questionid: setquestionid,
  //         surveyid: surveyID,
  //         question_text: questext,
  //         question_type: ansType,
  //         created_date: createdDate,
  //         modified_date: modifiedDate,
  //         multiple_answer: "multiple_answer",
  //         answer: anser,
  //         compulsory
  //       }),
  //     });

  //     if (res.status === 200) {
  //       dispatch(updateQuestionList());
  //       console.log('Survey Data posted successfully');
  //       setTimeout(() => {}, 1000);
  //     } else if (res.status === 400) {
  //       alert('Survey not created due to some issue');
  //     }
  //   } catch (err) {
  //     alert('Survey not created due to some issue');
  //     console.log(err);
  //   }
  // };

  const openDeleteModal = (questionId) => {
    setQuestionIdToDelete(questionId);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setQuestionIdToDelete(null);
  };

  // question delete from survey
  const handelDeletequestion = async (questionid) => {
    // Set the state to open the delete modal
    setQuestionIdToDelete(questionid);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    setDeleteModalOpen(false);
    try {
      const response = await fetch(
        `https://app.surveyslack.com/api/surveytask/api/v1/surveyquestionary.php?questionid=${questionIdToDelete}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        console.log(`Survey with ID ${questionIdToDelete} was deleted successfully.`);

        const newArray = queslist.filter((item) => item.questionid !== questionIdToDelete);
        setQuesOrder(newArray.map((item) => item.questionid));

        try {
          const endpoint = API_ENDPOINTS.surveyEdit;
          const updateResponse = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              question_order: JSON.stringify(newArray.map((item) => item.questionid)),
            }),
          });

          if (updateResponse.ok) {
            setQueslist((prevQueslist) => prevQueslist.filter((item) => item.questionid !== questionIdToDelete));
            console.log(`Survey with ID ${id} question order saved successfully.`);
            fetchOrder();

            setdeleteState(true);

            // Set deleteState to false after 2 seconds
            setTimeout(() => {
              setdeleteState(false);
            }, 2000);
          }
        } catch (error) {
          console.error('An error occurred while saving the question order:', error);
        }
      }
    } catch (error) {
      console.error('An error occurred while deleting the survey:', error);
    }
  };

  // fetch order from api
  localStorage.setItem('QuestionOrder', quesorder);

  useEffect(() => {
    // Fetch the order from local storage
    const storedOrder = localStorage.getItem('questionOrder');
    if (storedOrder) {
      // Parse the stored order back into an array and set it as the initial state
      setQuesOrder(JSON.parse(storedOrder));
    }
  }, []);

  const fetchorder = () => {
    if (!Array.isArray(quesorder)) {
      // If quesorder is not an array, set a default value
      const fetchedQuesorder = ['4334'];
      setOrderval(fetchedQuesorder);
    } else {
      // If quesorder is an array, use it as is
      const data = quesorder.map((item) => item);
      setOrderval(data);
    }
  };

  const sortedArray = filteredQuestionList.sort((a, b) => {
    const aIndex = quesorder.indexOf(a.questionid);
    const bIndex = quesorder.indexOf(b.questionid);
    return aIndex - bIndex;
  });

  const array = sortedArray.map((item) => item.questionid);

  console.log('newArray', array);

  localStorage.setItem('quesOrder', array);

  const successdeleteAlert = [
    {
      type: 'Question created successfully',
    },
  ];
  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        handleDelete={handleDeleteConfirmation}
        questionIdToDelete={questionIdToDelete}
      />
      {deleteState && <PositionedSnackbar alerttext={successdeleteAlertforDelete} />}
      {/* <SurveyViewpopoup /> */}
      <CreateSurveyPage createsurvey={createsurvey} />
      <div className="text-center position-relative" id="question-listpge" style={{ margin: '10px' }}>
        <div className="row align-items-start mt-4">
          {/* <div className="col-md-6 col-lg-6 createQuebtns justify-content-end d-flex">
            <button onClick={surveyClick} className="btn btn-primary me-2 mb-2 mb-lg-1" type="button">
              Create New Question
            </button>
            <button className="btn btn-primary mb-2 mb-lg-1" type="button">
              Create From Template
            </button>
          </div> */}
        </div>

        {Noquestion ? (
          <Loader />
        ) : (
          <div className="row mt-3">
            <div className="col-lg-12 question-card" id="questionCard">
              {filteredQuestionList >= 0 && (
                <>
                  <div style={{ padding: '20vh 0' }}>
                    <h4>There are no questions for this survey.</h4>
                    <p style={{ color: '#42a5f5' }}>
                      To create a new question, click on the 'Create New Question' button.
                    </p>
                  </div>
                </>
              )}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="queslist">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {sortedArray.map((survey, index) => (
                        <Draggable key={survey.questionid} draggableId={survey.questionid} index={index}>
                          {(provided) => (
                            <div
                              className=" my-2"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <Card
                                style={{ borderRadius: '20px' }}
                                className="p-2 flex-row"
                             
                              >
                                {/* {survey.questionid} */}
                                <div className="d-flex align-items-center" style={{ marginLeft: '-20px' }}>
                                  <FcMenu
                                    className="me-2"
                                    style={{ fontSize: '25px', color: 'gray', cursor: 'grab' }}
                                  />
                                </div>
                                <div className="d-flex w-100 justify-content-between">
                                  <div className="col-md-10 col-lg-10">
                                    <div
                                      // onKeyDown={openSurveyview}
                                      tabIndex={-1}
                                      className="bg-transparent border-0 w-100 h-100 d-flex align-items-center"
                                      // type="button"
                                      // onClick={() =>
                                      //   openSurveyview({
                                      //     questionid: survey.questionid,
                                      //     answer: survey.answer,
                                      //     compulsory: survey.compulsory,
                                      //     question_type: survey.question_type,
                                      //     question_text: survey.question_text,
                                      //   })
                                      // }
                                    >
                                      {/* <Card.Header className='d-flex justify-content-between align-items-center' style={{
                                        borderBottom: 'none',
                                        backgroundColor: 'transparent'
                                      }}>
                                        <div className='d-flex text-muted'>
                                   
                                        </div>
                                      </Card.Header> */}
                                      <Card.Body className="d-flex align-items-start" style={{ padding: '0px' }}>
                                        <Avatar
                                          style={{ backgroundColor: '#34897a' }}
                                          className="me-2"
                                          sx={{
                                            width: 24,
                                            height: 24,
                                            fontSize: '16px',
                                          }}
                                        >
                                          {/* question numbering create */}
                                          {index + 1}
                                        </Avatar>
                                        <p className="m-0" style={{ textAlign: 'start' }}>
                                          {survey.question_text}
                                          {survey.compulsory === '1' && (
                                            <Tooltip
                                              title="Required Question"
                                              placement="top"
                                              style={{
                                                position: 'absolute',
                                                right: '0',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                              }}
                                            >
                                              <FaStarOfLife
                                                style={{ color: 'red', height: '10px', marginTop: '-10px' }}
                                              />
                                            </Tooltip>
                                          )}
                                        </p>
                                      </Card.Body>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-lg-2 Q_typesection position-relative">
                                    <Tooltip title="Question-type" className="Question-type" placement="top">
                                      <div
                                        style={{
                                          whiteSpace: 'nowrap',
                                          border: '1px solid lightgray ',
                                          borderRadius: ' 10px',
                                          padding: '4px 7px',
                                          backgroundColor: '#d2d2d24f',
                                        }}
                                      >
                                        <>
                                          {survey.question_type === 12 ? 'Multiple Choice' : null}
                                          {survey.question_type === 1 ? 'Long Answers' : null}
                                          {survey.question_type === 2 ? 'Short Answers  ' : null}
                                          {survey.question_type === 3 ? 'Rating' : null}
                                          {survey.question_type === 4 ? 'DropDown' : null}
                                          {survey.question_type === 5 ? 'Linear-scale' : null}
                                          {survey.question_type === 6 ? 'Range Slider' : null}
                                          {survey.question_type === 7 ? 'Date & Time ' : null}
                                          {survey.question_type === 8 ? 'File Upload' : null}
                                          {survey.question_type === 9 ? 'Image-choice' : null}
                                          {survey.question_type === 10 ? 'Matrix-MCQ' : null}
                                          {survey.question_type === 11 ? 'Matrix-Checkbox' : null}
                                          {console.log(survey.question_type)}
                                        </>
                                      </div>
                                    </Tooltip>
                                    {/* <Tooltip title="Question-type" className="Question-type" placement="top">
  <div
    style={{
      whiteSpace: 'nowrap',
      border: '1px solid lightgray ',
      borderRadius: ' 10px',
      padding: '4px 7px',
      backgroundColor: '#d2d2d24f',
    }}
    key={survey.question_type}
  >
    {(() => {
      const questionType = String(survey.question_type).trim();
      switch (questionType) {
        case '12':
          return 'Multiple Choice';
        case '1':
          return 'Long Answers';
        case '2':
          return 'Short Answers';
        case '3':
          return 'Rating';
        case '4':
          return 'DropDown';
        case '5':
          return 'Linear-scale';
        case '6':
          return 'Range Slider';
        case '7':
          return 'Date & Time';
        case '8':
          return 'File Upload';
        case '9':
          return 'Image-choice';
        case '10':
          return 'Matrix-MCQ';
        case '11':
          return 'Matrix-Checkbox';
        default:
          return `Unknown question type: ${questionType}`; // Set unknown type with the actual value
      }
    })()}
  </div>
</Tooltip> */}
                                    <div className="d-flex">
                                      <Tooltip title="Edit">
                                        <IconButton
                                          className="deletequestion mt-2"
                                          onClick={
                                            () =>
                                              openSurveyview(
                                                setQuestionId,
                                                {
                                                  questionid: survey.questionid,
                                                  answer: survey.answer,
                                                  compulsory: survey.compulsory,
                                                  question_type: survey.question_type,
                                                  question_text: survey.question_text,
                                                  multiple_answer: survey.multiple_answer,
                                                },
                                                true
                                              ) // pass true as the second argument to indicate edit mode
                                          }
                                          style={{ backgroundColor: '#d2d2d24f', marginLeft: '10px' }}
                                          aria-label="delete"
                                        >
                                          <EditIcon style={{ color: '#34897a' }} />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Clone">
                                        <IconButton
                                          className="deletequestion mt-2"
                                          onClick={() =>
                                            handelCloneQues(
                                              survey.questionid, // id
                                              survey.surveyid, // surveyID
                                              survey.question_text, // questext
                                              survey.answer, // anser
                                              survey.question_type, // ansType
                                              survey.created_date, // createdDate
                                              survey.modified_date, // modifiedDate
                                              survey.compulsory, // compulsory
                                              survey.multiple_answer // updated to multipleAnswer
                                            )
                                          }
                                          style={{ backgroundColor: '#d2d2d24f', marginLeft: '10px' }}
                                          aria-label="delete"
                                        >
                                          <FaCopy style={{ color: '#34897a' }} />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Delete">
                                        <IconButton
                                          className="deletequestion mt-2"
                                          onClick={() => handelDeletequestion(survey.questionid)}
                                          style={{ backgroundColor: '#d2d2d24f', marginLeft: '10px', color: '#34897a' }}
                                          aria-label="delete"
                                        >
                                          <FaTrash style={{ color: '#34897a' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        )}
        {createquesvariable && <PositionedSnackbar alerttext={successdeleteAlert} />}
      </div>
    </>
  );
};

export default memo(QuestionList);
