// import { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import { useDispatch } from 'react-redux';
// import { MdEdit } from 'react-icons/md';
// import { AiFillDelete, AiOutlineCloseCircle } from 'react-icons/ai';
// import Modal from '@mui/material/Modal';
// import { styled } from '@mui/material/styles';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
// import { Box, Link, Card, Typography, CardContent } from '@mui/material';
// import Zoom from '@mui/material/Zoom';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import { NavLink } from 'react-router-dom';
// import { BsShareFill } from 'react-icons/bs';
// import { motion } from 'framer-motion';
// // import SkeletonAnination from '../../../components/component-part/SkeletonAnination';
// import { openSurveyfunction } from '../../../Redux/actions';

// const styleshare = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };
// const StyledCardMedia = styled('div')({
//   position: 'relative',
//   paddingTop: 'calc(100% * 3 / 4)',
// });
// const StyledTitle = styled(Link)({
//   height: 44,
//   overflow: 'hidden',
//   WebkitLineClamp: 2,
//   display: '-webkit-box',
//   WebkitBoxOrient: 'vertical',
// });

// const StyledCover = styled('img')({
//   top: 0,
//   width: '100%',
//   height: '100%',
//   objectFit: 'cover',
//   position: 'absolute',
// });

// SurveyCard.propTypes = {
//   post: PropTypes.object.isRequired,
//   index: PropTypes.number,
//   surveydata: PropTypes.object.isRequired,
//   handleDelete: PropTypes.func,
// };

// export default function SurveyCard({ surveydata, index, handleDelete }) {
//   // const bgimg = surveydata.map((item)=>item.background)
//   const dispatch = useDispatch();
//   const latestPostLarge = index === 0;
//   const latestPost = index === 1 || index === 2;

//   const getSurveyfunction = (surveyId) => {
//     localStorage.setItem('SURVEY_ID', surveyId);
//     dispatch(openSurveyfunction(surveyId));
//   };

//   const onClickHandler = (surveyId) => {
//     // Ensure handleDelete is called as a function
//     handleDelete(surveyId);
//     console.log("surveyid",surveyId);
//   };
//   const urlRef = useRef(null);
//   const copyToClipboard = () => {
//     if (urlRef.current) {
//       urlRef.current.select();
//       document.execCommand('copy');
//     }
//   };

//   const [skeletion, setSkelton] = useState(false);
//   const [shareid, setShareId] = useState();
//   const [openshare, setOpenshare] = useState(false);
//   const [sharelink, setShareLink] = useState('');

//   const handleSharesurvey = (surevyid) => {
//     setOpenshare(true);
//     setShareId(surevyid);
//   };

//   const handleClose = () => setOpenshare(false);

//   const handelChangeInput = (e) => {
//     setShareLink(e.target.value);
//   };

//   const [isEditable, setIsEditable] = useState(false);
//   const handleEditClick = () => {
//     setIsEditable(!isEditable);
//   };
//   console.log("surveydata",surveydata);

//   return (
//     <div className="row g-3">
//       {surveydata.map((getsurveydata) => (
//         <>
//           <div className="col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 position-relative">
//             <motion.div
//               initial={{ opacity: 0, scale: 0.9 }}
//               animate={{ opacity: 1, scale: 1 }}
//               transition={{ ease: 'easeOut', duration: 1 }}
//             >
//               {/* Your content goes here */}
//               <div>
//                 {skeletion && (
//                   <div className="your-card ">
//                     <div className="your-card__image" />
//                     <div className="your-card__content" />
//                     <p className="your-paragraph" style={{ height: '20px' }} />
//                     <p className="your-paragraph" style={{ height: '50px' }} />
//                   </div>
//                 )}
//                 {/* <SkeletonAnination /> */}
//                 <Card
//                   onClick={() =>
//                     getSurveyfunction(getsurveydata.surveyid, getsurveydata.survey_title, getsurveydata.background, getsurveydata.fonts)
//                   }
//                   id="surveyid"
//                   className="rounded-2 surveycardui "
//                   sx={{ position: 'relative' }}
//                 >
//                   <NavLink
//                     // to={`/surveycreatelayout/setting?id=${getsurveydata.surveyid}`}>
//                     to={`/surveycreatelayout/setting/${getsurveydata.surveyid}`}
//                   >
//                     <Stack
//                       style={{ zIndex: '8', overflow: 'hidden' }}
//                       className="position-absolute top-0 right-0 w-100 pr-1 p-3"
//                       spacing={1}
//                       alignItems="center"
//                     >
//                       <Stack className="w-100 justify-content-end" direction="row" spacing={1}>
//                         {JSON.parse(getsurveydata.survey_label).map((item, index) => (
//                           <Chip
//                             key={index}
//                             style={{
//                               backgroundColor: index % 2 === 0 ? '#f25445bf' : '#5380c7',
//                               whiteSpace: 'nowrap',
//                               overflow: 'hidden',
//                               dispatch: 'inline-flex',
//                               textOverflow: 'ellipsis',
//                             }}
//                             className="rounded-2 ms-2"
//                             label={item}
//                             color="primary"
//                           />
//                         ))}
//                       </Stack>
//                     </Stack>

//                     <StyledCardMedia
//                       sx={{
//                         ...(latestPostLarge || latestPost),
//                       }}
//                     >
//                       {/* {getsurveydata.background && (
//                         <StyledCover
//                           className="hover-effect"
//                           // style={{ objectFit: 'unset' }}
//                           src={`${getsurveydata.background}?v=${new Date().getTime()}`}
//                         />
//                       )}
//                       {!getsurveydata.background && (
//                         <StyledCover
//                           className="hover-effect"
//                           // style={{ objectFit: 'unset' }}
//                           style={{ backgroundColor: '#4e4e4e' }}
//                         />
//                       )} */}
//             {getsurveydata.fonts && getsurveydata.fonts[1] ? (
//   <StyledCover
//     className="hover-effect"
//     style={{ backgroundColor: getsurveydata.fonts[1] || '#616569' }}
//   />
// ) : (
//   <StyledCover
//     className="hover-effect"
//     style={{ backgroundColor: '#4e4e4e' }}
//   />
// )}

//                       {/* <StyledCover
//                         className="hover-effect"
//                         // style={{ objectFit: 'unset' }}
//                         src={getsurveydata.background ? `${getsurveydata.background}?v=${new Date().getTime()}` : undefined}
//                         style={{ backgroundColor: getsurveydata.background ? 'transparent' : '#1b1b1b' }}
//                       /> */}
//                     </StyledCardMedia>
//                   </NavLink>
//                   <div style={{ position: 'relative' }}>
//                     <div>
//                       <NavLink
//                         className="surveyDescription"
//                         to={`/surveycreatelayout/setting/${getsurveydata.surveyid}`}
//                       >
//                         <CardContent
//                           sx={{
//                             pb: 1,
//                             ...((latestPostLarge || latestPost) && {}),
//                           }}
//                         >
//                           <Typography className='mb-3' gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
//                             {getsurveydata.created_date}
//                           </Typography>
//                           <StyledTitle
//                             color="inherit"
//                             variant="subtitle2"
//                             underline="hover"
//                             className="titlecss"
//                             style={{ height: '20px' }}
//                           >
//                             <Tooltip style={{ color: 'black' }} title={getsurveydata.survey_title} followCursor>
//                               <Box className="surveyheading">
//                                 {getsurveydata.survey_title}
//                                 {getsurveydata.status}
//                               </Box>
//                             </Tooltip>
//                             <br />
//                           </StyledTitle>
//                         </CardContent>
//                       </NavLink>
//                     </div>

//                     <div className="rediect_class">
//                       <Typography>
//                         <Tooltip TransitionComponent={Zoom} title="Share">
//                           <IconButton onClick={() => handleSharesurvey(getsurveydata.surveyid)}>
//                             <BsShareFill className="tootip-icon" />
//                           </IconButton>
//                           <Modal
//                             className="shareModal"
//                             open={openshare}
//                             onClose={handleClose}
//                             aria-labelledby="modal-modal-title"
//                             aria-describedby="modal-modal-description"
//                           >
//                             <Box sx={styleshare} className="position-relative py-4 ">
//                               <button
//                                 className="p-0"
//                                 style={{
//                                   position: 'absolute',
//                                   top: '10px',
//                                   right: '10px',
//                                   border: 'none',
//                                   backgroundColor: 'transparent',
//                                 }}
//                                 onClick={handleClose}
//                                 type="button"
//                               >
//                                 <AiOutlineCloseCircle className="h4 m-0" />
//                               </button>
//                               <Typography
//                                 style={{ fontSize: '1rem' }}
//                                 className="text-start"
//                                 id="modal-modal-title"
//                                 variant="h6"
//                                 component="h2"
//                               >
//                                 Direct Link
//                               </Typography>
//                               <span style={{ fontSize: '0.9rem' }}>
//                                 Copy this link to your clipboard or share it on a social network
//                               </span>
//                               <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//                                 <div
//                                   className="d-flex align-items-center rounded-2 p-0 position-relative"
//                                   style={{ backgroundColor: 'rgb(223 223 223 / 20%)' }}
//                                 >
//                                   <input
//                                     ref={urlRef}
//                                     type="text"
//                                     id="url-input"
//                                     onChange={handelChangeInput}
//                                     // value={`https://react.surveyslack.com/surveyform/${shareid}`}
//                                     value={
//                                       window.location.hostname === 'localhost'
//                                         ? `http://localhost:${window.location.port}/surveyform/${shareid}`
//                                         : `https://app.surveyslack.com/surveyform/${shareid}`
//                                     }
//                                     readOnly={!isEditable}
//                                     style={{
//                                       width: '100%',
//                                       border: 'none',
//                                       backgroundColor: 'rgb(223 223 223 / 20%)',
//                                       padding: '6px',
//                                       borderRadius: '5px 0 0 5px',
//                                     }}
//                                   />
//                                   <MdEdit
//                                     onClick={handleEditClick}
//                                     style={{
//                                       position: 'absolute',
//                                       right: '138px ',
//                                       top: '9px',
//                                       fontSize: '22px',
//                                       color: '#4c4c4c',
//                                       backgroundColor: '#ececec ',
//                                       display:'none'
//                                     }}
//                                   />
//                                   <button
//                                     type="button"
//                                     onClick={copyToClipboard}
//                                     className="m-0 px-5 py-2 d-flex align-items-center rounded-0 border-0"
//                                     style={{ backgroundColor: '#34897a', color: 'white', fontSize: '14px' }}
//                                   >
//                                     {/* <AiFillCopy className='ms-2' />  */}
//                                     &nbsp;Copy
//                                   </button>
//                                 </div>
//                                 <div className="text-center d-flex align-items-center justify-content-start mt-1">
//                                   {/* Share to : */}
//                                   <NavLink
//                                     target="_blank"
//                                     to={
//                                       window.location.hostname === 'localhost'
//                                         ? `http://localhost:${window.location.port}/surveyform/${shareid}`
//                                         : `https://app.surveyslack.com/surveyform/${shareid}`
//                                     }
//                                     style={{ color: '#68cc5c', textDecoration: 'underline', fontSize: '12px' }}
//                                   >
//                                     Open In new Window
//                                   </NavLink>
//                                   {/* <a style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" href={'https://twitter.com'}>
//                                 <BsTwitter className='mx-2 my-0 h4' />
//                               </a>
//                               <a style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" href={'https://www.facebook.com'}>
//                                 <BsFacebook className='mx-2 my-0 h4' />
//                               </a>
//                               <a style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" href={'https://instagram.com'}>
//                                 <AiFillInstagram className='mx-2 my-0 h3' />
//                               </a> */}
//                                 </div>
//                               </Typography>
//                             </Box>
//                           </Modal>
//                         </Tooltip>
//                         <Tooltip TransitionComponent={Zoom} title="Delete">
//                           <IconButton onClick={() => handleDelete(getsurveydata.surveyid)}>
//                             <AiFillDelete className="tootip-icon" />
//                           </IconButton>
//                         </Tooltip>
//                       </Typography>
//                     </div>
//                   </div>

//                   {/* 
//                   <div className='d-flex align-items-center justify-content-between p-4 pt-0'>
//                     <Typography className='m-0' gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
//                     {getsurveydata.survey_enddate}
//                     </Typography>
                    
//                   </div> */}
//                 </Card>
//               </div>
//             </motion.div>
//           </div>
//         </>
//       ))}
//     </div>
//   );
// }


import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import { AiFillDelete, AiOutlineCloseCircle } from 'react-icons/ai';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Box, Link, Card, Typography, CardContent } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import { BsShareFill } from 'react-icons/bs';
import { motion } from 'framer-motion';
import { openSurveyfunction } from '../../../Redux/actions';

const styleshare = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 1.5 / 4)',
  
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

SurveyCard.propTypes = {
  surveydata: PropTypes.array.isRequired, // Ensure surveydata is an array of objects
  handleDelete: PropTypes.func.isRequired,
};

export default function SurveyCard({ surveydata, handleDelete }) {
  const dispatch = useDispatch();

  const getSurveyfunction = (surveyId) => {
    localStorage.setItem('SURVEY_ID', surveyId);
    dispatch(openSurveyfunction(surveyId));
  };

  const onClickHandler = (surveyId) => {
    handleDelete(surveyId);
  };

  const urlRef = useRef(null);

  const copyToClipboard = () => {
    if (urlRef.current) {
      urlRef.current.select();
      document.execCommand('copy');
    }
  };

  const [skeletion, setSkelton] = useState(false);
  const [shareid, setShareId] = useState();
  const [openshare, setOpenshare] = useState(false);
  const [sharelink, setShareLink] = useState('');

  const handleSharesurvey = (surevyid) => {
    setOpenshare(true);
    setShareId(surevyid);
  };

  const handleClose = () => setOpenshare(false);

  const handelChangeInput = (e) => {
    setShareLink(e.target.value);
  };

  const [isEditable, setIsEditable] = useState(false);
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

   
  return (
    <div className="row g-3">
      {surveydata.map((getsurveydata, index) => (
        
     

        <div key={index} className="col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 position-relative">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ ease: 'easeOut', duration: 1 }}
          >
            <Card
              onClick={() => getSurveyfunction(getsurveydata.surveyid)}
              id="surveyid"
              className="rounded-2 surveycardui"
              sx={{ position: 'relative' }}
            >
              <NavLink to={`/survey/${getsurveydata.surveyid}/settings`}>
                <Stack
                  style={{ zIndex: '8', overflow: 'hidden' }}
                  className="position-absolute top-0 right-0 w-100 pr-1 p-3"
                  spacing={1}
                  alignItems="center"
                >
                  <Stack className="w-100 justify-content-end" direction="row" spacing={1}>
                    {JSON.parse(getsurveydata.survey_label).map((item, index) => (
                      <Chip
                        key={index}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#f25445bf' : '#5380c7',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          dispatch: 'inline-flex',
                          textOverflow: 'ellipsis',
                        }}
                        className="rounded-2 ms-2"
                        label={item}
                        color="primary"
                      />
                    ))}
                  </Stack>
                </Stack>

                <StyledCardMedia>
                <StyledCover
  className="hover-effect"
  style={{
    backgroundColor: getsurveydata.fonts ? JSON.parse(getsurveydata.fonts)[2] : '#4e4e4e',
  }}
/>
                </StyledCardMedia>
              </NavLink>

              <div style={{ position: 'relative' }}>
                <div>
                  <NavLink className="surveyDescription" to={`/survey/${getsurveydata.surveyid}/settings`}>
                    <CardContent style={{padding:'15px'}}>
                      <Typography className='mb-3' gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
                        {getsurveydata.created_date}
                      </Typography>
                      <StyledTitle
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        className="titlecss"
                        style={{ height: '20px' }}
                      >
                        <Tooltip style={{ color: 'black' }} title={getsurveydata.survey_title} followCursor>
                          <Box className="surveyheading">
                            {getsurveydata.survey_title}
                            {getsurveydata.status}
                          </Box>
                        </Tooltip>
                      </StyledTitle>
                    </CardContent>
                  </NavLink>
                </div>

                <div className="rediect_class">
                  <Typography>
                    <Tooltip TransitionComponent={Zoom} title="Share">
                      <IconButton onClick={() => handleSharesurvey(getsurveydata.surveyid)}>
                        <BsShareFill className="tootip-icon" />
                      </IconButton>
                      <Modal
                        className="shareModal"
                        open={openshare}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={styleshare} className="position-relative py-4 ">
                          <button
                            className="p-0"
                            style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onClick={handleClose}
                            type="button"
                          >
                            <AiOutlineCloseCircle className="h4 m-0" />
                          </button>
                          <Typography
                            style={{ fontSize: '1rem' }}
                            className="text-start"
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Direct Link
                          </Typography>
                          <span style={{ fontSize: '0.9rem' }}>
                            Copy this link to your clipboard or share it on a social network
                          </span>
                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div
                              className="d-flex align-items-center rounded-2 p-0 position-relative"
                              style={{ backgroundColor: 'rgb(223 223 223 / 20%)' }}
                            >
                              <input
                                ref={urlRef}
                                type="text"
                                id="url-input"
                                onChange={handelChangeInput}
                                value={
                                  window.location.hostname === 'localhost'
                                    ? `http://localhost:${window.location.port}/surveyform/${shareid}`
                                    : `https://app.surveyslack.com/surveyform/${shareid}`
                                }
                                readOnly={!isEditable}
                                style={{
                                  width: '100%',
                                  border: 'none',
                                  backgroundColor: 'rgb(223 223 223 / 20%)',
                                  padding: '6px',
                                  borderRadius: '5px 0 0 5px',
                                }}
                              />
                              <MdEdit
                                onClick={handleEditClick}
                                style={{
                                  position: 'absolute',
                                  right: '138px ',
                                  top: '9px',
                                  fontSize: '22px',
                                  color: '#4c4c4c',
                                  backgroundColor: '#ececec ',
                                  display: 'none'
                                }}
                              />
                              <button
                                type="button"
                                onClick={copyToClipboard}
                                className="m-0 px-5 py-2 d-flex align-items-center rounded-0 border-0"
                                style={{ backgroundColor: '#34897a', color: 'white', fontSize: '14px' }}
                              >
                                &nbsp;Copy
                              </button>
                            </div>
                            <div className="text-center d-flex align-items-center justify-content-start mt-1">
                              <NavLink
                                target="_blank"
                                to={
                                  window.location.hostname === 'localhost'
                                    ? `http://localhost:${window.location.port}/surveyform/${shareid}`
                                    : `https://app.surveyslack.com/surveyform/${shareid}`
                                }
                                style={{ color: '#68cc5c', textDecoration: 'underline', fontSize: '12px' }}
                              >
                                Open In new Window
                              </NavLink>
                            </div>
                          </Typography>
                        </Box>
                      </Modal>
                    </Tooltip>
                    <Tooltip TransitionComponent={Zoom} title="Delete">
                      <IconButton onClick={() => handleDelete(getsurveydata.surveyid)}>
                        <AiFillDelete className="tootip-icon" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </div>
              </div>
            </Card>
          </motion.div>
        </div>
      ))}
    </div>
  );
}
