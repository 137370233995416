
import {React } from 'react';
import General from './Generalcomponent'

const WelComeScreen = () => {
  const formdata = [
    {
      mainheading: 'Welcome Settings',
      subheading: ' Communicate to your participants at the beginning and end of your survey.',
      surveytitle: 'Welcome Message',
      successmessage: 'Success Message',
      successlogo: 'Success Image',
      welcome: 'Welcome Image',
      Display: 'Display',
      option1: 'One Question at time',
      option2: 'All Question at once',
      Language: 'Language',
      Language1: 'English',
      Language2: 'French',
      Language3: 'Russian',
      logourl: 'Logo Click URL',
      Categories: 'Categories',
      Save: 'Save Settings',
      input1:'d-none',
      input12:'d-none',
      input4:'d-none',
      input3:'d-none',
      // input6:'d-none',
      input7:'d-none',
      input8:'d-none',
      input9:'d-none',
      input13:'d-none',
      input20:'d-none',
      input21:'d-none',
      input23:'d-none',
      input:'d-none',
    },
  ];


  return (
    <div>
      <General formdata={formdata}/>
    </div>
  )
}

export default WelComeScreen

