import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../Style/Modal.css';

const DeleteModal = ({ isOpen, toggleModal, handleDelete }) => {
  const handleKeyDown = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };

  return (
    
    <div
      style={{
        position: 'fixed',
        top: '1px',
        zIndex: 20,
        width: '100%',
        height: '100%',
        background: '#80808059',
        right: 0,
        display: isOpen ? 'block' : 'none',
      }}
      id="deleteConfirmationModal"
      className="warning confirm-alert slide-in"
    >
      <div className='deleteMainContainer'
        role="alert"
        style={{
          zIndex: 1001,
          boxSizing: 'border-box',
          maxWidth: '403px',
          minWidth: '200px',
          padding: '34px 20px 20px 60px',
          position: 'absolute',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 15px',
          backgroundColor: 'rgb(243, 245, 249)',
          color: 'rgb(56, 63, 80)',
          right: '9px',
          top: '100px',
          opacity: 1,
        }}
      >
        <div
          className="icon-wrapper"
          style={{
            position: 'absolute',
            left: '-22px',
            right: 0,
            bottom: 0,
            width: '50px',
            height: '100%',
            borderRadius: '3px 0 0 3px',
            backgroundColor: '#da1923',
          }}
        >
          <FontAwesomeIcon icon={faTrash} style={{ color: 'white', fontSize: '24px', padding: '13px',marginTop:'70px' }} />
        </div>
        <h3
          id="deleteQuestion"
          style={{
            fontSize: '20px',
            textAlign: 'left',
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          Are you sure?
        </h3>
        <p
          id="alertMessage"
          className="alert-message"
          style={{ fontSize: '16px', color: '#333' }}
        >
          Do you really want to delete these records? This process cannot be undone.
        </p>
        <nav style={{ textAlign: 'center' }}>
          <ul
            style={{
              listStyleType: 'none',
              display: 'flex',
              justifyContent: 'flex-around',
              marginTop: '20px',
              padding: 0,
            }}
          >
            <li>
              <div
                id="confirmDeleteLogoButton"
                className="button confirmOfDeleteModal confirm-button"
                role="button"
                tabIndex="0"
                style={{
                  padding: '15px',
                  fontSize: '14px',
                  color: '#fff',
                  backgroundColor: '#da1923',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width:'130px',
                  textAlign:'center'
                }}
                onClick={handleDelete}
                onKeyDown={(event) => handleKeyDown(event, handleDelete)}
              >
                Delete
              </div>
            </li>
            <li>
              <div
                id="cancelDeleteButton"
                className="button gray cancelofDeletModal    "
                role="button"
                tabIndex="0"
                style={{
                  padding: '15px',
                  fontSize: '14px',
                  color: '#fff',
                  backgroundColor: '#bcbcbc',
                  border: 'none',
                  borderRadius: '5px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                  width:'130px',
                  textAlign:'center'
                }}
                onClick={toggleModal}
                onKeyDown={(event) => handleKeyDown(event, toggleModal)}
              >
                Cancel
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default DeleteModal;



// import React from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import '../Style/Modal.css';

// const DeleteModal = ({ isOpen, toggleModal, handleDelete }) => 
  
//     <Modal className='deletemodal' isOpen={isOpen} toggle={toggleModal}>
//         <ModalHeader className="text-center modalfordelete" toggle={toggleModal}>
//           <div className="icon-box">
//           <FontAwesomeIcon icon="fa-solid fa-trash "  />
//           </div>
//           <h2>Are you sure?</h2>
//         </ModalHeader>
//         <ModalBody className='contentofdelete'>
//           Do you really want to delete these records? This process cannot be undone.
//         </ModalBody>
//         <ModalFooter>
//           <Button color="secondary" onClick={toggleModal}>
//             Cancel
//           </Button>{' '}
//           <Button color="danger" onClick={handleDelete}>
//             Delete
//           </Button>
//         </ModalFooter>
//       </Modal>
    
  
  
//   export default DeleteModal;