import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Box, Typography, Button, Select, MenuItem, TextField, IconButton, Divider } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FaStarOfLife, FaCopy, FaTrash } from 'react-icons/fa';
import { closeQuestionBranchingModal } from '../../../Redux/actions';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const QuestionBranchingModal = (isOpen, rule, closeModal ) => {
  const dispatch = useDispatch();
  const isEditModeForRule = useSelector((state) => state.isEditModeForRule);
  const ruleId = useSelector((state) => state.ruleId);
  const ruleIdfor = useSelector((state) => state.ruleId);
  const isQuestionBranchingModalOpen = useSelector((state) => state.showQuestionBranchingModal);
  const Surveyid = localStorage.getItem('SURVEY_ID');
  const [answerConditions, setAnswerConditions] = useState([]);
  const [showConditionDropdown, setShowConditionDropdown] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('If');
  const [answers, setAnswers] = useState([]);
  const [answerCondition, setAnswerCondition] = useState('Is');
  const [answerText, setAnswerText] = useState('');
  const [questionType, setQuestionType] = useState(null);
  const [answerConditionForShortLong, setAnswerConditionForShortLong] = useState('Is empty');
  const [rules, setRules] = useState([]);
  const [isAddRuleEnabled, setIsAddRuleEnabled] = useState(false);
  const [condition, setCondition] = useState('And');
  const [Action, setAction] = useState('go to');
  const [actionValue, setActionValue] = useState('');
  const [logicalOperator, setLogicalOperators] = useState([]);
  const [questionText, setQuestionText] = useState('');
  




  useEffect(() => {
    if (isEditModeForRule && ruleId) {
      fetchAnswers();
      fetchRuleData();

    }
  }, [isEditModeForRule, ruleId]);
  
  
  const fetchRuleData = async () => {
    try {
      const response = await fetch(`${BASE_URL}${API_ENDPOINTS.QuestionBranching}?rule_id=${ruleId}`);
      if (!response.ok) {
        console.error('Failed to fetch rule data');
        return;
      }
  
      const responseData = await response.json();
      const ruleData = responseData.data[0];
  
      if (!ruleData) {
        console.error('No data found in rules response');
        return;
      }
  
      const questionId = ruleData.question_id;
      const questionResponseForRule = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?questionid=${questionId}`);
      const questionData = await questionResponseForRule.json();
      console.log(questionData, "questionData");
  
      const questionType = questionData.data[0].question_type;
      
      setSelectedQuestion(questionId);
      setSelectedCondition(ruleData.condition || 'If');
  
      const answerConditions = JSON.parse(ruleData.answer_conditions || '[]');
      const answerTexts = JSON.parse(ruleData.answer_texts || '[]');
      const logicalOperators = JSON.parse(ruleData.logical_operators || '[]');
  
      let rules = [];
  
      if (questionType === 1 || questionType === 2) {
        for (let i = 0; i < answerConditions.length; i += 2) {
          const condition1 = answerConditions[i];
          const condition2 = answerConditions[i + 1];
          const logicalOperator = logicalOperators[Math.floor(i / 2)];
  
          rules.push({
            id: `${ruleData.rule_id}-${Math.floor(i / 2)}`,
            type: questionType,
            answerCondition: condition1,
            answerConditionForShortLong: condition2,
            answerText: answerTexts[Math.floor(i / 2)] || '',
            condition: ruleData.condition || 'If',
            action: ruleData.action || 'go to',
            actionValue: ruleData.action_value || '',
            logicalOperator,
          });
        }
      } else if (questionType === 5) {
        // Handle questionType 5 with varying answerTexts length
        if (answerTexts.length === 2) {
          const [start, end] = answerTexts;
          const range = Array.from({ length: end - start + 1 }, (_, i) => start + i).join(', ');
  
          rules.push({
            id: ruleData.rule_id,
            type: questionType,
            answerCondition: answerConditions[0] || 'Is',
            answerText: range,
            condition: ruleData.condition || 'If',
            action: ruleData.action || 'go to',
            actionValue: ruleData.action_value || '',
            logicalOperator: logicalOperators[0] || '',
          });
        } else if (answerTexts.length > 0) {
          // If answerTexts length is not 2, use the available elements
          const answerText = answerTexts.join(', ');
  
          rules.push({
            id: ruleData.rule_id,
            type: questionType,
            answerCondition: answerConditions[0] || 'Is',
            answerText,
            condition: ruleData.condition || 'If',
            action: ruleData.action || 'go to',
            actionValue: ruleData.action_value || '',
            logicalOperator: logicalOperators[0] || '',
          });
        } else {
          // If answerTexts is empty
          rules.push({
            id: ruleData.rule_id,
            type: questionType,
            answerCondition: answerConditions[0] || 'Is',
            answerText: '', // No answerText to display
            condition: ruleData.condition || 'If',
            action: ruleData.action || 'go to',
            actionValue: ruleData.action_value || '',
            logicalOperator: logicalOperators[0] || '',
          });
        }
      } else {
        rules = answerConditions.map((condition, index) => {
          const answerText = answerTexts[index] || '';
  
          return {
            id: `${ruleData.rule_id}-${index}`,
            type: questionType,
            answerCondition: condition || 'Is',
            answerText,
            condition: ruleData.condition || 'If',
            action: ruleData.action || 'go to',
            actionValue: ruleData.action_value || '',
            logicalOperator: logicalOperators[index] || '',
          };
        });
      }
  
      setRules(rules);
    } catch (error) {
      console.error('Error fetching rules:', error);
    }
  };
  
  // const fetchRuleData = async () => {
  //   try {
  //     // Fetch rule data by rule_id
  //     const response = await fetch(`${BASE_URL}${API_ENDPOINTS.QuestionBranching}?rule_id=${ruleId}`);
      
  //     if (!response.ok) {
  //       console.error('Failed to fetch rule data');
  //       return;
  //     }
  
  //     const responseData = await response.json();
  //     const ruleData = responseData.data[0];
  
  //     if (!ruleData) {
  //       console.error('No data found in rules response');
  //       return;
  //     }
      
  //     const questionId = ruleData.question_id;
  
  //     // Fetch question data with the question ID
  //     const questionResponseForRule = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?questionid=${questionId}`);
  //     const questionData = await questionResponseForRule.json();  // Parse question response data
  //     console.log(questionData, "questionData");
  
  //     // Get the question_type from the question data
  //     const questionType = questionData.data[0].question_type;  // Extract question_type directly
  
  //     // Set rule data in state
  //     setSelectedQuestion(questionId);
  //     setSelectedCondition(ruleData.condition || 'If');
  
  //     // Parse JSON fields
  //     const answerConditions = JSON.parse(ruleData.answer_conditions || '[]');
  //     const answerTexts = JSON.parse(ruleData.answer_texts || '[]');
  //     const logicalOperators = JSON.parse(ruleData.logical_operators || '[]');
  
  //     // Handle cases with multiple conditions, texts, and logical operators
  //     setAnswerCondition(answerConditions.length > 0 ? answerConditions[0] : '');
  //     setAnswerText(answerTexts.join(', '));
  //     setLogicalOperators(logicalOperators.join(', '));

  //     // Set action and action value
  //     setAction(ruleData.action || 'go to');
  //     setActionValue(ruleData.action_value || '');
  
  //     // Adjusted rules mapping logic with questionType
  //     let rules = [];
  
  //     if (questionType === 1 || questionType === 2) {
  //       for (let i = 0; i < answerConditions.length; i += 2) {
  //         const condition1 = answerConditions[i];
  //         const condition2 = answerConditions[i + 1];
  //         const logicalOperator = logicalOperators[Math.floor(i / 2)];
  
  //         rules.push({
  //           id: `${ruleData.rule_id}-${Math.floor(i / 2)}`, // Unique ID for each rule
  //           type: questionType,  // Use the questionType
  //           answerCondition: condition1,
  //           answerConditionForShortLong: condition2,
  //           answerText: answerTexts[Math.floor(i / 2)],
  //           condition: ruleData.condition || 'If',
  //           action: ruleData.action || 'go to',
  //           actionValue: ruleData.action_value || '',
  //           logicalOperator,
  //         });
  //       }
  //     } 
 
  //     else {
        
  //       rules = answerConditions.map((condition, index) => {
  //         const answerText = answerTexts[index] || '';  // Adjust to handle missing texts
  //         console.log(questionType, "questionType");  // Log questionType
  
  //         return {
  //           id: `${ruleData.rule_id}-${index}`, // Unique ID for each rule
  //           type: questionType,  // Use the questionType
  //           answerCondition: condition || 'Is',
  //           answerText,  // Use adjusted answerText
  //           condition: ruleData.condition || 'If',
  //           action: ruleData.action || 'go to',
  //           actionValue: ruleData.action_value || '',
  //           logicalOperator: logicalOperators[index] || '',
  //         };
  //       });
  //     }
  
  //     setRules(rules);
  //   } catch (error) {
  //     console.error('Error fetching rules:', error);
  //   }
  // };

  
  const fetchQuestion = async (queId) => {
    try {
      const questionResponse = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?questionid=${queId}`);
      const questionData = await questionResponse.json();
      
      if (questionData.data && questionData.data.length > 0) {
        const { question_type: questionType, question_text: questionText } = questionData.data[0];
        console.log('Question Data:', questionData.data);
        console.log('Question Type:', questionType);
  
        setQuestionType(questionType);
        setQuestionText(questionText);
      } else {
        console.error('No question data found for questionid:', queId);
      }
    } catch (error) {
      console.error('Error fetching question by QueId:', error);
    }
  };
  
  
  // Set the states for condition, answerCondition, answerText, action, and actionValue

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?surveyid=${Surveyid}`);
        if (response.ok) {
          const responseData = await response.json();
          setQuestions(responseData.data);
          console.log(responseData.data,"setQuestions");
        } else {
          throw new Error('Failed to fetch questions');
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };
    fetchQuestions();
  }, [Surveyid]);
  

    const fetchAnswers = async () => {
      if (!selectedQuestion) return;
  
      try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?questionid=${selectedQuestion}`);
        if (!response.ok) throw new Error('Failed to fetch answers');
  
        const responseData = await response.json();
        const selectedQuestionData = questions.find((question) => question.questionid === selectedQuestion);
        const questionType = selectedQuestionData?.question_type;
        setQuestionType(questionType || null);
  
        if (questionType === 1 || questionType === 2) {
          setAnswers([]);
          return;
        }
  
        if (questionType === 5) {
          if (responseData.data && responseData.data[0] && responseData.data[0].answer) {
            try {
              const parsedAnswers = JSON.parse(responseData.data[0].answer);
              if (Array.isArray(parsedAnswers) && parsedAnswers.length === 2) {
                const [start, end] = parsedAnswers;
                // Generate range from start to end
                const range = Array.from({ length: end - start + 1 }, (_, i) => start + i);
                setAnswers(range);
              } else {
                setAnswers([]);
              }
            } catch (error) {
              console.error('Error parsing answer:', error);
              setAnswers([]);
            }
          } else {
            setAnswers([]);
          }
          return;
        }
  
        if (questionType === 3) {
          if (responseData.data && responseData.data[0] && responseData.data[0].answer) {
            try {
              const parsedRating = JSON.parse(responseData.data[0].answer);
              console.log("Parsed Rating:", parsedRating);
  
              // Ensure that the last element is an array
              const lastArray = Array.isArray(parsedRating[3]) ? parsedRating[3] : [];
              console.log("Last Array:", lastArray);
              setAnswers(lastArray);
            } catch (error) {
              console.error('Error parsing answer:', error);
              setAnswers([]);
            }
          } else {
            setAnswers([]);
          }
          return;
        }
  
        if (responseData.data && responseData.data[0] && responseData.data[0].answer) {
          try {
            const parsedAnswers = JSON.parse(responseData.data[0].answer);
            setAnswers(Array.isArray(parsedAnswers) ? parsedAnswers : []);
          } catch (error) {
            console.error('Error parsing answer:', error);
            setAnswers([]);
          }
        } else {
          setAnswers([]);
        }
      } catch (error) {
        console.error('Error fetching answers:', error);
        setAnswers([]);
      }
    };
  

    useEffect(()=>{
    fetchAnswers();
  }, [selectedQuestion, questions]);
  
  

  // useEffect(() => {
  //   const fetchAnswers = async () => {
  //     if (!selectedQuestion) return;
  
  //     try {
  //       const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}?questionid=${selectedQuestion}`);
  //       if (response.ok) {
  //         const responseData = await response.json();
  //         const selectedQuestionData = questions.find((question) => question.questionid === selectedQuestion);
  //         const questionType = selectedQuestionData?.question_type;
  //         setQuestionType(questionType || null);
  
  //         if (questionType === 2 || questionType === 1) {
  //           setAnswers([]);
  //           return;
  //         }
  
  //         if (questionType === '3') {
  //           if (responseData.data && responseData.data[1] && responseData.data[1].answer) {
  //             try {
  //               const parsedRating = JSON.parse(responseData.data[1].answer);
  //               setAnswers(parsedRating[3] || []);
  //             } catch (error) {
  //               console.error('Error parsing answer:', error);
  //               setAnswers([]);
  //             }
  //           } else {
  //             setAnswers([]);
  //           }
  //           return;
  //         }
  
  //         if (responseData.data && responseData.data[0] && responseData.data[0].answer) {
  //           setAnswers(JSON.parse(responseData.data[0].answer) || []);
  //         } else {
  //           setAnswers([]);
  //         }
  //       } else {
  //         throw new Error('Failed to fetch answers');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching answers:', error);
  //     }
  //   };
  
  //   fetchAnswers();
  // }, [selectedQuestion, questions]);


  useEffect(() => {
    setIsAddRuleEnabled(selectedQuestion !== '');
  }, [selectedQuestion, answerText, questionType]);

  // Function to generate a random 10-character ID
  const generateRandomId = (length = 10) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

 
  const handleSaveRule = async () => {
    try {
      // Validate required fields
      if (!selectedQuestion || !selectedCondition || rules.length === 0) {
        throw new Error('Required fields are missing');
      }
  
      // Determine rule ID
      const ruleid = isEditModeForRule ? ruleId : generateRandomId();
  
      // Group rules based on unique criteria
      const groupedRules = rules.reduce((acc, rule) => {
        const key = `${rule.question_id}-${rule.rule_id}-${rule.survey_id}-${rule.condition}-${rule.action}-${rule.action_value}`;
  
        if (!acc[key]) {
          acc[key] = {
            ...rule,
            answer_conditions: [],
            answer_texts: [],
            logical_operators: [],
          };
        }
  
        console.log("rule.type", rule.type);
  
        if (rule.type === 1 || rule.type === 2) {
          if (rule.answerCondition) {
            acc[key].answer_conditions.push(rule.answerCondition);
          }
          if (rule.answerConditionForShortLong) {
            acc[key].answer_conditions.push(rule.answerConditionForShortLong);
          }
        } else {
          if (rule.answerCondition) {
            acc[key].answer_conditions.push(rule.answerCondition);
          }
          if (rule.answerConditionForShortLong) {
            acc[key].answer_conditions.push(rule.answerConditionForShortLong);
          }
        }
  
        console.log("rule.answerConditionForShortLong:", rule.answerConditionForShortLong);
  
        acc[key].answer_texts.push(rule.answerText || '');
        acc[key].logical_operators.push(rule.logicalOperator.toUpperCase());
  
        return acc;
      }, {});
  
      // Prepare data for request
      const rulesData = Object.values(groupedRules).map((rule) => ({
        rule_id: ruleid,
        survey_id: Surveyid,
        question_id: selectedQuestion,
        condition: selectedCondition,
        logical_operators: rule.logical_operators,
        answer_conditions: rule.answer_conditions,
        answer_texts: rule.answer_texts,
        action: Action,
        action_value: actionValue,
      }));
      console.log("rulesData:", rulesData);
  
      // Determine API URL and method
      const apiUrl = isEditModeForRule
        ? `${BASE_URL}${API_ENDPOINTS.QuestionBranching}?rule_id=${ruleid}`
        : `${BASE_URL}${API_ENDPOINTS.QuestionBranching}`;
      const method = isEditModeForRule ? 'PUT' : 'POST';
  
      // Send request
      const response = await fetch(apiUrl, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: isEditModeForRule ? JSON.stringify(rulesData[0]) : JSON.stringify({ rules: rulesData }),
      });
  
      // Handle response
      if (response.ok) {
        const responseData = await response.json();
        console.log("responseData:", responseData);
        if (responseData.success) {
          // alert('Rule saved successfully');
          handleClose();
          dispatch(closeQuestionBranchingModal());
        } else {
          throw new Error(responseData.message || 'Failed to save rule');
        }
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error saving rule:', error);
      // alert('Failed to save rule');
    }
  };
  
  

  

  
  
  const handleActionValueChange = (e) => {
    const selectedValue = e.target.value;
    setActionValue(selectedValue);
  };

  const handleClose = () => {
    setSelectedQuestion('');
    setSelectedCondition('If');
    setAnswerCondition('Is');
    setAnswerText('');
    setAnswerConditionForShortLong('Is empty');
    setRules([]);
    setCondition('And');
    setAction('go to');
    setActionValue('');
    dispatch(closeQuestionBranchingModal());
  };

  const handleQuestionChange = (event) => {
    const newSelectedQuestion = event.target.value;
    setSelectedQuestion(newSelectedQuestion);

    if (newSelectedQuestion) {
      const selectedQuestionData = questions.find((question) => question.questionid === newSelectedQuestion);
      const questionType = selectedQuestionData?.question_type;
      let defaultAnswerText = '';

      if (questionType === 3 && answers.length > 0) {
        defaultAnswerText = answers[0];
      }

      setRules((prevRules) => [
        ...prevRules,
        {
          id: prevRules.length + 1,
          type: questionType,
          answerText: defaultAnswerText,
          condition,
          answerCondition,
          Action,
          actionValue,
          logicalOperator:"",
        },
      ]);
    }
  };

  const handleConditionChange = (event) => {
    setSelectedCondition(event.target.value);
  };

  const handleSelectChange = (e, index) => {
    setRules(prevRules => {
      const updatedRules = [...prevRules];
      updatedRules[index].logicalOperator = e.target.value;
      // console.log(updatedRules[index].logicalOperator,"asdsa"); // Add this line
      return updatedRules;
    });
  };

  const handleRuleChange = (index, field, value) => {
    const updatedRules = [...rules];
    updatedRules[index] = { ...updatedRules[index], [field]: value };
    setRules(updatedRules);
  };

  const addRule = () => {
    if (rules.length >= 3) {
      alert('Cannot add more than 3 rules');
      return;
    }
    if (!selectedQuestion) {
      console.warn('No question selected');
      return;
    }
  
    const selectedQuestionData = questions.find((question) => question.questionid.toString() === selectedQuestion.toString());
    const questionType = selectedQuestionData?.question_type;
  
    let defaultAnswerText = '';
    if (questionType === 3 && answers.length > 0) {
      defaultAnswerText = answers[0];
    }
  
    setRules((prevRules) => {
      const newRules = [
        ...prevRules,
        {
          id: prevRules.length + 1,
          type: questionType,   
          answerText: defaultAnswerText,
          condition,
          answerCondition,
          Action,
          actionValue,
          logicalOperator: 'And',
        },
      ];
      console.log('New rules:', newRules); // Add this line to debug
      return newRules;
    });
  };
  

  
  // const addRule = () => {
  //   if (rules.length >= 3) {
  //     alert('Cannot add more than 3 rules');
  //     return;
  //   }
  //   if (!selectedQuestion) {
  //     console.warn('No question selected');
  //     return;
  //   }
  
  //   const selectedQuestionData = questions.find((question) => question.questionid.toString() === selectedQuestion.toString());
  //   const questionType = selectedQuestionData?.question_type;
  
  //   let defaultAnswerText = '';
  //   if (questionType === 3 && answers.length > 0) {
  //     defaultAnswerText = answers[0];
  //   }
  
  //   setRules((prevRules) => {
  //     const newRules = [
  //       ...prevRules,
  //       {
  //         id: prevRules.length + 1,
  //         type: questionType,   
  //         answerText: defaultAnswerText,
  //         condition,
  //         answerCondition,
  //         Action,
  //         actionValue,
  //         logicalOperator: 'And',
  //       },
  //     ];
  //     console.log('New rules:', newRules); // Add this line to debug
  //     return newRules;
  //   });
  // };
  

  const handleDeleteRule = (id) => {
    setRules(rules.filter((rule) => rule.id !== id));
  };




  
  const renderQuestionTypeSpecificUI = (type, answerText, index) => {
    const showDeleteButton = rules.length > 1;
    const mlValue = showDeleteButton ? '5%' : '10%';
  
    if (type === 1 || type === 2) {
      return (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2, ml: mlValue }}>
          {showDeleteButton && (
            <IconButton
              className="deletequestion mr-2"
              aria-label="delete"
              onClick={() => handleDeleteRule(rules[index].id)}
              sx={{ mr: '2%' }}
            >
              <FaTrash />
            </IconButton>
          )}
          <Select
            value={rules[index].answerCondition || ''}
            onChange={(e) => handleRuleChange(index, 'answerCondition', e.target.value)}
            displayEmpty
            sx={{ mr: 2, width: 100 }}
          >
            <MenuItem value="Is">Is</MenuItem>
            <MenuItem value="Is not">Is not</MenuItem>
          </Select>
          <Select
            value={rules[index].answerConditionForShortLong || ''}
            onChange={(e) => handleRuleChange(index, 'answerConditionForShortLong', e.target.value)}
            displayEmpty
            sx={{ mr: 2, width: 150 }}
          >
            <MenuItem value="Is empty">Is empty</MenuItem>
            <MenuItem value="Contains">Contains</MenuItem>
            <MenuItem value="Exactly">Exactly</MenuItem>
          </Select>
          <TextField
            value={rules[index].answerText || ''}
            onChange={(e) => handleRuleChange(index, 'answerText', e.target.value)}
            sx={{ width: '100%' }}
            placeholder="Enter answer"
            disabled={rules[index].answerConditionForShortLong === 'Is empty'}
          />
        </Box>
      );
    }
  
    // For other types (e.g., type 3)
    if (questionType === 3) {
      console.log("Answers array:", answers);
      return (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2, ml: mlValue }}>
          {showDeleteButton && (
            <IconButton
              className="deletequestion"
              aria-label="delete"
              onClick={() => handleDeleteRule(rules[index].id)}
              sx={{ mr: '2%' }}
            >
              <FaTrash />
            </IconButton>
          )}
          <Select
            value={rules[index].answerCondition || ''}
            onChange={(e) => handleRuleChange(index, 'answerCondition', e.target.value)}
            displayEmpty
            sx={{ mr: 2, width: 100 }}
          >
            <MenuItem value="Is">Is</MenuItem>
            <MenuItem value="Is not">Is not</MenuItem>
          </Select>
          <Select
            value={rules[index].answerText || ''}
            onChange={(e) => handleRuleChange(index, 'answerText', e.target.value)}
            displayEmpty
            sx={{ width: '100%' }}
          >
            <MenuItem value="" disabled>
              Select an answer
            </MenuItem>
            {answers.length ? (
              answers.map((answer, idx) => (
                <MenuItem key={idx} value={answer}>
                  {answer}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No options available
              </MenuItem>
            )}
          </Select>
        </Box>
      );
    }
    
    
    // if (type === 3) {
    //   console.log("Answers array:", answers);
    //   return (
    //     <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2, ml: mlValue }}>
    //       {showDeleteButton && (
    //         <IconButton
    //           className="deletequestion"
    //           aria-label="delete"
    //           onClick={() => handleDeleteRule(rules[index].id)}
    //           sx={{ mr: '2%' }}
    //         >
    //           <FaTrash />
    //         </IconButton>
    //       )}
    //       <Select
    //         value={rules[index].answerCondition || ''}
    //         onChange={(e) => handleRuleChange(index, 'answerCondition', e.target.value)}
    //         displayEmpty
    //         sx={{ mr: 2, width: 100 }}
    //       >
    //         <MenuItem value="Is">Is</MenuItem>
    //         <MenuItem value="Is not">Is not</MenuItem>
    //       </Select>
    //       <Select
    //         value={rules[index].answerText || ''}
    //         onChange={(e) => handleRuleChange(index, 'answerText', e.target.value)}
    //         displayEmpty
    //         sx={{ width: '100%' }}
    //       >
    //         <MenuItem value="" disabled>
    //           Select an answer
    //         </MenuItem>
    //         {answers.map((answer, idx) => (
    //           <MenuItem key={idx} value={answer}>
    //             {answer}
    //           </MenuItem>
    //         ))}
    //       </Select>
    //     </Box>
    //   );
    // }
  
    return (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2, ml: mlValue }}>
        {showDeleteButton && (
          <IconButton
            className="deletequestion"
            aria-label="delete"
            onClick={() => handleDeleteRule(rules[index].id)}
            sx={{ mr: '2%' }}
          >
            <FaTrash />
          </IconButton>
        )}
        <Select
          value={rules[index].answerCondition || ''}
          onChange={(e) => handleRuleChange(index, 'answerCondition', e.target.value)}
          displayEmpty
          sx={{ mr: 2, width: 100 }}
        >
          <MenuItem value="Is">Is</MenuItem>
          <MenuItem value="Is not">Is not</MenuItem>
        </Select>
        <Select
          value={rules[index].answerText || ''}
          onChange={(e) => handleRuleChange(index, 'answerText', e.target.value)}
          displayEmpty
          sx={{ width: '100%' }}
        >
          <MenuItem value="" disabled>
            Select an answer
          </MenuItem>
          {answers.map((answer, idx) => (
            <MenuItem key={idx} value={answer}>
              {answer}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };
  


  return (
    <Modal
      open={isQuestionBranchingModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="modal-title" variant="h4" component="h2">
          {isEditModeForRule ? 'Edit Rule' : 'Add Rule'}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
          <Select value={selectedCondition} onChange={handleConditionChange} displayEmpty sx={{ mr: 2, width: 100 }}>
            <MenuItem value="If">If</MenuItem>
            <MenuItem value="Always">Always</MenuItem>
          </Select>
          <Select
  value={selectedQuestion}
  onChange={handleQuestionChange}
  displayEmpty
  sx={{ width: '100%' }}
>
  <MenuItem value="" disabled>
    Select a question
  </MenuItem>
  {questions.map((question) => (
    <MenuItem key={question.questionid} value={question.questionid}>
      {question.question_text}
    </MenuItem>
  ))}
</Select>
        </Box>
        {rules.map((rule, index) => (
  <React.Fragment key={rule.id}>
    {index > 0 && (
      // console.log(rule.logicalOperator); 
     <Select
     value={rules[index].logicalOperator} // Set the value prop to the current logicalOperator
     onChange={(e) => handleSelectChange(e, index)}
     style={{ width: 'fit-content', marginLeft: '10%' }}
     displayEmpty
     sx={{ mt: 2, width: '100%' }}
   >
     {rule.type === 3 || rule.type === 4  || rule.type === 1 || rule.type === 2 || rule.type === 6 || rule.type === 5? (
      <MenuItem value="Or">Or</MenuItem>
    ) : (
      [
        <MenuItem value="And">And</MenuItem>,
        <MenuItem value="Or">Or</MenuItem>
      ]
    )}
   </Select>
    )}

    {renderQuestionTypeSpecificUI(rule.type, rule.answerText, index)}
  </React.Fragment>
))}

        {selectedQuestion && (
          <>
            <Divider sx={{ mt: 2, mb: 2, height: 1, backgroundColor: 'black' }} />

            <Typography id="modal-title" variant="h7" component="h6" style={{ marginLeft: '2%' }}>
              Then
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, ml: '10%' }}>
              <Select
                value={Action}
                onChange={(e) => setAction(e.target.value)}
                displayEmpty
                sx={{ mr: 2, width: 100 }}
              >
                <MenuItem value="go to">go to</MenuItem>
                <MenuItem value="skip">skip</MenuItem>
                <MenuItem value="End survey">end survey</MenuItem>
              </Select>
              {(Action === 'go to' || Action === 'skip') && (
                <Select value={actionValue} onChange={handleActionValueChange} displayEmpty sx={{ width: '100%' }}>
                  <MenuItem value="" disabled>
                    Select a question
                  </MenuItem>
                  {questions.map((question) => (
                    <MenuItem key={question.questionid} value={question.questionid}>
                      {question.question_text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          </>
        )}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
        {!isEditModeForRule && (
  <Button
    variant="contained"
    className="bgButton"
    onClick={addRule}
    disabled={!isAddRuleEnabled || rules.length >= 3}
    style={{ marginRight: '10px' }}
  >
    Add Rule
  </Button>
)}

          <Button
            className="bgButton "
            onClick={() =>
              handleSaveRule(
                rules,
                selectedQuestion,
                selectedCondition,
                Surveyid,
                actionValue,
                BASE_URL,
                API_ENDPOINTS,
                generateRandomId,
                handleClose
              )
            }
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default QuestionBranchingModal;
