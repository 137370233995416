import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
// import { useSelector } from 'react-redux';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { AiFillCheckCircle, AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { iscloseModal ,showConfPasswordModal} from '../../Redux/actions';
import ConfirmPassword from './ConfermPasspopup';

function CredentialUpdatePopup() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const dispatch = useDispatch();
  const isEditModal = useSelector((state) => state.isEditModal);
  const [error, setError] = useState(false);
  const [successtic, setSuccesstic] = useState(false);
  const [showConfermpass, setShowConfermpass] = useState(false);
  const [pasvaldiv, setPswvaldi] = useState(true);
  const getemail = sessionStorage.getItem('email')

  const handleClose = () => {
    dispatch(iscloseModal());
  };

  function reauthenticateUser() {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(email, password);
    reauthenticateWithCredential(user, credential)
      .then(() => {
        console.log('Password validation succeeded.');
        dispatch(showConfPasswordModal())
        setError(false);
        setSuccesstic(true);
      
        dispatch(showConfPasswordModal())
        handleClose()
        setSuccesstic(false)
   
      })
      .catch((error) => {
        console.error('Password validation failed:', error);
        setError(true);
        setSuccesstic(false);
      });
  }

  const checkinput = (e) => {
    setPassword(e.target.value)
    setError(false)
  }

// edit password password hide and show 
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  
  return (
    <>
      <Modal show={isEditModal} onHide={handleClose}>
    
     <div>
            <Modal.Header closeButton style={{ backgroundColor: '#D0F2FF' }}>
              <Modal.Title>Enter Old Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className='d-none'>Email</Form.Label>
                  <Form.Control
                    className="mb-3 d-none"
                    type="email"
                    value={email}
                    placeholder="********"
                    autoFocus
                    onChange={(e) => setEmail({ getemail })}
                  />
                  <Form.Label> Current Password</Form.Label>
                  <div style={{ position: 'relative' }}>
                    {' '}
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="********"
                      autoFocus
                      onChange={checkinput}
                    />

                    {/* edit password password hide and show  */}
                    {showPassword ? <AiFillEye
                      onClick={togglePasswordVisibility}
                      style={{ right: '10px', top: '10px' }} className='position-absolute  ' /> : <AiFillEyeInvisible onClick={togglePasswordVisibility}
                        style={{ right: '10px', top: '10px' }} className='position-absolute  ' />}

                    {successtic && <AiFillCheckCircle className="greentic" />}
                  </div>
                  {error && (
                    (password.length ?
                      <p className="mt-2" style={{ color: '#c71a1ad1' }}> {' '}
                        incorrect password
                      </p>
                      : <p className="mt-2" style={{ color: '#c71a1ad1' }}>
                        {' '}
                        please enter password
                      </p>
                    )
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button className="checkbtn" type="button" onClick={reauthenticateUser}>
                Proceed
              </button>
            </Modal.Footer>
          </div>
      </Modal>
    </>
  );
}

export default CredentialUpdatePopup;
