import { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import Header from '../header';
// import Nav from './nav';
import Nav from './questionNav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function QuestionDetailslayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   const authTokene = sessionStorage.getItem('Auth Token');
  //   const sessionId = Cookies.get('sessionId');
  //   const sessionInfo = localStorage.getItem('sessionInfo');
  //   if (!authTokene && !sessionId && !sessionInfo) {
  //     navigate('/', { replace: true });
  //     console.log('Please Sign in');
  //   }
  // }, [navigate]);

  return (
    <StyledRoot >
     
      <Header  onOpenNav={() => setOpen(true)} />
      {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}
      <Main  style={{paddingLeft:'5%',paddingRight:'5%'}}>
 
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
