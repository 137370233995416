import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function Alerts({ alert }) {

    const [show, setShow] = useState(true);

    return (
        <>
            {alert.map((getalertdata, index) => (
                <Modal className='alers-modal' key={index} show={show} onHide={getalertdata.onClosealert} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton className='p-0'>
                        <Modal.Title style={{ width: '100%' }} >
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert variant="filled" severity={getalertdata.bgcolor}>
                                    Alert
                                </Alert>
                            </Stack>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> {getalertdata.alerttype}</Modal.Body>
                   <span style={{ color: '#c71a1ad1' }} className='px-3 pt-1'>{getalertdata.errortype}</span>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={getalertdata.onClosealert}> No</Button>
                        <Button variant="danger" onClick={getalertdata.onClick}>Yes</Button>
                    </Modal.Footer>
                </Modal>
            ))}
        </>
    );
}

export default Alerts;
