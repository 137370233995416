// Action types
const SHOW_DIV = 'SHOW_DIV';
const SHOW_EDIT_PASS_MODAL = 'SHOW_EDIT_PASS_MODAL';
const CONFERMPSW_MODAL = 'CONFERMPSW_MODAL';
const CONFERMPSW_MODAL_CLOSE = 'CONFERMPSW_MODAL_CLOSE';
const SHOW_UPDATE_EMAIL_MODAL = 'SHOW_UPDATE_EMAIL_MODAL';
const CLOSE_UPDATE_EMAIL_MODALL = 'CLOSE_UPDATE_EMAIL_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const CLOSE_ERROR = 'CLOSE_ERROR';
const CREATE_SURVEY = 'CREATE_SURVEY';
const CREATE_SURVEY_POPUP = 'CREATE_SURVEY_POPUP';
const CREATE_QUESTION_POPUP = 'CREATE_QUESTION_POPUP';
const CLOSE_CREATE_SURVEY_POPUP = 'CLOSE_CREATE_SURVEY_POPUP';
const CLOSE_CREATE_QUESTION_POPUP = 'CLOSE_CREATE_QUESTION_POPUP';
const OPEN_QUESTION_DATA = 'OPEN_QUESTION_DATA';
const OPEN_QUESTION_DATA_CLOSE = 'OPEN_QUESTION_DATA_CLOSE';
// const SET_QUESTION_TAB = 'SET_QUESTION_TAB';
const OPEN_CREATE_SURVEY = 'OPEN_CREATE_SURVEY'
const CLOSE_CREATE_SURVEY = 'CLOSE_CREATE_SURVEY'
const OPEN_CLICK_SURVEY = 'OPEN_CLICK_SURVEY'
const UPDATE_SURVEY_LIST = 'UPDATE_SURVEY_LIST'
const UPDATE_QUESTION_LIST = 'UPDATE_QUESTION_LIST'
const REFRESH_QUESTION_DATA = 'REFRESH_QUESTION_DATA'
const BACKDROP_LOADER = 'BACKDROP_LOADER'
const CLOSE_BACKDROP_LOADER = 'CLOSE_BACKDROP_LOADER'
const CLOSE_CONFERMPSW_MODAL = 'CLOSE_CONFERMPSW_MODAL'
const OPEN_CONFERMPSW_MODAL = 'OPEN_CONFERMPSW_MODAL'
const SET_BACKGROUND = 'SET_BACKGROUND'
const CREATE_QUESTION_ALERT = 'CREATE_QUESTION_ALERT'
const CREATE_QUESTION_ALERT_CLOSE = 'CREATE_QUESTION_ALERT_CLOSE'
const OPEN_SURVEY_FILTER = 'OPEN_SURVEY_FILTER'
const OPEN_SURVEY_FILTER_CLOSE = 'OPEN_SURVEY_FILTER_CLOSE'
const QUESTION_ORDER = 'QUESTION_ORDER'
const UPDATE_USER_NAME = 'UPDATE_USER_NAME'
const UPDATE_SURVEY_NAME = 'UPDATE_SURVEY_NAME'
const UPDATE_SURVEY_LOGO_DATA = 'UPDATE_SURVEY_LOGO_DATA'
const SHOW_AIQUESTION_MODAL = 'SHOW_AIQUESTION_MODAL'
const CLOSE_AIQUESTION_MODAL = 'CLOSE_AIQUESTION_MODAL'
const SHOW_AISURVEY_MODAL = 'SHOW_AISURVEY_MODAL'
const CLOSE_AISURVEY_MODAL = 'CLOSE_AISURVEY_MODAL'
const SHOW_AISURVEYTITLE_MODAL = 'SHOW_AISURVEYTITLE_MODAL'
const CLOSE_AISURVEYTITLE_MODAL = 'CLOSE_AISURVEYTITLE_MODAL'
const SET_SURVEY_ID = 'SET_SURVEY_ID';
const CLEAR_SURVEY_ID = 'CLEAR_SURVEY_ID';
const SHOW_QUESTION_BRANCHING_MODAL = 'SHOW_QUESTION_BRANCHING_MODAL';
const CLOSE_QUESTION_BRANCHING_MODAL = 'CLOSE_QUESTION_BRANCHING_MODAL';
const EDIT_SHOW_QUESTION_BRANCHING_MODAL ='EDIT_SHOW_QUESTION_BRANCHING_MODAL';

// const SET_SURVEY_TITLE = 'SET_SURVEY_TITLE'

// export const setSurveyTitle = (title) => ({
//   type: SET_SURVEY_TITLE,
//   payload: title,
// });

export const setBackground = (bgimg) => ({
  type: SET_BACKGROUND,
  payload: bgimg,
});

export const showDiv = () => ({
  // Action creators
  type: SHOW_DIV,
});

export const showEditPasswordModal = () => ({
  type: SHOW_EDIT_PASS_MODAL,
});
export const showConfPasswordModal = () => ({
  type: CONFERMPSW_MODAL,
});

export const showUpdateEmailModal = () =>({
  type :SHOW_UPDATE_EMAIL_MODAL
})

export const iscloseEmailModal = () =>({
  type :CLOSE_UPDATE_EMAIL_MODALL
})

export const iscloseModal = () => ({
  type: CLOSE_MODAL,
});
export const closeconfermPsw = () => ({
  type: CONFERMPSW_MODAL_CLOSE,
});

export const isshowConfermPsw = () => ({
  type: CLOSE_CONFERMPSW_MODAL,
});
export const isOpenconfermPasshow = () => ({
  type:OPEN_CONFERMPSW_MODAL,
});

export const iscloseError = () => ({
  type: CLOSE_ERROR,
});

export const createSurvey = () => ({
  type: CREATE_SURVEY,
});

export const createSurveypopup = (surveyData,isEditMode) => ({
  type:CREATE_SURVEY_POPUP,
  payload:{ surveyData, isEditMode },
});

export const closeCreateSurveypopup = (isEditMode) => ({
  type: CLOSE_CREATE_SURVEY_POPUP,
  payload:{ isEditMode },
});

export const showCreateQuestionpopup = () => ({
  type:CREATE_QUESTION_POPUP,
});

// open and edit question 
export const callSurveyview = (surveyData) => ({
  type:OPEN_QUESTION_DATA,
  payload: surveyData,
});

export const closeQuestiondata = () => ({
  type: OPEN_QUESTION_DATA_CLOSE ,
});

export const closeCreateQuestionpopup = () => ({
  type: CLOSE_CREATE_QUESTION_POPUP,
});

export const opencreateSurvey =()=>({
  type:OPEN_CREATE_SURVEY,
})

export const closecreateSurvey =()=>({
  type:CLOSE_CREATE_SURVEY,
})

export const openSurveyfunction =(surveyId)=>({
  type:OPEN_CLICK_SURVEY,
  payload: surveyId,
})

export const updateQuestionList = (updatedQuestionList) => ({
  type: UPDATE_QUESTION_LIST,
  payload: updatedQuestionList,
});

// export const updateQuestionList =()=>({
//   type:UPDATE_QUESTION_LIST,
//   // payload: surveyId,
// })
export const createQueAlert =()=>({
  type:CREATE_QUESTION_ALERT,
})
export const createQueAlertclose =()=>({
  type:CREATE_QUESTION_ALERT_CLOSE,
})

export const refreshQuestiondata = () => ({
  type: REFRESH_QUESTION_DATA ,
});

export const showBackdropLoader = () => ({
  type: BACKDROP_LOADER,
});

export const closeBackdroploader = () => ({
  type: CLOSE_BACKDROP_LOADER,
});

export const openFilter = () => ({
  type: OPEN_SURVEY_FILTER,
});

export const closefilter = () => ({
  type: OPEN_SURVEY_FILTER_CLOSE,
});

export const updateUserData = (userData) => ({
    type: UPDATE_USER_NAME,
    payload: userData,
})

export const updatesurveyData = (usersurveyData) => ({
  type: UPDATE_SURVEY_NAME,
  payload: usersurveyData,
})
export const updateSurveyLogo = (usersurveylogoData) => ({
  type: UPDATE_SURVEY_LOGO_DATA,
  payload: usersurveylogoData,
})
export const increaseCount = () => ({
  type: 'INCREASE_COUNT'
})

export const showAIquestion = () => ({
  type: SHOW_AIQUESTION_MODAL,
});

export const closeAIquestion = () => ({
  type: CLOSE_AIQUESTION_MODAL,
});

export const showAISurvey = () => ({
  type: SHOW_AISURVEY_MODAL,
});

export const closeAISurvey = () => ({
  type: CLOSE_AISURVEY_MODAL,
});
export const showAISurveyTitle = () => ({
  type: SHOW_AISURVEYTITLE_MODAL,
});

export const closeAISurveyTitle = () => ({
  type: CLOSE_AISURVEYTITLE_MODAL,
});

export const setSurveyId = (surveyId) => ({
  type: SET_SURVEY_ID,
  payload: surveyId,
});

export const clearSurveyId = () => ({
  type: CLEAR_SURVEY_ID,
});

export const showQuestionBranchingModal = (rulesData, isEditModeForRule) => ({
  type: isEditModeForRule ? EDIT_SHOW_QUESTION_BRANCHING_MODAL : SHOW_QUESTION_BRANCHING_MODAL,
  payload: { rulesData, isEditModeForRule }
});

export const closeQuestionBranchingModal= () => ({
  type: CLOSE_QUESTION_BRANCHING_MODAL,
});
