// import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { IconButton } from '@mui/material';
// import { openFilter } from '../../../Redux/actions';
// import Iconify from '../../../components/iconify';

// export default function Searchbar() {
//   const dispatch = useDispatch();
//   const handleOpen = () => {
//     dispatch(openFilter());
//   };

//   const [isVisible, setIsVisible] = useState(true);

//   useEffect(() => {
//     const { pathname } = window.location;
//     if (pathname.startsWith('/surveycreatelayout/')) {
//       setIsVisible(false);
//     } else {
//       setIsVisible(true);
//     }
//   }, [window.location.pathname]);

//   if (!isVisible) return null;

//   return (
//     <div>
//       <IconButton onClick={handleOpen}>
//         <Iconify icon="eva:search-fill" />
//       </IconButton>
//       <span style={{ color: 'gray' }}> Search Survey...</span>
//     </div>
//   );
// }



import { useDispatch } from 'react-redux';
import {  IconButton } from '@mui/material';
import { openFilter } from '../../../Redux/actions';
import Iconify from '../../../components/iconify';

export default function Searchbar() {
  const dispatch = useDispatch();
  const handleOpen = () => {
   dispatch(openFilter())
 };

  return (
      <div>
          <IconButton onClick={handleOpen} > 
            <Iconify icon="eva:search-fill"/> 
          </IconButton><span style={{color:'gray'}}> Search Survey...</span> 
      </div>
  );
}