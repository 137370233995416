// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import General from './Generalcomponent';
import SuccessScreen from './SuccessScreen';


const GeneralScreen = () => {



  // const openedSurveyId = useSelector(state => state.openedSurveyId);p
  // const [survey, setSurveyList] = useState([]);

  // const getsurveyid = localStorage.getItem('SURVEY_ID');
  // useEffect(() => {
  //   const fetchSurveyList = async () => {
  //     try {
  //       const response = await fetch(`https://app.surveyslack.com/api/surveytask/api/v1/surveylist.php?surveyid=${getsurveyid}`)
  //         .then(response => response.json())
  //         .then(data => setSurveyList([data.surveyid]))
  //       console.log(survey)
  //       if (response.status === 200) {
  //         console.log("survey get successfully", response);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching survey list:', error);
  //     }
  //   };

  //   fetchSurveyList();
  // }, []);


  const formdata = [
    {
    
    mainheading: 'this is general',
    subheading: 'for general',
    surveytitle: 'Title',
    Surveylogo: 'Logo',
    Display: 'Display',
    option1: 'One Question at time',
    option2: 'All Question at once',
    Category:'Category',
    Language:'Language',
    logourl: 'Logo Click URL',
    Categoriestitle: 'Lables',
    Save: 'Save Settings',
    input10: 'd-none',
    input3: 'd-none',
    input13: 'd-none',
    input14: 'd-none',
    description: 'Description',
    input18:'d-none',
  }
  ]


  return (
    <div>
      <General formdata={formdata} />
    </div>
  )
}

export default GeneralScreen

