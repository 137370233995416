import React from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineRollback, AiFillSetting, AiFillQuestionCircle } from 'react-icons/ai';
import QuestionList from '../Create-Survey-Folder/Questiontabs/QuestionListing';
import { createSurveypopup, showAIquestion } from '../../Redux/actions';
import AIquestionModal from '../../components/Popup-folder/Question_section/AIquestionModal';

const Questions = () => {
  const dispatch = useDispatch();

  const surveyClick = () => {
    dispatch(createSurveypopup());
  };

  const AIquestionClick = () => {
    dispatch(showAIquestion());
  };

  return (
    <div style={{ paddingTop: '' }}>
      <div className="row align-items-start">
        <div className="col-md-6 col-lg-6 text-start" style={{ display: 'flex' }}>
          <AiFillQuestionCircle className="iconOfTab" />
          <h1 className="headingofTab">Questions</h1>
        </div>
        <div className="col-md-6 col-lg-6 createQuebtns justify-content-end d-flex">
          <button className="btn bgButton mb-2 me-2 mb-lg-1" onClick={AIquestionClick} type="button">
            AI Question
          </button>
          <button onClick={surveyClick} className="btn bgButton me-2 mb-2 mb-lg-1" type="button">
            Create New Question
          </button>
        </div>
      </div>

      <QuestionList />
      <AIquestionModal />
    </div>
  );
};

export default Questions;
