import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: 'AIzaSyAyRH_xpxTzGMvjP1_ISQUPaDXfdJMxxaw',
  authDomain: 'surveyslack2024.firebaseapp.com',
  projectId: 'surveyslack2024',
  storageBucket: 'surveyslack2024.appspot.com',
  messagingSenderId: '656023429979',
  appId: '1:656023429979:web:435635fd0a220d0a94707e',
  measurementId: 'G-6M6CPWVXQW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {storage,auth,provider};