import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset,faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import {
    showQuestionBranchingModal,
    closeQuestionBranchingModal
  } from '../../Redux/actions';
  import QuestionBranchingModal from '../../components/Popup-folder/Question_section/QuestionBranchingModal';
  import QuestionBranchingpage from '../Create-Survey-Folder/Questiontabs/QuestionBranchingpage';

const QuestionBranching=()=>{
    const dispatch = useDispatch();

    const OpenQuestionBranchingModal=()=>{
        dispatch(showQuestionBranchingModal());
    }

    

    return(
        <div className=''>
        <div className='iconcontainer'>
          {/* <AiFillSetting className='iconOfTab'  /> */}
          <FontAwesomeIcon icon={faCodeBranch} className='iconOfTab' />
          <h1 className='headingofTab'>Question Branching</h1>

          <button className="btn bgButton mb-2 me-2 mb-lg-1" type="button" onClick={OpenQuestionBranchingModal}  style={{margin:'auto'}}>
            Add Branching
          </button>
   
        </div>
      
        <QuestionBranchingModal/>
        <QuestionBranchingpage/>
      </div>
    )
}
export default QuestionBranching;