import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import '../Style/Profile.css'
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { BiLoader } from 'react-icons/bi';
// import { getAuth, updateEmail } from 'firebase/auth';
import { ref, getDownloadURL } from 'firebase/storage';
import { FiEdit2 } from 'react-icons/fi';
import { MdModeEditOutline } from 'react-icons/md';
import ConfirmPassword from '../components/Popup-folder/ConfermPasspopup';
import EmailUpdatePopup from '../components/Popup-folder/EmailUpdatepopup';
import CredentialUpdatePopup from '../components/Popup-folder/PasswordUpdatepopup';
import { storage } from '../sections/auth/login/firebase';
// import { increaseCount } from '../../../Redux/actions';
import { showEditPasswordModal, showUpdateEmailModal ,updateUserData ,showConfPasswordModal,increaseCount} from '../Redux/actions';
import { BASE_URL, API_ENDPOINTS } from '../api_folder/config'; 

// const changeEmail = () => {
//   const auth = getAuth();
//   const newEmail = document.getElementById('new-email').value;
//   if (newEmail === '') {
//     alert('please enter email');
//   } else {
//     updateEmail(auth.currentUser, newEmail)
//       .then(() => {
//         console.log('Email updated successfully');
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }
// };

function UserProfile () {
  const getemail = localStorage.getItem('email')
  const dispatch = useDispatch();
  const [file, setFile] = useState('');
  const [isfirstname, setFirstname] = useState(false);
  const [islastname, setLastname] = useState(false);
  const [percent, setPercent] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const [saveClicked, setSaveClicked] = useState(false);
  const [profileUrl, setProfileUrl] = useState('');

  useEffect(() => {
    const storageRef = ref(storage, `/files/${file.name}`);
    getDownloadURL(storageRef).then((url) => {
      setFileUrl(url);
    }).catch((e)=>console.log(e));
  }, [file]);

  // function handleChange(event) {
  //   setFile(event.target.files[0]);
  //   // setFile(URL.createObjectURL(event.target.files[0]));
  // }
  // function handleUpload() {
  //   if (!file) {
  //     alert('Please choose a file first!');
  //   } else {
  //     const storageRef = ref(storage, `/files/${file.name}`);

  //     const uploadTask = uploadBytesResumable(storageRef, file);
  //     uploadTask.on(
  //       'state_changed',
  //       (snapshot) => {
  //         const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100); // update progress
  //         setPercent(percent);
  //       },
  //       (err) => console.log(err),
  //       () => {
  //         // download url
  //         getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //           console.log(url);
  //           setFileUrl(url);
  //         });
  //       }
  //     );
  //   }

  // }
    const userid = localStorage.getItem('uid');

  

  function handelPassword() {
    if (logintype === "google.com") {
      dispatch(showConfPasswordModal());
    } else {
      dispatch(showEditPasswordModal());
    }
  }
  
  function handelEmail() {
    dispatch(showUpdateEmailModal());
  }

  const defaultImageUrl = 'https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg';
  const [imagePreview, setImagePreview] = useState(defaultImageUrl);

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    Phone: '',
    Bio: '',
    img:'',
  });

  useEffect(() => {
    const userid = localStorage.getItem('uid');
    const endpoint = API_ENDPOINTS.userDataprofileGET5;

    fetch(`${BASE_URL}${endpoint}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then((data) => {
        const user = data.data.find((profiledata) => profiledata.userid === userid);
        if (user) {
          setUserData({
            firstName: user.first_name,
            lastName: user.last_name,
            Phone: user.phone_number,
            Bio: user.bio,
            img: user.profile_image,
          });

          const profileImage = localStorage.getItem('profileImage');
          if (profileImage) {
            setImagePreview(profileImage);
          } else {
            setImagePreview(user.profile_image || defaultImageUrl);
          }
        } else {
          console.error('User not found');
        }
      })
      .catch(error => console.error('Error fetching user data:', error));
  }, []);



  const [backgroundImage, setBackgroundImage] = useState(null);
const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64String = reader.result;
        setUserData(prevState => ({ ...prevState, img: base64String }));
        setImagePreview(base64String);

        const formData = new FormData();
        formData.append('userid', localStorage.getItem('uid'));
        formData.append('profileFile', file);

        fetch('https://app.surveyslack.com/api/surveytask/api/v1/imgupload.php', {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(data => {
            console.log('Data posted successfully:', data);
            if (data.profile) {
              setUserData(prevState => ({ ...prevState, profileUrl: data.profile }));
            }
          })
          .catch(error => {
            console.error('Error posting data:', error);
            setImagePreview(defaultImageUrl);
          });
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(defaultImageUrl);
    }
  };







  // get userprofile api call
 
  const logintype = localStorage.getItem("provider")



  useEffect(() => {
  //     setFirstname(false);
  // setLastname(false);
    // Check if first name is blank
    setFirstname(userData.firstName.trim() === '');
 console.log(userData.lastName.trim() === '')
    // Check if last name is blank
     setLastname(userData.lastName.trim() === '');
  }, [userData.firstName, userData.lastName]);

  // const [mobilevalidator, setMobileValid] = useState('')
  const [isloader, setLoader] = useState(false)
  const handelInputCange = (e) => {
    const { name, value } = e.target || {}; // Safe destructuring, ensures target is valid
    
    // Ensure phone number is limited to 10 digits
    if (name === "Phone") {
      // Allow only digits and prevent more than 10 characters
      const phoneValue = value.replace(/\D/g, ''); // Remove non-digit characters
      if (phoneValue.length > 10) return; // Prevent updating if length exceeds 10
      
      setUserData({
        ...userData,
        [name]: phoneValue, // Set the phone number after validation
      });
      return;
    }
  
    // Update other fields
    setUserData({
      ...userData,
      [name]: value,
    });
  
    // Handle firstName and lastName fields
    if (name === "firstName") {
      setFirstname(value.trim() === "");
    }
    if (name === "lastName") {
      setLastname(value.trim() === "");
    }
  
    // Update localStorage with the new data
    const newUsername = `${userData.firstName} ${userData.lastName}`;
    localStorage.setItem('username', newUsername);
    localStorage.setItem('lastname', userData.lastName);
    localStorage.setItem('profileimg', userData.profileUrl);
  };
  

  // const filteredSurveyList = userData.filter(profiledata => profiledata.userid === userid);
  const UpdateprofileData = async (e) => {
    e.preventDefault(); // Prevent form submission and page refresh

    if (userData.firstName === "") {
        setFirstname(true);
    }
    if (userData.lastName === "") {
        setLastname(true);
        return;
    }
    const newUsername = `${userData.firstName} ${userData.lastName}`;
    dispatch(updateUserData(newUsername));

    setLoader(true);

    // Check if profileUrl is set in userData
    // if (!userData.profileUrl) {
    //     console.error("Profile URL is not set.");
    //     setLoader(false);
    //     return;
    // }

    try {
        const userid = localStorage.getItem("uid");
        const endpoint = API_ENDPOINTS.profileDataUpdate;

        const res = await fetch(`${BASE_URL}${endpoint}?userid=${userid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: newUsername,
                first_name: userData.firstName,
                last_name: userData.lastName,
                phone_number: userData.Phone,
                bio: userData.Bio,
                profile_image: userData.profileUrl, // Use the profile URL from state
            })
        });

        if (res.ok) {
            setLoader(false);
            
            console.log("User profile updated successfully");
        } else {
            console.log("Failed to update user profile");
            setLoader(false);
        }
    } catch (error) {
        console.error("Error updating user profile:", error);
        setLoader(false);
    }
};







  return (
    
    <Form onSubmit={UpdateprofileData} className='p-2'>
       <Helmet>
        <title> Profile : surveyslack</title>
      </Helmet>
      <h2>Edit Profile</h2>
      <CredentialUpdatePopup />
      <EmailUpdatePopup />
      <ConfirmPassword/>
      {/* <CredentialUpdatePopup updatepassword={updatepassword}/> */}
      {/* <Col className="mb-3" xs={6} md={4}>
        {fileUrl&&<Image
          style={{ width: '100px' }}
          src={fileUrl}
        />}
        <Row>
          <input type="file" accept="image/*" onChange={handleChange} />
          <button type="button" onClick={handleUpload} style={{ width: '40%' }}>
            upload image
          </button>
         <p>{percent} "% done"</p>
        </Row>
      </Col> */}

      <div>

        <>
          <div className='mb-3' id='profile-upload'>
            <div className="hvr-profile-img">
              <i className="fa fa-camera">
                <input type="file" name="logo" className="upload w180" title="Dimensions 180 X 180" onChange={handleImageChange} />
                < MdModeEditOutline style={{ color: 'white', fontSize: '25px' }} /></i>
            </div>
        {imagePreview && (
    <div className="image-preview">
    <img 
          name="img" 
          style={{ height: '150px', width: '150px' }} 
          src={userData.img ? userData.img : "https://static.vecteezy.com/system/resources/previews/002/275/847/original/male-avatar-profile-icon-of-smiling-caucasian-man-vector.jpg"} 
          alt="Profile" 
        />    </div>
)}

            {/* {imagePreview && (
            <div className="image-preview">
              <img name="img" style={{ height: '150px', width: '150px' }} src={imagePreview} alt="" />
            </div>
          )} */}
          </div>

          <Row className="mb-3">
          <Form.Group className='mb-3' as={Col} md="6" lg="6" >
        <Form.Label>First Name</Form.Label>
        <Form.Control className='form-label' onChange={handelInputCange} name="firstName" value={userData.firstName} type="text" placeholder="First Name" />
        {isfirstname  && 
          <div className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
            Enter First Name
          </div>
        }
      </Form.Group>

      <Form.Group className='mb-3' as={Col} md="6" lg="6" >
        <Form.Label>Last Name</Form.Label>
        <Form.Control onChange={handelInputCange} name="lastName" value={userData.lastName} type="text" placeholder="Last Name" />
        {(islastname || (userData.lastName === '' && saveClicked)) && (
          <div className="mt-1 input-error" style={{ color: 'red', fontSize: '14px' }}>
            Enter Last Name
          </div>
        )}
      </Form.Group>
            {/* <div className="mb-3">

              <div className="mb-2 mt-2">Email</div>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6} md={6} className='position-relative' >
                  <Form.Control type="email" value={getemail} placeholder="Enter email" id="new-email1" />
                  <button style={{display:logintype==="google.com"?"none":"block"}} className="editpass" type="button" onClick={handelEmail}>
                    <FiEdit2 className="editicon" />
                  </button>
                </Grid>
                <Grid item xs={12} lg={6} md={6} className='position-relative'>
                <div className="mb-2 mt-2">Password</div>
                  <Form.Control disabled type="password" placeholder="Password" />
                  <button  style={{display:logintype==="google.com"?"none":"block"}} className="editpass" type="button" onClick={handelPassword}>
                    <FiEdit2 className="editicon" />
                  </button>
                </Grid>
              </Grid>
            </div> */}

              <Form.Group className="mb-3 position-relative" as={Col} md="6" lg="6" >
              <Form.Label>Email</Form.Label>
              <Form.Control disabled type="email" value={getemail} placeholder="Enter email" id="new-email1" />
                  <button style={{visibility:logintype==="google.com"?"hidden":"visible",top:'30px', right:'7px'}} className="editpass" type="button" onClick={handelEmail}>
                    <FiEdit2 className="editicon" />
                  </button>
            </Form.Group>

            
            <Form.Group className="mb-3 position-relative" as={Col} md="6" lg="6" >
              <Form.Label>Password</Form.Label>
              <Form.Control disabled type="password" placeholder="Password" />
                  <button style={{visibility:logintype==="google.com"?"visible":"visible",top:'30px', right:'7px'}} className="editpass" type="button" onClick={handelPassword}>
                    <FiEdit2 className="editicon" />
                  </button>
            </Form.Group>

            <Form.Group className="mb-3" as={Col} md="6" lg="6" >
              <Form.Label>Bio</Form.Label>
              <Form.Control onChange={handelInputCange} name="Bio" value={userData.Bio} placeholder="&quot;One or two sentences about yourself, such as your background, interests, or a brief statement about what you do.&quot;" as="textarea" rows={3} />
            </Form.Group>

            <Form.Group className='mb-2' as={Col} md="6" lg="6" controlId="formGridPassword">
              <Form.Label>Phone</Form.Label>
              <Form.Control type='number' onChange={handelInputCange} name="Phone" value={userData.Phone} placeholder="(1234-567890)" rows={3} />
              {/* <span className='mt-1' style={{ color: 'red', fontSize: '14px', marginLeft: '35px' }}>{mobilevalidator}</span> */}
            </Form.Group>


            <Button
        style={{ width: 'auto' }}
        className="loader mb-3 bgButton ms-3"
        color="success" variant="contained"
        type="submit"
        // disabled={isfirstname || islastname} // Disable button if first name or last name is empty
      >
        Save
        {isloader && <BiLoader className="BiLoader ms-1" />}
      </Button>
          </Row>
        </>

      </div>


      {/* <div className='row'>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
          <Form.Label htmlFor="inputPassword5">First Name</Form.Label>
          <Form.Control
            value={userData.first_name}
            type="text"
            id="inputPassword5"
            placeholder="First Name"
            aria-describedby="passwordHelpBlock"
          />
        </div>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
          <Form.Label htmlFor="inputPassword5">Last Name</Form.Label>
          <Form.Control
            value={userData.last_name}
            type="text"
            placeholder="Last Name"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
          />
        </div>

        <div className='col-12 col-md-6 col-lg-6 col-xl-6 position-relative'>
          <Form.Label htmlFor="inputPassword5">Email</Form.Label>
          <Form.Control
            type="email" value={getemail} placeholder="Enter email"
          />
          <button className="editpass" type="button" onClick={handelEmail}>
            <FiEdit2 className="editicon" />
          </button>
        </div>

        <div className='col-12 col-md-6 col-lg-6 col-xl-6 position-relative'>
          <Form.Label htmlFor="inputPassword5">Email</Form.Label>
          <Form.Control
            type="email" value={getemail} placeholder="Enter email"
          />
          <button className="editpass" type="button" onClick={handelEmail}>
            <FiEdit2 className="editicon" />
          </button>
        </div>

      </div> */}
    </Form>
  );
}

export default UserProfile ;
