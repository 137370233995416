
import { useEffect, React, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, updatePassword } from 'firebase/auth';
import validator from 'validator'; 
import { iscloseModal,closeconfermPsw } from '../../Redux/actions';

export default function ConfirmPassword() {
  const dispatch = useDispatch();
  const isEditModal = useSelector((state) => state.isEditModal);
  const [newpassword, setNewPassword] = useState('');
  // const [confermpassshow, setconfermPassshow] = useState(true);
  // const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setPasswordsMatch(newpassword === confirmPassword);
  }, [newpassword, confirmPassword]);
  const isshowConfermPsw = useSelector((state) => state.isshowConfermPsw);
  function getASecureRandomPassword() {
    return setNewPassword;
  }
  const newpasswords = getASecureRandomPassword();

  function handleSubmit() {
    if (!passwordsMatch) {
      // setPasswordsMatch(true)
      return
    }

    if (confirmPassword === "") {
      setError('Please type Password');
      return
    }

    const auth = getAuth();
    const user = auth.currentUser;
    updatePassword(user, newpassword)
      .then(() => {
        console.log('Password updated successfully!');
        // setconfermPassshow(false)
        dispatch(iscloseModal());
        handleClose()
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 'auth/weak-password') {
          setError(' Must contain at least 8 characters, one number, symbol, uppercase, and lowercase letter.');
          setTimeout(() => {
            setError('');
          }, 1000);
        } else {
          setError('try after some time');
        }
      });
  }
 
  const handelChangePassword = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setError('');

    if (password === "") {
      setError('Enter a value in the password field.');
    } else if (!validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1
    })) {
      setError('Must contain at least 8 characters, one number, symbol, uppercase, and lowercase letter.');
    }
  }
  const handleClose = () => {
    dispatch(closeconfermPsw());
  }

  return (
    <>
     <Modal
        show={isshowConfermPsw}
        onHide={handleClose}
        backdrop="static"
  
      >
        <Modal.Header closeButton style={{ backgroundColor: '#D0F2FF' }}>
          <Modal.Title>Enter New Password</Modal.Title>
        </Modal.Header>
        <div className='d-flex flex-column'>
          <div className='my-2' style={{
            width: '90%',
            margin: 'auto '
          }}>New Password</div>
          <input
            style={{
              width: '90%',
              margin: 'auto '
            }}
            className="form-control"
            // onChange={(event) => setNewPassword(event.target.value)}
            onChange={handelChangePassword}
            name="password"
            type="text"
          />

          <div style={{
            width: '90%',
            margin: 'auto '
          }} className='my-2 '>Confirm Password
          </div>

          <input
            style={{
              width: '90%',
              margin: 'auto '
            }}
            className="form-control"
            onChange={(event) => setConfirmPassword(event.target.value)}
            name="password"
            type="text"
          />
        </div>

        {!passwordsMatch && <p style={{ color: 'red', width: '90%', margin: 'auto' }}>Passwords not match.</p>}
        <p style={{ color: 'red', width: '90%', margin: 'auto' }}>{error}</p>
        <button style={{
          width: '90%',
          margin: 'auto '
        }} className=" my-4 checkbtn form-control " type="button" onClick={handleSubmit}>
          submit
        </button>
      </Modal>
    </>
  );
}
