// import React from 'react';
import { useEffect, useState } from 'react';
import '../../Style/Participants.css';
import IconButton from '@mui/material/IconButton';
import { FcMenu, FcEmptyTrash } from 'react-icons/fc';
import { NavLink } from 'react-router-dom';
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';
import DeleteModal from '../DeleteModal';

const ParticipantList = () => {
  const [responder, setResponder] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [participantId, setParticipantId] = useState(null);

  // const { surveyId } = useParams();
  const url = window.location.href;
  const urlObject = new URL(url);
  const surveyId = urlObject.pathname.split('/').pop();
  // console.log(id);
  const renderLocalTime = (dateTimeString) => {
    const date = new Date(dateTimeString); // Parse string into Date object
    const utcTime = date.getTime(); // Get UTC time in milliseconds
    const offset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds for UTC +5:30
    const localTime = new Date(utcTime + offset); // Adjust to local time zone
    const year = localTime.getFullYear();
    const month = String(localTime.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(localTime.getDate()).padStart(2, '0'); // Add leading zero if needed
    const hours = String(localTime.getHours()).padStart(2, '0'); // Add leading zero if needed
    const minutes = String(localTime.getMinutes()).padStart(2, '0'); // Add leading zero if needed
    const seconds = String(localTime.getSeconds()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  

  const fetchSurveyList = async () => {
    try {
      const endpoint = API_ENDPOINTS.responderdata;
      const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyId}`)
        .then((response) => response.json())
        .then((data) => {
          setResponder(data.responses);
        });
      if (response === 200) {
        console.log('survey get successfully', response);
      }
    } catch (error) {
      console.error('Error fetching survey list:', error);
    }
  };

  useEffect(() => {
    fetchSurveyList();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDelete = async () => {
    if (!participantId) return;

    try {
      const endpoint = API_ENDPOINTS.responderdata;
      const response = await fetch(`${BASE_URL}${endpoint}?responderid=${participantId}`, {
        method: 'DELETE',
      });
      if (response.status === 200) {
        fetchSurveyList();
        console.log(`Survey with ID ${participantId} was deleted successfully.`);
        toggleModal();
      }
    } catch (error) {
      console.error('An error occurred while deleting the survey:', error);
    }
  };

  const handelDeleteparticipant = (resId) => {
    setParticipantId(resId);
    toggleModal();
  };


  // const handelDeleteparticipant = async (resId) => {
  //   const confirmed = window.confirm('Are you sure you want to delete the Participant ?');
  //   if (!confirmed) {
  //     return;
  //   }

  //   console.log('Error fetching survey list:', resId);
  //   try {
  //     const endpoint = API_ENDPOINTS.responderdata;
  //     const response = await fetch(`${BASE_URL}${endpoint}?responderid=${resId}`, {
  //       method: 'DELETE',
  //     });
  //     if (response.status === 200) {
  //       fetchSurveyList();

  //       console.log(`Survey with ID ${resId} was deleted successfully.`);
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while deleting the survey:', error);
  //   }
  // };
  return (
    <div className="participant-list-container">
      <table id="content-list" className="content-list">
        <thead>
          <tr className="content-list-header">
            <th className="col1"> </th>
            <th className="col2">Participant</th>
            <th className="col3">Time Spent</th>
            <th className="col4">OS</th>
            <th className="col5">Browser</th>
            <th className="col6">Date</th>
            <th className="col7">&nbsp;</th>
            <th className="col8">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
  {responder === undefined
    ? ''
    : responder.map((responderdata, index) => (
        <tr key={responderdata.responderid} className="content-list-item participant-item">
          <td className="col1">
            <NavLink className="icon-link" to={`/QuestionDetailslayout/${responderdata.responderid}`}>
              P{index + 1}
            </NavLink>
          </td>
          <td className="col2">
            <div className="email-container">
              <span className="participant-item-email">{responderdata.responderemail}</span>
              <span className="participant-item-location">{responderdata.location}</span>
            </div>
          </td>
          <td className="col3">
            {responderdata.time_spent < 3600
              ? responderdata.time_spent < 60
                ? responderdata.time_spent === ''
                  ? 'not Available'
                  : `${responderdata.time_spent} sec`
                : ` ${Math.floor(responderdata.time_spent / 60)} min `
              : ` ${Math.floor(responderdata.time_spent / 3600)} hrs `}
          </td>
          <td className="col4">{responderdata.OS}</td>
          <td className="col5">{responderdata.browser}</td>
          <td className="col6">{renderLocalTime(responderdata.created_at)}</td>
          <td className="col7">
            <NavLink className="icon-link" to={`/QuestionDetailslayout/${responderdata.responderid}`}>
              <FontAwesomeIcon icon={faEye} />
            </NavLink>
          </td>
          <td className="col8">
            <FontAwesomeIcon
              icon={faTrash}
              className="icon-link"
              onClick={() => handelDeleteparticipant(responderdata.responderid)}
            />
          </td>
        </tr>
      ))}
</tbody>

      </table>
      {isModalOpen && (
        <DeleteModal
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          handleDelete={handleDelete}
          participantId={participantId}
        />
      )}
    </div>
  );
};

export default ParticipantList;
