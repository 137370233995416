import PropTypes from 'prop-types';
import { useState,useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }} >
        {data.map((item) => (
          <NavItem key={item.title} item={item}  />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState('');
  useEffect(() => {
    const signupMethod = localStorage.getItem('provider');
    if (signupMethod === 'google.com') {
      setIsLoginWithGoogle(false);
    } else {
      setIsLoginWithGoogle(true);
    }
    console.log(isLoginWithGoogle)
  }, []);

  const isLastName = localStorage.getItem('lastname');
  const SignupWithEmail = localStorage.getItem('signupWithEmail');
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
    component={RouterLink}
    to={path}
    disabled={!isLoginWithGoogle ? false : SignupWithEmail === "email" && isLastName === null}
    sx={{
      '&.active': {
        color: '#105d4f',
        bgcolor: 'action.selected',
        fontWeight: 'fontWeightBold',
      },
      '&:hover': {
        color: '#105d4f', // Apply the desired hover color here
      }
    }}
  >
    <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
    <ListItemText disableTypography primary={title} />
    {info && info}
  </StyledNavItem>
  
  );
}