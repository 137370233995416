// import React, { useState } from 'react';
// import { borderRadius, display } from '@mui/system';
// import {
//   Modal,
//   Box,
//   Typography,
//   Button,
//   Backdrop,
//   CircularProgress,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   TextField,
//   FormControlLabel,
//   Checkbox,
// } from '@mui/material';
// import { useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import CloseIcon from '@mui/icons-material/Close';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LongAnswerImg from '../../img/question-and-answer.png';
// import ShortAnswerImg from '../../img/question.png';
// import RatingImg from '../../img/rating.png';
// import DropdownImg from '../../img/drop-down-menu.png';
// import LinearscaleImg from '../../img/customer-satisfaction.png';
// import RangeSliderImg from '../../img/compass.png';
// import FileUploadImge from '../../img/upload-file.png';
// import ImageChoiceImg from '../../img/photo.png';
// import DateTimeImg from '../../img/calendar.png';
// import MultipleChoiceImg from '../../img/multiple.png';
// import { closeAISurvey,closecreateSurvey,showAISurveyTitle,createQueAlert,
//   createQueAlertclose, updateQuestionList} from '../../Redux/actions';
// import AISurveyTitle from './AISurveyTitle';
// import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '80%',
//   bgcolor: 'background.paper',
//   border: 'none',
//   borderRadius:'10px',
//   boxShadow: 24,
//   p: 4,
// };

// const AISurveyModal = () => {
//   const { id } = useParams();
//   const dispatch = useDispatch();
//   const open = useSelector((state) => state.showAISurvey);
//   const surveyId = useSelector(state => state.surveyId);
//   const [isLoading, setIsLoading] = useState(false);
//   const [questions, setQuestions] = useState([]);
//   const [topic, setTopic] = useState('');
//   const [error, setError] = useState(null);
//   const [selectedQuestions, setSelectedQuestions] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);


//   const handleInternalClose = () => {
//     dispatch(closeAISurvey());
//     dispatch(closecreateSurvey());
//   };

//   const handleGenerate = async () => {
//     setIsLoading(true);
//     const apiKey = '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';
//     const prompt = `Generate 30 Survey questions on the topic: ${topic}. Specify the type of question as MC for Multiple Choice, DD for Dropdown, SA for Short Answer, LA for Long Answer, RT for Rating, and LS for Linear Scale. For example:\n
//       1. What is the capital city of Spain? [MC]; A) Madrid B) Barcelona C) Seville D) Valencia\n
//       2. Which famous Spanish artist is known for his surrealist paintings? [DD]; A) Pablo Picasso B) Salvador Dalí C) Diego Velázquez D) Francisco Goya\n
//       3. What is your name? [SA]\n
//       4. Describe the impact of climate change in urban areas. [LA]\n
//       5. Rate your experience with our service. [RT]\n
//       6. How likely would you be to recommend our product to your friend or a colleague? [LS]\n
//       7. How many hours do you work out every week? [RS]\n
//       8. which date & time you would like to pick? [DT]\n
//       ... (add 22 more questions in total)`;

//     try {
//       const response = await fetch('https://api.openai.com/v1/chat/completions', {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${apiKey}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           model: 'gpt-3.5-turbo',
//           messages: [
//             {
//               role: 'system',
//               content: 'You are an AI Quiz generator working at TriviaMaker, skilled in generating entertaining questions and answers.',
//             },
//             {
//               role: 'user',
//               content: prompt,
//             },
//           ],
//           temperature: 0.3,
//           max_tokens: 2048,
//           top_p: 1.0,
//           frequency_penalty: 0.0,
//           presence_penalty: 0.0,
//         }),
//       });

//       if (!response.ok) {
//         throw new Error(`Error ${response.status}: ${response.statusText}`);
//       }

//       const data = await response.json();
//       console.log('API Response:', data);

//       const generatedQuestions = data.choices[0].message.content
//         .split('\n')
//         .map((line) => {
//           const matchWithOptions = line.match(
//             /^\d+\. (.+?) \[(MC|DD|RT|LS|RS|DT)\];?(?: A\) (.+?) ?)?(?: B\) (.+?) ?)?(?: C\) (.+?) ?)?(?: D\) (.+?) ?)?$/
//           );
//           const matchWithoutOptions = line.match(/^\d+\. (.+?) \[(SA|LA)\]$/);

//           if (matchWithOptions) {
//             const question = matchWithOptions[1].trim();
//             let questionType;
//             let options;

//             switch (matchWithOptions[2]) {
//               case 'MC':
//                 questionType = '12';
//                 options = {
//                   A: matchWithOptions[3]?.trim() || '',
//                   B: matchWithOptions[4]?.trim() || '',
//                   C: matchWithOptions[5]?.trim() || '',
//                   D: matchWithOptions[6]?.trim() || '',
//                 };
//                 break;
//               case 'DD':
//                 questionType = '4';
//                 options = {
//                   A: matchWithOptions[3]?.trim() || '',
//                   B: matchWithOptions[4]?.trim() || '',
//                   C: matchWithOptions[5]?.trim() || '',
//                   D: matchWithOptions[6]?.trim() || '',
//                 };
//                 break;
//               case 'RT':
//                 questionType = '3';
//                 options = null;
//                 break;
//               case 'LS':
//                 questionType = '5';
//                 options = null;
//                 break;
//               case 'RS':
//                 questionType = '6';
//                 options = null;
//                 break;
//               case 'DT':
//                 questionType = '7';
//                 options = null;
//                 break;
//               default:
//                 return null;
//             }

//             return {
//               question,
//               options,
//               questionType,
//               questionid: generateQuestionId(),
//             };
//           }

//           if (matchWithoutOptions) {
//             const question = matchWithoutOptions[1].trim();
//             const questionType = matchWithoutOptions[2] === 'SA' ? '2' : '1'; // 2 for Short Answer, 1 for Long Answer
//             return {
//               question,
//               options: null,
//               questionType,
//               questionid: generateQuestionId(),
//             };
//           }

//           return null;
//         })
//         .filter(Boolean);

//       console.log('Generated Questions:', generatedQuestions);

//       setQuestions(generatedQuestions);
//     } catch (error) {
//       console.error('Error generating questions:', error);
//       setError('Failed to generate questions. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const generateQuestionId = () => {
//     return Math.random().toString().slice(2, 7);
//   };

//   const setsurveyid=localStorage.getItem('SURVEY_ID');
  
//   const handleCreateSurvey = async () => {
//     setIsLoading(true);
//     try {
//       const createdDate = new Date().toISOString().replace(/T.*/, '');
//       const modifiedDate = new Date().toISOString().replace(/T.*/, '');
  
//       // Function to determine options array based on question type
//       const getOptionsArray = (question) => {
//         switch (question.questionType) {
//           case '3': // Rating
//             return ["5","2",null,["1","2","3","4","5"]];
//           case '5': // Linear Scale
//             return [1, 10];
//           case '6': // RS
//             return ["1","10","20"];
//           case '7': // Date and Time
//             return ["true","true","MM / DD / YYYY"];
//           default:
//             return question.options
//               ? Object.values(question.options).map((option) => option.replace(/^\s*[A-D]\)\s*/, '').trim())
//               : null;
//         }
//       };
      
  
//       // Map selected questions to the format expected by the API
//       const selectedQuestionsData = selectedQuestions.map((question) => {
//         const optionsArray = getOptionsArray(question);
//         const JSONanswer = Array.isArray(optionsArray) ? JSON.stringify(optionsArray) : optionsArray;
  
//         return {
//           questionid: question.questionid,
//           surveyid: surveyId, // Assuming 'surveyId' is defined somewhere in your scope
//           question_text: question.question.replace(/^\d+\.\s*/, '').trim(),
//           question_type: question.questionType,
//           create_date: createdDate,
//           modify_date: modifiedDate,
//           answer: JSONanswer,
//           question_order: question.questionOrder,
//           isImage: 0,
//           imagelink: '',
//           isVideo: 0,
//           video_link: '',
//           isAudio: 0,
//           audio_link: '',
//           multiple_answer: 0,
//           compulsory: 0,
//         };
//       });
  
//       console.log('Questions to be created:', selectedQuestionsData);
  
//       // Create questions via API
//       const response = await fetch(`${BASE_URL}${API_ENDPOINTS.Aisurvey}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(selectedQuestionsData),
//       });
  
//       if (!response.ok) {
//         throw new Error(`Error ${response.status}: ${response.statusText}`);
//       }
  
//       const data = await response.json();
//       console.log('Response from create questions:', data);
  
//       // Update question order after successful creation
//       const questionIds = selectedQuestionsData.map((question) => question.questionid);
//       await updateQuestionOrder(questionIds);
  
//       // Close modal or perform any necessary UI updates
//       handleInternalClose();
//     } catch (error) {
//       console.error('Error creating questions:', error);
//       setError('Failed to create survey. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };
  
//   const updateQuestionOrder = async (questionIds) => {
//     const orderEndpoint = API_ENDPOINTS.surveyEdit;
//     const currentOrder = localStorage.getItem('quesOrder') || '';
//     const orderArray = currentOrder.split(',').map((item) => item.trim());
//     orderArray.push(...questionIds);
  
//     const response = await fetch(`${BASE_URL}${orderEndpoint}?surveyid=${surveyId}`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ question_order: JSON.stringify(orderArray.map(String)) }),
//     });
  
//     if (response.status === 200) {
//       console.log(`Survey with ID ${id} question order saved successfully.`);
//     } else {
//       console.error('Failed to update question order');
//     }
//   };
  
  



//   const handleSelectQuestion = (question) => {
//     setSelectedQuestions((prevSelectedQuestions) => {
//       const isSelected = prevSelectedQuestions.some((q) => q.questionid === question.questionid);
//       if (isSelected) {
//         return prevSelectedQuestions.filter((q) => q.questionid !== question.questionid);
//       }
//       return [...prevSelectedQuestions, question];
//     });
//   };

//   const handleSelectAll = (event) => {
//     const { checked } = event.target;
//     setSelectAll(checked);
//     if (checked) {
//       setSelectedQuestions(questions);
//     } else {
//       setSelectedQuestions([]);
//     }
//   };


//   const CreateSurveyQuestion = () => {
//     // dispatch(showAISurveyTitle());
  
// };


//   return (
//     <>
//       <Backdrop sx={{ color: '#fff', zIndex: isLoading ? 2000 : undefined }} open={isLoading}>
//         <CircularProgress color="inherit" variant="indeterminate" />
//       </Backdrop>
//       <Modal
//         open={open}
//         onClose={handleInternalClose}
//         aria-labelledby="ai-survey-modal-title"
//         aria-describedby="ai-survey-modal-description"
//       >
//         <Box sx={style}>
//           <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
//           Create Survey with AI
//             <IconButton onClick={handleInternalClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
//               <CloseIcon />
//             </IconButton>
//           </Typography>
//           <Box mt={2} mb={2} style={{display:'flex'}}>
//             <TextField
//               fullWidth
//               label="Topic"
//               variant="outlined"
//               value={topic}
//               onChange={(e) => setTopic(e.target.value)}
//             />
//             <Button
//               variant="contained"
//               color="success" 
//               className='bgButton'
//               onClick={handleGenerate}
//               disabled={isLoading || topic.trim() === ''}
//               sx={{  marginLeft:'5px'}}
//             >
//               Generate
//             </Button>
//           </Box>
//           {questions.length > 0 && (
//             <>
//             <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
//               {questions.map((question, index) => (
//                 <Accordion key={index} sx={{ mb: 2 }}>
//                   <AccordionSummary expandIcon={<ExpandMoreIcon />} >
//                     <Typography style={{display:'flex',alignItems:'center'}}>
//                     <Checkbox
//                             checked={selectedQuestions.some((q) => q.questionid === question.questionid)}
//                             onChange={() => handleSelectQuestion(question)}
//                             name={`question-${question.questionid}`}
//                           />
//                     {question.questionType === '12' && (
//     <img src={MultipleChoiceImg} alt="Multiple Choice" className="question-icon"  style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '2' && (
//     <img src={ShortAnswerImg} alt="Short Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '1' && (
//     <img src={LongAnswerImg} alt="Long Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '4' && (
//     <img src={DropdownImg} alt="Drop Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '6' && (
//     <img src={RangeSliderImg} alt="range Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '3' && (
//     <img src={RatingImg} alt="Rating Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '7' && (
//     <img src={DateTimeImg} alt="Date & time Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
//   {question.questionType === '5' && (
//     <img src={LinearscaleImg} alt="Linear Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
//   )}
  
//                       {index + 1}. {question.question}
//                     </Typography>
//                   </AccordionSummary>
//                   <AccordionDetails >
//                     {question.options && (
//   <Box>
//     {Object.keys(question.options).map((key) => (
//       <Typography key={key}>
//         {key}. {question.options[key]}
//       </Typography>
//     ))}
//   </Box>
// )}

//                   </AccordionDetails>
//                 </Accordion>
//               ))}
             
//             </Box>
//             <div style={{display:'flex',alignItems:'flex-end'}}>
//             <FormControlLabel
//                           control={
//                             <Checkbox
//                               defaultChecked
//                               checked={selectAll}
//                               onChange={handleSelectAll}
//                               name="selectAll"
//                             />
//                           }
//                           label="Select All"
//                         />
//             <Button
//               variant="contained"
//               color="success"
//               className='bgButton'
//               onClick={handleCreateSurvey}
//               style={{display:'block',marginLeft:'auto',marginTop:'20px'}}
//             >
//               Create Survey
//             </Button>
//             </div>  
//             </>
//           )}
//           {error && (
//             <Typography color="error" sx={{ mt: 2 }}>
//               {error}
//             </Typography>
//           )}
//         </Box>
//       </Modal>
//       <AISurveyTitle/>
//     </>
//   );
// };

// export default AISurveyModal;
import React, { useState } from 'react';
import { borderRadius, display } from '@mui/system';
import {
  Modal,
  Box,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LongAnswerImg from '../../img/question-and-answer.png';
import ShortAnswerImg from '../../img/question.png';
import RatingImg from '../../img/rating.png';
import DropdownImg from '../../img/drop-down-menu.png';
import LinearscaleImg from '../../img/customer-satisfaction.png';
import RangeSliderImg from '../../img/compass.png';
import FileUploadImge from '../../img/upload-file.png';
import ImageChoiceImg from '../../img/photo.png';
import DateTimeImg from '../../img/calendar.png';
import MultipleChoiceImg from '../../img/multiple.png';
import { closeAISurvey,closecreateSurvey,showAISurveyTitle,createQueAlert,
  createQueAlertclose, updateQuestionList} from '../../Redux/actions';
import AISurveyTitle from './AISurveyTitle';
import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

const AISurveyModal = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.showAISurvey);
  const surveyId = useSelector(state => state.surveyId);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [topic, setTopic] = useState('');
  const [error, setError] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);


  const handleInternalClose = () => {
    dispatch(closeAISurvey());
    dispatch(closecreateSurvey());
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    const apiKey = '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';
    const prompt = `Generate 30 Survey questions on the topic: ${topic}. Specify the type of question as MC for Multiple Choice, DD for Dropdown, SA for Short Answer, LA for Long Answer, RT for Rating, and LS for Linear Scale. For example:\n
      1. What is the capital city of Spain? [MC]; A) Madrid B) Barcelona C) Seville D) Valencia\n
      2. Which famous Spanish artist is known for his surrealist paintings? [DD]; A) Pablo Picasso B) Salvador Dalí C) Diego Velázquez D) Francisco Goya\n
      3. What is your name? [SA]\n
      4. Describe the impact of climate change in urban areas. [LA]\n
      5. Rate your experience with our service. [RT]\n
      6. How likely would you be to recommend our product to your friend or a colleague? [LS]\n
      7. How many hours do you work out every week? [RS]\n
      8. which date & time you would like to pick? [DT]\n
      ... (add 22 more questions in total)`;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: 'You are an AI Quiz generator working at TriviaMaker, skilled in generating entertaining questions and answers.',
            },
            {
              role: 'user',
              content: prompt,
            },
          ],
          temperature: 0.3,
          max_tokens: 2048,
          top_p: 1.0,
          frequency_penalty: 0.0,
          presence_penalty: 0.0,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      const generatedQuestions = data.choices[0].message.content
        .split('\n')
        .map((line) => {
          const matchWithOptions = line.match(
            /^\d+\. (.+?) \[(MC|DD|RT|LS|RS|DT)\];?(?: A\) (.+?) ?)?(?: B\) (.+?) ?)?(?: C\) (.+?) ?)?(?: D\) (.+?) ?)?$/
          );
          const matchWithoutOptions = line.match(/^\d+\. (.+?) \[(SA|LA)\]$/);

          if (matchWithOptions) {
            const question = matchWithOptions[1].trim();
            let questionType;
            let options;

            switch (matchWithOptions[2]) {
              case 'MC':
                questionType = '12';
                options = {
                  A: matchWithOptions[3]?.trim() || '',
                  B: matchWithOptions[4]?.trim() || '',
                  C: matchWithOptions[5]?.trim() || '',
                  D: matchWithOptions[6]?.trim() || '',
                };
                break;
              case 'DD':
                questionType = '4';
                options = {
                  A: matchWithOptions[3]?.trim() || '',
                  B: matchWithOptions[4]?.trim() || '',
                  C: matchWithOptions[5]?.trim() || '',
                  D: matchWithOptions[6]?.trim() || '',
                };
                break;
              case 'RT':
                questionType = '3';
                options = null;
                break;
              case 'LS':
                questionType = '5';
                options = null;
                break;
              case 'RS':
                questionType = '6';
                options = null;
                break;
              case 'DT':
                questionType = '7';
                options = null;
                break;
              default:
                return null;
            }

            return {
              question,
              options,
              questionType,
              questionid: generateQuestionId(),
            };
          }

          if (matchWithoutOptions) {
            const question = matchWithoutOptions[1].trim();
            const questionType = matchWithoutOptions[2] === 'SA' ? '2' : '1'; // 2 for Short Answer, 1 for Long Answer
            return {
              question,
              options: null,
              questionType,
              questionid: generateQuestionId(),
            };
          }

          return null;
        })
        .filter(Boolean);

      console.log('Generated Questions:', generatedQuestions);

      setQuestions(generatedQuestions);
    } catch (error) {
      console.error('Error generating questions:', error);
      setError('Failed to generate questions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  const generateQuestionId = () => {
    return Math.random().toString().slice(2, 7);
  };

  const setsurveyid=localStorage.getItem('SURVEY_ID');
  
  const handleCreateSurvey = async () => {
    setIsLoading(true);
    try {
      const createdDate = new Date().toISOString().replace(/T.*/, '');
      const modifiedDate = new Date().toISOString().replace(/T.*/, '');
  
      // Function to determine options array based on question type
      const getOptionsArray = (question) => {
        switch (question.questionType) {
          case '3': // Rating
            return ["5","2",null,["1","2","3","4","5"]];
          case '5': // Linear Scale
            return [1, 10];
          case '6': // RS
            return ["1","10","20"];
          case '7': // Date and Time
            return ["true","true","MM / DD / YYYY"];
          default:
            return question.options
              ? Object.values(question.options).map((option) => option.replace(/^\s*[A-D]\)\s*/, '').trim())
              : null;
        }
      };
      
  
      // Map selected questions to the format expected by the API
      const selectedQuestionsData = selectedQuestions.map((question) => {
        const optionsArray = getOptionsArray(question);
        const JSONanswer = Array.isArray(optionsArray) ? JSON.stringify(optionsArray) : optionsArray;
  
        return {
          questionid: question.questionid,
          surveyid: surveyId, // Assuming 'surveyId' is defined somewhere in your scope
          question_text: question.question.replace(/^\d+\.\s*/, '').trim(),
          question_type: question.questionType,
          create_date: createdDate,
          modify_date: modifiedDate,
          answer: JSONanswer,
          question_order: question.questionOrder,
          isImage: 0,
          imagelink: '',
          isVideo: 0,
          video_link: '',
          isAudio: 0,
          audio_link: '',
          multiple_answer: 0,
          compulsory: 0,
        };
      });
  
      console.log('Questions to be created:', selectedQuestionsData);
  
      // Create questions via API
      const response = await fetch(`${BASE_URL}${API_ENDPOINTS.Aisurvey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedQuestionsData),
      });
  
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Response from create questions:', data);
  
      // Update question order after successful creation
      const questionIds = selectedQuestionsData.map((question) => question.questionid);
      await updateQuestionOrder(questionIds);
  
      // Close modal or perform any necessary UI updates
      handleInternalClose();
    } catch (error) {
      console.error('Error creating questions:', error);
      setError('Failed to create survey. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const updateQuestionOrder = async (questionIds) => {
    const orderEndpoint = API_ENDPOINTS.surveyEdit;
    const currentOrder = localStorage.getItem('quesOrder') || '';
    const orderArray = currentOrder.split(',').map((item) => item.trim());
    orderArray.push(...questionIds);
  
    const response = await fetch(`${BASE_URL}${orderEndpoint}?surveyid=${surveyId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ question_order: JSON.stringify(orderArray.map(String)) }),
    });
  
    if (response.status === 200) {
      console.log(`Survey with ID ${id} question order saved successfully.`);
    } else {
      console.error('Failed to update question order');
    }
  };
  
  



  const handleSelectQuestion = (question) => {
    setSelectedQuestions((prevSelectedQuestions) => {
      const isSelected = prevSelectedQuestions.some((q) => q.questionid === question.questionid);
      if (isSelected) {
        return prevSelectedQuestions.filter((q) => q.questionid !== question.questionid);
      }
      return [...prevSelectedQuestions, question];
    });
  };

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    if (checked) {
      setSelectedQuestions(questions);
    } else {
      setSelectedQuestions([]);
    }
  };


  const CreateSurveyQuestion = () => {
    // dispatch(showAISurveyTitle());
  
};


  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: isLoading ? 2000 : undefined }} open={isLoading}>
        <CircularProgress color="inherit" variant="indeterminate" />
      </Backdrop>
      <Modal
        open={open}
        onClose={handleInternalClose}
        aria-labelledby="ai-survey-modal-title"
        aria-describedby="ai-survey-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
          Create Survey with AI
            <IconButton onClick={handleInternalClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
              <CloseIcon />
            </IconButton>
          </Typography>
          <Box mt={2} mb={2} style={{display:'flex'}}>
            <TextField
              fullWidth
              label="Topic"
              variant="outlined"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            />
            <Button
              variant="contained"
              color="success" 
              className='bgButton'
              onClick={handleGenerate}
              disabled={isLoading || topic.trim() === ''}
              sx={{  marginLeft:'5px'}}
            >
              Generate
            </Button>
          </Box>
          {questions.length > 0 && (
            <>
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
            {questions.map((question, index) => (
  <Accordion key={index} sx={{ mb: 2 }}>
    <AccordionSummary 
      expandIcon={<ExpandMoreIcon />} 
      aria-controls={`panel${index}a-content`}
      id={`panel${index}a-header`}
      onClick={(e) => e.stopPropagation()} // Prevent summary from expanding on click
    >
      <Typography 
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={(e) => e.stopPropagation()} // Prevent Typography from expanding accordion
      >
        <Checkbox
          checked={selectedQuestions.some((q) => q.questionid === question.questionid)}
          onChange={(e) => {
            e.stopPropagation(); // Prevent checkbox from expanding the accordion
            handleSelectQuestion(question);
          }}
          onClick={(e) => e.stopPropagation()} // Prevent checkbox click from expanding accordion
          name={`question-${question.questionid}`}
        />
        {question.questionType === '12' && (
          <img src={MultipleChoiceImg} alt="Multiple Choice" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '2' && (
          <img src={ShortAnswerImg} alt="Short Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '1' && (
          <img src={LongAnswerImg} alt="Long Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '4' && (
          <img src={DropdownImg} alt="Drop Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '6' && (
          <img src={RangeSliderImg} alt="Range Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '3' && (
          <img src={RatingImg} alt="Rating Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '7' && (
          <img src={DateTimeImg} alt="Date & Time Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {question.questionType === '5' && (
          <img src={LinearscaleImg} alt="Linear Answer" className="question-icon" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
        )}
        {index + 1}. {question.question}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {question.options && (
        <Box>
          {Object.keys(question.options).map((key) => (
            <Typography key={key}>
              {key}. {question.options[key]}
            </Typography>
          ))}
        </Box>
      )}
    </AccordionDetails>
  </Accordion>
))}

             
            </Box>
            <div style={{display:'flex',alignItems:'flex-end'}}>
            <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              checked={selectAll}
                              onChange={handleSelectAll}
                              name="selectAll"
                            />
                          }
                          label="Select All"
                        />
            <Button
              variant="contained"
              color="success"
              className='bgButton'
              onClick={handleCreateSurvey}
              style={{display:'block',marginLeft:'auto',marginTop:'20px'}}
            >
              Create Survey
            </Button>
            </div>  
            </>
          )}
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Modal>
      <AISurveyTitle/>
    </>
  );
};

export default AISurveyModal;
