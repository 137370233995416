import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
import { RiFileAddLine } from 'react-icons/ri';
import Chip from '@mui/material/Chip';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { showBackdropLoader, closeBackdroploader, updatesurveyData, updateSurveyLogo } from '../../../Redux/actions';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';

function General({ formdata }) {

  const [fetchdata, setFetchdata] = useState(false);
  const [taglabel, setSurveylabel] = useState([]);
  const [selectdate, setSelectdate] = useState('');
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.showsurveyloaderbackdrop);
  const [survey, setSurveyList] = useState([]);
  const [description, setDescription] = useState(null);
  const [Category, setCategory] = useState(null);
  const [Language, setLanguage] = useState('');
  const [Display, setDisplay] = useState('One Question at time');
  const [succmessage, setSuccessmess] = useState('');
  const [surevyendDate, setSurveydate] = useState();
  const [welcomemess, setWelcomemess] = useState('');
  const [logourl, setLogourl] = useState(null);
  const MixArray = survey;
  const string = MixArray.toString();
  const ansarray = string.split(',');
  const survetitle = ansarray[0];
  const getsurveyid = localStorage.getItem('SURVEY_ID');

  useEffect(() => {
    // dayjs(surevyendDate).format('YYYY-MM-DD')
    // setSelectdate(dayjs(surevyendDate).format('YYYY-MM-DD'))
    // const isDate = localStorage.getItem('date')
    // setSelectdate(new date(isDate))
  }, []);
  const today = dayjs();
  const { id } = useParams();
  const fetchSurveyList = async () => {
    try {
      const endpoint = API_ENDPOINTS.surveyEdit;
      const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${getsurveyid}`);
      const data = await response.json();
  
      if (response.status === 200) {
        const surveyData = data.surveyid;
        setSurveyList(surveyData.survey_title);
        setDisplay(surveyData.survey_display);
        setSurveylabel(surveyData.survey_label);
        setDescription(surveyData.survey_description);
        setCategory(surveyData.survey_categories);
        setLanguage(surveyData.survey_language);         
        setLogourl(surveyData.status);
        setSuccessmess(surveyData.success_msg);
        setSurveydate(surveyData.survey_enddate);

        // setSelectdate(dayjs(surveyData.survey_enddate).format('YYYY-MM-DD'));

        // dayjs(surevyendDate).format('YYYY-MM-DD')
        setSelectdate(surveyData.survey_enddate)
        // const isDate = localStorage.getItem('date')
        // setSelectdate(new date(isDate))

        setWelcomemess(surveyData.welcome_msg);
        setSelectedImage(surveyData.logo);
        setSelectedSuccImage(surveyData.success_img);
        setWelcomeImg(surveyData.welcome_img);
        console.log('Survey fetched successfully', surveyData);
      }
    } catch (error) {
      console.error('Error fetching survey list:', error);
    }
  };
  
  useEffect(() => {
    fetchSurveyList();
  }, []);
  

  const handleChange = (event) => {
    setSurveyList(event.target.value);
  };
  const handelWelScreen = (event) => {
    setWelcomemess(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handelLogourl = (event) => {
    setLogourl(event.target.value);
  };
  const handleSuccess = (event) => {
    setSuccessmess(event.target.value);
  };

  General.propTypes = {
    formdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  const [settingtab, setSettigntab] = useState(true);
  const [questiontab, setQuestiontab] = useState(true);
  // const [themstab, setThemstab] = useState(true)
  // const [previewtab, setPreviewtab] = useState(true)
  // const [sharetabtab, setSharetab] = useState(true)
  // const [resulttab, setResulttab] = useState(true)

  // useEffect(() => {
  //     const url = 'http://localhost:3000/surveycreatelayout/setting?id=39870';
  // const pathname = window.location.pathname;

  // console.log(pathname); // Output: "/surveycreatelayout/setting"
  //   const url = window.location.href;
  //   const path = url.substring(url.indexOf("/surveycreatelayout/"));
  //   console.log('current url :--' ,path);
  //   switch (path) {
  //     case  `/surveycreatelayout/setting?id=${id}`:
  //       setSettigntab(true)
  //       break;
  //     case "/surveycreatelayout/questions":
  //       setQuestiontab(true)
  //       break;
  //     case "/surveycreatelayout/thems":
  //       setThemstab(true)
  //       break;
  //     case "/surveycreatelayout/preview":
  //       setPreviewtab(true)
  //       break;
  //     case "/surveycreatelayout/share":
  //       setSharetab(true)
  //       break;
  //     case "/surveycreatelayout/result":
  //       setResulttab(true)
  //       break;
  //     default:
  //       break;
  //   }
  // }
  //   , [currenturl]);
  // formdata.map((surveyItem) => ({
  //   mainheading: 'General Setting',
  //   subheading: ' Configure the main settings for your survey.',
  //   surveytitle: 'Survey title',
  //   title_input: surveyItem.survey_title,
  //   Surveylogo: 'Survey Logo',
  //   Display: 'Display',
  //   option1: 'One Question at time',
  //   option2: 'All Question at once',
  //   Language: 'Language',
  //   Language1: 'English',
  //   Language2: 'French',
  //   Language3: 'Russian',
  //   logourl: 'Logo Click URL',
  //   Categories: 'Categories',
  //   Save: 'Save Settings',
  //   input10: 'd-none',
  //   input3: 'd-none',
  //   input13: 'd-none',
  //   input14: 'd-none',
  // }));

  // const [inputValue, setInputValue] = useState('');
  // const [categorieValue, setCategorieValue] = useState('');

  const [postimg, setPostBgImg] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [isimgchange, setImagechange] = useState(false);

  const handleImageChange = (e) => {
    setImagechange(true);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    console.log('image', URL.createObjectURL(e.target.files[0]));
    dispatch(updateSurveyLogo(URL.createObjectURL(e.target.files[0])));
    localStorage.setItem('surveylogo', URL.createObjectURL(e.target.files[0]));
    setPostBgImg(e.target.files[0]);
  };

  const [postsuccessimg, setPostSuccBgImg] = useState(null);
  const [postwelcomeimg, setPostwelcomeimg] = useState(null);
  const [selectedSuccImage, setSelectedSuccImage] = useState(null);
  const [welcomeImg, setWelcomeImg] = useState(null);
  const [issuccimgchange, setSuccImagechange] = useState(false);
  const [checkwelcomeimg, setCheckwelcomeimg] = useState(false);

  const handleImageSuccess = (e) => {
    setSuccImagechange(true);
    setSelectedSuccImage(URL.createObjectURL(e.target.files[0]));
    setPostSuccBgImg(e.target.files[0]);
  };
  const handleWekcomeImg = (e) => {
    setCheckwelcomeimg(true);
    setWelcomeImg(URL.createObjectURL(e.target.files[0]));
    setPostwelcomeimg(e.target.files[0]);
  };

  // const handelEditsurveydata = async (event) => {
  //   event.preventDefault();
  //   // fetchSurveyList();
  //   setFetchdata(true);

  //   const uid = localStorage.getItem('uid');
  //   const formData = new FormData();
  //   formData.append('theFile', postsuccessimg);
  //   formData.append('surveyid', id);

  //   if (!postsuccessimg) {
  //     formData.append('logoFile', postimg);
  //     formData.append('logoFile', true);
  //   }

  //   if (postwelcomeimg) {
  //     formData.append('theFile', postwelcomeimg);
  //     formData.append('logoFile', true);
  //   }
  //   formData.append('userid', uid);

  //   try {
  //     const endpoint = API_ENDPOINTS.answerImagePOST;
  //     const res = await fetch(`${BASE_URL}${endpoint}`, {
  //       method: 'POST',
  //       body: formData,
  //     });
  //     if (res.status === 200) {
  //       const responseJson = await res.json();
  //       try {
  //         const endpoint = API_ENDPOINTS.surveyEdit;
  //         const requestBody = {
  //           survey_title: survetitle,
  //           survey_label: taglabel,
  //           survey_display:Display,
  //           survey_description: description,
  //           survey_categories: Category,
  //           survey_language: Language,
  //           status: logourl,
  //           success_msg: succmessage,
  //           welcome_msg: welcomemess,
  //           survey_enddate: selectdate,
  //         };
  //         if (isimgchange) {
  //           requestBody.logo = responseJson.logo === undefined ? '' : responseJson.logo;
  //         }
  //         if (postsuccessimg) {
  //           requestBody.success_img = responseJson.logo === undefined ? '' : responseJson.logo;
  //         }
  //         if (postwelcomeimg) {
  //           requestBody.welcome_img = responseJson.logo === undefined ? '' : responseJson.logo;
  //         }

  //         const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, {
  //           method: 'PUT',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },

  //           body: JSON.stringify(requestBody),
  //         });
  //         dispatch(showBackdropLoader());
          
  //         if (response.status === 200) {
  //           dispatch(updatesurveyData(survetitle));
  //           // dispatch(updatesurveylogoData(logourl));

  //           console.log(`Survey with ID ${id} was Edit successfully.`);
  //         }
  //       } catch (error) {
  //         console.error('An error occurred while deleting the survey:', error);
  //       }
  //     } else {
  //       console.error('Error posting Image :', res.status);
  //     }
  //   } catch (error) {
  //     console.error('Error posting Image :', error);
  //   }finally {
  //     setTimeout(() => {
  //         dispatch(closeBackdroploader());
  //     }, 500);
  //   }
  //   // setBackgoundurl();
  // };

  const handelEditsurveydata = async (event) => {
    event.preventDefault();
    setFetchdata(true);
  
    const uid = localStorage.getItem('uid');
    const formData = new FormData();
    formData.append('theFile', postsuccessimg);
    formData.append('surveyid', id);
  
    if (!postsuccessimg) {
      formData.append('logoFile', postimg);
      formData.append('logoFile', true);
    }
  
    if (postwelcomeimg) {
      formData.append('theFile', postwelcomeimg);
      formData.append('logoFile', true);
    }
    formData.append('userid', uid);
  
    try {
      const endpoint = API_ENDPOINTS.answerImagePOST;
      const res = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        body: formData,
      });
  
      if (res.status === 200) {
        const responseJson = await res.json();
        try {
          const editEndpoint = API_ENDPOINTS.surveyEdit;
          const fetchEndpoint = `${BASE_URL}${editEndpoint}?surveyid=${id}`;
          const surveyDataRes = await fetch(fetchEndpoint);
          const currentSurveyData = await surveyDataRes.json();
  
          const updatedSurveyData = {
            ...currentSurveyData.surveyid,
            survey_title: survetitle,
            survey_label: taglabel,
            survey_display: Display,
            survey_description: description,
            survey_categories: Category,
            survey_language: Language,
            status: logourl,
            success_msg: succmessage || currentSurveyData.surveyid.success_msg,
            welcome_msg: welcomemess || currentSurveyData.surveyid.welcome_msg,
            survey_enddate: selectdate || currentSurveyData.surveyid.survey_enddate,
          };

          // const formattedDate = dayjs(selectdate || currentSurveyData.surveyid.survey_enddate).format('YYYY-MM-DD');
        
          //   // setSurveydate(date);
        
          //   // Store the selected date in local storage
          //   localStorage.setItem('date', formattedDate);
        
  
          // Update images if provided
          if (isimgchange) {
            updatedSurveyData.logo = responseJson.logo || updatedSurveyData.logo;
          }
          if (postsuccessimg) {
            updatedSurveyData.success_img = responseJson.logo || updatedSurveyData.success_img;
          }
          if (postwelcomeimg) {
            updatedSurveyData.welcome_img = responseJson.logo || updatedSurveyData.welcome_img;
          }
  
          const response = await fetch(fetchEndpoint, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSurveyData),
          });
  
          dispatch(showBackdropLoader());
  
          if (response.status === 200) {
            dispatch(updatesurveyData(survetitle));
            console.log(`Survey with ID ${id} was edited successfully.`);
          }
        } catch (error) {
          console.error('An error occurred while editing the survey:', error);
        }
      } else {
        console.error('Error posting Image:', res.status);
      }
    } catch (error) {
      console.error('Error posting Image:', error);
    } finally {
      setTimeout(() => {
        dispatch(closeBackdroploader());
      }, 500);
    }
  };
  
  

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    try {
      const parsedLabels = JSON.parse(taglabel);
      setLabels(parsedLabels);
    } catch (error) {
      console.error('Failed to parse taglabel:', error);
      setLabels([]);
    }
  }, [taglabel]);

  // tag editor code y
  // const [taglabel, setSurveylabel] = useState([]);
  const [tagvalue, setTagvalue] = useState('');
  const [validation, setValidation] = useState(false);
 
  const handleTageditorChange = (event) => {
    setTagvalue(event.target.value);
  };

  const handleKeyDown = (event) => {
    const labels = JSON.parse(taglabel);
    if (labels.length >= 3) {
      setValidation(true);
      setTimeout(() => {
        setValidation(false);
      }, 1000);
    } else if (event.key === 'Enter' && tagvalue.trim() !== '') {
      const jsonconvert = [...labels, tagvalue];
      setSurveylabel(JSON.stringify(jsonconvert));
      setTagvalue('');
    }
  };
  // const handleDelete = (name) => {
  //   setSurveylabel(taglabel.filter((n) => n !== name));
  // };

  const handeltagDelete = (name) => {
    const parsedPersonName = JSON.parse(taglabel);
    const filteredList = parsedPersonName.filter((n) => n !== name);
    setSurveylabel(JSON.stringify(filteredList));
  };

  const todayDisable = new Date();
  const shouldDisableDate = (day) => day < todayDisable;
const dateSelect = (date) => {
 const formattedDate = dayjs(date).format('YYYY-MM-DD');
  // setSelectdate(formattedDate);

    // setSurveydate(date);

    // Store the selected date in local storage
    // localStorage.setItem('date', formattedDate);
};



  const categoryArray = [
    'Community or volunteer feedback',
    'Customer feedback',
    'Concept, product, or ad testing',
    'Brand tracking or awareness',
    'General market research',
    'Employee engagement',
    'Employee performance',
    'General employee feedback',
    'Event registration',
    'Event feedback',
    'Academic research',
    'Course evaluation',
    'Student or parent feedback',
    'Quiz',
    'Form or application',
    'Vote or poll',
    'Other',
  ];

  const languageArray = [
    'English',
    'French',
    'Arabic',
    'Chinese',
    'Czech',
    'Danish',
    'Dutch',
    'German',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Italian',
    'Japanese',
    'Korean',
    'Norwegian',
    'Persian',
    'Portuguese',
    'Romanian',
    'Russian',
    'Spanish',
    'Swedish',
    'Turkish',
    'Ukrainian',
    'Vietnamese',
    'Finnish',
    'Thai',
    'Tagalog',
    'Serbian',
    'Croatian',
    'Bulgarian',
  ];

const displayArray = [
  'One Question at time',
  'All question at Once'
]

useEffect(() => {
  const storedDisplayMode = localStorage.getItem('displayMode');
  if (storedDisplayMode) {
    setDisplay(storedDisplayMode);
  } else {
    setDisplay(displayArray[0]); // Default value if nothing is stored
  }
}, []);

// Store the selected display mode in localStorage whenever it changes
const handleDisplayChange = (e) => {
  const selectedDisplayMode = e.target.value;
  setDisplay(selectedDisplayMode);
  localStorage.setItem('displayMode', selectedDisplayMode);
};

  return (
    <div>
      
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  >
        <CircularProgress color="inherit"  />
      </Backdrop>
      {formdata.map((getformdata, index) => (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <form key={index} style={{ marginTop: '' }}>
            <Row className={getformdata.input}>
              <div className="mb-4">
                {/* survey Logo */}
                <Form.Group
                  className={`${getformdata.input12} col-md-6 col-lg-6 d-flex m-lg-0 mb-3`}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Form.Label className="ms-2">{getformdata.Surveylogo}</Form.Label>
                  {/* <Form.Control className={getformdata.input2} type="file" name="file" /> */}
                  <div
                    id="input"
                    className={`${getformdata.input2} mb-3`}
                    style={{
                      position: 'relative',
                      border: '2px solid #a7a7a7',
                      borderRadius: '12px',
                      borderStyle: 'dashed',
                      background: '#fffce8',
                      backgroundImage: isimgchange
                        ? `url(${selectedImage})`
                        : `url(${selectedImage}?v=${new Date().getTime()})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: '70px',
                      width: '150px',
                    }}
                  >
                    <input
                      id="input"
                      style={{
                        cursor: 'pointer',
                        opacity: '0',
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                        zIndex: '1',
                      }}
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                    <RiFileAddLine
                      style={{
                        fontSize: '40px',
                        color: '#8c8c8c',
                        position: 'absolute',
                        top: '50%',
                        right: '50%',
                        transform: 'translate(46%, -50%)',
                      }}
                    />
                  </div>
                </Form.Group>
              </div>

              <Form.Group className={`${getformdata.input15} col-md-6 col-lg-6 mb-4`}>
                <Form.Label className={getformdata.input23}>{getformdata.surveytitle}</Form.Label>

                <Form.Control
                  id="input"
                  className={getformdata.input1}
                  type="text"
                  name="edittitle"
                  value={survey}
                  placeholder="Enter title "
                  onChange={handleChange}
                />
              </Form.Group>

              {/* Display */}
              <Form.Group className={`${getformdata.input4}`} as={Col} md="6">
      <Form.Label>{getformdata.Display}</Form.Label>
      <Form.Select
        onChange={handleDisplayChange}
        value={Display}
        id="input"
        className=""
        aria-label="Default select example"
      >
        {displayArray.map((data, index) => (
          <option key={index} value={data}>{data}</option>
        ))}
      </Form.Select>
    </Form.Group>

              {/* description */}
              <Form.Group className={`${getformdata.input12} col-md-6 col-lg-6 m-lg-0 mb-3 `}>
                <Form.Label>{getformdata.description}</Form.Label>
                <Form.Control
                  id="input"
                  value={description}
                  onChange={handleDescription}
                  placeholder="Enter survey description"
                  as="textarea"
                  rows={2}
                />
              </Form.Group>

              {/* categories  */}
              <Form.Group className={`${getformdata.input20} `} as={Col} md="6">
      <Form.Label>{getformdata.Category}</Form.Label>
      <Form.Select
        value={Category} // Set the value from state
        onChange={(e) => {
          setCategory(e.target.value); // Update state with selected value
        }}
        id="input"
        className=""
        aria-label="Default select example"
      >
        <option value="">Select an option</option> {/* Placeholder option */}
        {categoryArray.map((data, index) => (
          <option key={index} value={data}>
            {data}
          </option>
        ))}
      </Form.Select>
    </Form.Group>

              <Form.Group className={`{getformdata.input7} col-md-6 col-lg-6 mt-4 controlId="validationFormik05"`}>
                <Form.Label>{getformdata.Categoriestitle}</Form.Label>
                <FormControl className="w-100">
      <div style={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
        <Form.Control
          id="demo-multiple-chip"
          className="mb-2"
          value={tagvalue}
          onChange={handleTageditorChange}
          onKeyDown={handleKeyDown}
          fullWidth
          placeholder="Enter labels"
          sx={{ flexGrow: 1, minHeight: 'auto' }}
        />
        {validation && (
          <div style={{ color: 'red', position: 'absolute', left: '0px', bottom: '-10px',top:'80px' }}>
            Max limit three
          </div>
        )}
        {labels.map((value) => (
          <Chip
            style={{ backgroundColor: '#c3c4c4', color: 'black' }}
            key={value}
            label={value}
            onDelete={() => handeltagDelete(value)}
            sx={{ marginRight: 0.5, marginBottom: 0.5 }}
          />
        ))}
      </div>
    </FormControl>
              </Form.Group>
              {/* language */}
              <Form.Group className={`${getformdata.input}  mt-4`} as={Col} md="6">
                <Form.Label>{getformdata.Language}</Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                  id="input"
                  className=""
                  aria-label="Default select example"
                  value={Language}
                >
                  {languageArray.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              
              <Form.Group className={`${getformdata.input9} col-md-6 col-lg-6 mb-4 mt-4 `}>
                <Form.Label>{getformdata.logourl}</Form.Label>
                <Form.Control
                  id="input"
                  onChange={handelLogourl}
                  type="text"
                  placeholder="https://app.surveyslack.com"
                  name="state"
                  value={logourl}
                />
              </Form.Group>

              <Form.Group className={`${getformdata.input7} mt-4 mb-4`} as={Col} md="6">
                <div>End Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs} className="pt-0">
                  <DemoContainer components={['DatePicker']} className="pt-0">
                    <DatePicker
                      id="input"
                      format="YYYY-MM-DD"
                      minDate={today}
                      shouldDisableDate={shouldDisableDate}
                      disablePast
                      onChange={dateSelect}
                      value={dayjs(surevyendDate)} 
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Form.Group>


            </Row>
            {/* logo click url */}
           
            <Form.Group className="mb-3 d-none ">
              <Form.Check
                style={{ padding: '0px ' }}
                className={`${getformdata.input8} d-flex checkboxDivs`}
                name="terms"
                label="&nbsp;&nbsp;Allow participants to go back to the previous question.
                  "
              />
              <Form.Check
                style={{ padding: '0px' }}
                className={`${getformdata.input8} d-flex checkboxDivs`}
                name="terms"
                label="&nbsp;&nbsp;Prevent multiple submissions from the same device."
                feedbackType="invalid"
                id="validationFormik0"
              />
            </Form.Group>
            {/* welcome part */}
            <Form.Group className={getformdata.input14} as={Col} md="12">
              <Form.Label>{getformdata.welcome}</Form.Label>
              <div
                className={getformdata.input2}
                style={{
                  position: 'relative',
                  border: '2px solid #a7a7a7',
                  borderRadius: '12px',
                  borderStyle: 'dashed',
                  background: '#fffce8',
                  backgroundImage: checkwelcomeimg
                    ? `url(${welcomeImg})`
                    : `url(${welcomeImg}?v=${new Date().getTime()})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '70px',
                  width: '150px',
                }}
              >
                <input
                  style={{ opacity: '0', height: '100%', width: '100%' }}
                  type="file"
                  onChange={handleWekcomeImg}
                  accept="image/*"
                />
                <RiFileAddLine
                  style={{
                    fontSize: '40px',
                    color: '#8c8c8c',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    transform: 'translate(46%, -50%)',
                  }}
                />
              </div>
            </Form.Group>
            {/* welcome screen */}
            <Form.Group className={`${getformdata.input18} mb-3 mt-4 col-md-12 col-lg-12`}>
              <Form.Label className={getformdata.input22}>{getformdata.surveytitle}</Form.Label>
              <Form.Control
                id="input"
                className={getformdata.input1}
                type="text"
                name="edittitle"
                value={survey}
                placeholder="Enter title "
                onChange={handleChange}
              />
              {/* welcome text */}
              <Form.Control
                id="input"
                value={welcomemess}
                onChange={handelWelScreen}
                placeholder="Welcome Message"
                className={getformdata.input10}
                as="textarea"
                rows={3}
              />
            </Form.Group>
            {/* success  img  */}
            <Form.Group className={getformdata.input13} as={Col} md="12">
              <Form.Label>{getformdata.successlogo}</Form.Label>
              {/* <Form.Control className={getformdata.input5} type="file" name="file" />  */}
              {/* <Form.Label className="ms-2">{getformdata.Surveylogo}</Form.Label> */}
              <div
                className={getformdata.input2}
                style={{
                  position: 'relative',
                  border: '2px solid #a7a7a7',
                  borderRadius: '12px',
                  borderStyle: 'dashed',
                  background: '#fffce8',
                  backgroundImage: issuccimgchange
                    ? `url(${selectedSuccImage})`
                    : `url(${selectedSuccImage}?v=${new Date().getTime()})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '70px',
                  width: '150px',
                }}
              >
                <input
                  style={{ opacity: '0', height: '100%', width: '100%' }}
                  type="file"
                  onChange={handleImageSuccess}
                  accept="image/*"
                />
                <RiFileAddLine
                  style={{
                    fontSize: '40px',
                    color: '#8c8c8c',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    transform: 'translate(46%, -50%)',
                  }}
                />
              </div>
            </Form.Group>
            {/* success Message */}
            <Form.Group className={`{getformdata.input13} mt-4 mb-3`} as={Col} md="12">
              <Form.Label className={getformdata.input21}>{getformdata.successmessage}</Form.Label>
              <Form.Control
                id="input"
                value={succmessage}
                onChange={handleSuccess}
                placeholder="Thanks for taking this survey."
                as="textarea"
                className={getformdata.input13}
                rows={3}
              />
            </Form.Group>
            <Button  className="bgButton" color="success" variant="contained" onClick={handelEditsurveydata}>
              {getformdata.Save}
            </Button>{' '}

            
          </form>
        </>
      ))}
    </div>
  );
}

export default General;
