

 
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import '../Style/SelectAnswerTypeModal.css'; // Import the CSS file

const SelectAnswerTypeModal = ({ isOpen, toggleModal }) => {
  const handleKeyDown = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: '1px',
      zIndex: 20,
      width: '100%',
      height: '100%',
      background: '#80808059',
      right: 0,
      display: isOpen ? 'block' : 'none',
    }}>
      <div className="modal-container"> {/* Use className instead of inline styles */}
        <div className='main-container' style={{
          position: 'absolute',
          maxWidth: '403px',
          minWidth: '200px',
          padding: '34px 20px 20px 60px',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 15px',
          backgroundColor: 'rgb(243, 245, 249)',
          color: 'rgb(56, 63, 80)',
          right: '9px',
          top: '100px',
          opacity: 1,
          // Center the modal horizontally by default
        }}>
          <div style={{
            position: 'absolute',
            left: '-22px',
            right: 0,
            bottom: 0,
            width: '50px',
            height: '100%',
            borderRadius: '3px 0 0 3px',
            backgroundColor: '#ffc64d',
          }}>
            <FontAwesomeIcon icon={faExclamation} style={{ color: 'white', fontSize: '24px', padding: '24px', marginTop: '70px' }} />
          </div>
          <h3 style={{
            fontSize: '20px',
            textAlign: 'left',
            fontWeight: 'bold',
            color: '#333',
          }}>Select Answer Type.</h3>
          <p style={{ fontSize: '16px', color: '#333' }}>
            For Creating a question need to select type of question.
          </p>
          <nav style={{ textAlign: 'center' }}>
            <ul style={{
              listStyleType: 'none',
              display: 'flex',
              justifyContent: 'flex-around',
              marginTop: '20px',
              padding: 0,
            }}>
              <li>
                <div
                  style={{
                    padding: '15px',
                    fontSize: '14px',
                    color: '#fff',
                    backgroundColor: '#bcbcbc',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    width: '130px',
                    textAlign: 'center',
                  }}
                  onClick={toggleModal}
                  onKeyDown={(event) => handleKeyDown(event, toggleModal)}
                  tabIndex="0"
                  role="button"
                  id="cancelDeleteButton"
                >
                  OK
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SelectAnswerTypeModal;

