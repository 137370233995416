import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../Style/Questionlistingpage.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Loader from './component-part/Loader';


export default function Tabpanel({ tabdata }) {
  const [value, setValue] = React.useState('1');
  const [expanded, setExpanded] = React.useState(`panel1`);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  // const [tabone, setTabone] = React.useState(true);
  // const [tabtwo, setTabtwo] = React.useState(true);
  // const [tabthree, setTabhtree] = React.useState(true);
  // const [tabfour, setTabfour] = React.useState(true);
  // const questiontab = useSelector((state) => state.questiontab)
  // useEffect (()=>{
  //   const url = window.location.href;
  //   const path = url.substring(url.indexOf("/surveycreatelayout/"));
  //   console.log(path);
  //   if (path==='/surveycreatelayout/share') {
  //     setTabhtree(false)
  //     setTabfour(false)
  //   }
  //   if (path==='/surveycreatelayout/thems') {
  //     setTabfour(false)
  //   }
  //   if (path==='/surveycreatelayout/questions') {
  //     setTabhtree(false)
  //     setTabfour(false)
  //   }
  //   if (path==='/surveycreatelayout/preview') {
  //     setTabfour(false)
  //   }
  //   if (path==='/surveycreatelayout/result') {
  //     setTabfour(false)
  //   }
  // },[])

  const getformdata = () => {
    // Your implementation here
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    // <Box sx={{ width: '100%', typography: 'body1' }}>
    //   {tabdata.map((gettabdata ,index) => <>
    //     <TabContext key={index}  value={value} >
    //       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    //         <TabList className='tab-css' onChange={handleChange} aria-label="lab API tabs example">
    //           {/* <Tab label="Item One" value="1" /> */}
    //           <Tab className='p-1 p-lg-4 ' label={gettabdata.itemtabone} value="1" />
    //           <Tab style={{textDecoration:'unset'}} className='p-1 p-lg-4' label={gettabdata.itemtabtwo} value="2" />
    //           <Tab className='p-1 p-lg-4' label={gettabdata.itemtabthree} value="3" />
    //           <Tab className='p-1 p-lg-4 ' label={gettabdata.itemtabfourth} value="4" />
    //           <Tab className='p-1 p-lg-4 ' label={gettabdata.itemtabfive} value="5" />
    //           {/* { tabone && <Tab label={gettabdata.itemtabone} value="1" />}
    //         {tabtwo &&   <Tab label={gettabdata.itemtabtwo} value="2" />}
    //         { tabthree && <Tab label={gettabdata.itemtabthree} value="3" />}
    //         { tabfour &&  <Tab  label={gettabdata.itemtabfourth} value="4" /> } */}
    //         </TabList>
    //       </Box>
    //       <TabPanel value="1">{gettabdata.itemone}</TabPanel>
    //       <TabPanel value="2">{gettabdata.itemtwo}</TabPanel>
    //       <TabPanel value="3">{gettabdata.itemthree}</TabPanel>
    //       <TabPanel value="4">{gettabdata.itemfourth}</TabPanel>
    // <TabPanel value="5">{gettabdata.itemfive}</TabPanel>

    //     </TabContext>
    //   </>
    //   )}
    // </Box>

    // accordian

      <Box sx={{ width: '100%', typography: 'body1' }}>
        {tabdata.map((gettabdata, index) => (
          <div key={index}>
            <Accordion style={{zIndex:'auto'}}
              expanded={expanded === `panel${index + 1}`}
              onChange={handleAccordionChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                className="custom-accordion-summary"
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography>{gettabdata.itemtabone}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{gettabdata.itemone}</Typography>
              </AccordionDetails>
            </Accordion>
  
            <Accordion style={{zIndex:'auto'}}
              expanded={expanded === `panel${index + 2}`}
              onChange={handleAccordionChange(`panel${index + 2}`)}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                className="custom-accordion-summary"
                aria-controls={`panel${index + 2}-content`}
                id={`panel${index + 2}-header`}
              >
                <Typography>{gettabdata.itemtabtwo}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{gettabdata.itemtwo}</Typography>
              </AccordionDetails>
            </Accordion>
  
            <Accordion style={{zIndex:'auto'}}
              expanded={expanded === `panel${index + 3}`}
              onChange={handleAccordionChange(`panel${index + 3}`)}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                className="custom-accordion-summary"
                aria-controls={`panel${index + 3}-content`}
                id={`panel${index + 3}-header`}
              >
                <Typography>{gettabdata.itemtabfive}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{gettabdata.itemfive}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Box>
    );
  }