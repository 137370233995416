import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { BiArrowBack } from 'react-icons/bi';
import { API_ENDPOINTS, BASE_URL } from '../../api_folder/config';

function AllanswerDetail() {
  const surveyidforallanswer = localStorage.getItem('SURVEY_ID');
  const [responses, setResponses] = useState([]);
  const [questionType, setQuestionType] = useState('');
  const [answer, setAnswer] = useState('');
  const url = window.location.href;
  const urlObject = new URL(url);
  const queid = urlObject.pathname.split('/').pop();

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const endpoint = API_ENDPOINTS.getsurveyresponse;
        const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyidforallanswer}`);
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.data && Array.isArray(responseData.data)) {
            const surveyData = responseData.data.filter((entry) => entry.questionid === queid);
            setResponses(surveyData);
          } else {
            console.error('Fetched data is not in the expected format:', responseData);
          }
        } else {
          console.error(`Failed to fetch questions. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while fetching questions:', error);
      }
    };

    fetchAnswers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyidforallanswer]);

  useEffect(() => {
    const fetchQuestionType = async () => {
      try {
        const endpoint = API_ENDPOINTS.questionListGET;
        const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyidforallanswer}`);
        if (response.ok) {
          const data = await response.json();
          const question = data.data.find((entry) => entry.questionid === queid);
          if (question) {
            setQuestionType(question.question_type);
            console.log(question.answer);
            setAnswer(question.answer);
          }
        } else {
          console.error(`Failed to fetch questions. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while fetching questions:', error);
      }
    };

    fetchQuestionType();
  }, [surveyidforallanswer, queid]);

  // const calculatePercentageForQuestionMultiple = (responses, questionId, answer) => {
  //   const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);

  //   let editedResponsesNew = [];

  //   try {
  //     // Attempt to parse the answer JSON to get the edited responses
  //     editedResponsesNew = Array.isArray(answer) ? answer : JSON.parse(answer);
  //     if (!Array.isArray(editedResponsesNew)) {
  //       editedResponsesNew = [editedResponsesNew];
  //     }
  //   } catch (error) {
  //     console.error('Error parsing answer JSON:', error);
  //   }

  //   // Calculate counts for each response value obtained from JSON parse
  //   const countsNew = {};
  //   let totalCount = 0; // Initialize total count for responses

  //   filteredResponsesNew.forEach((response) => {
  //     try {
  //       JSON.parse(response.response_text)
  //         .filter((value) => value && value.trim() !== '')
  //         .forEach((value) => {
  //           // Increment the count for each response value
  //           countsNew[value] = (countsNew[value] || 0) + 1;
  //           totalCount += 1; // Increment total count
  //         });
  //     } catch (error) {
  //       console.error('Error parsing response text JSON:', error);
  //     }
  //   });
    

  //   // Calculate percentage for each value
  //   const percentagesNew = {};
  //   Object.entries(countsNew).forEach(([responseValue, count]) => {
  //     const isEditedNew = editedResponsesNew.includes(responseValue); // Check if the response is edited

  //     // Calculate percentage for each value based on total count of selected values
  //     const percentageNew = (count / totalCount) * 100;

  //     // Ensure percentage is limited to 100%
  //     percentagesNew[responseValue] = { percentageNew: Math.min(percentageNew, 100), isEditedNew };
  //   });

  //   return { percentagesNew };
  // };
  const renderLocalTime = (dateTimeString) => {
    const date = new Date(dateTimeString); // Parse string into Date object
    const utcTime = date.getTime(); // Get UTC time in milliseconds
    const offset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds for UTC +5:30
    const localTime = new Date(utcTime + offset); // Adjust to local time zone
    const year = localTime.getFullYear();
    const month = String(localTime.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(localTime.getDate()).padStart(2, '0'); // Add leading zero if needed
    const hours = String(localTime.getHours()).padStart(2, '0'); // Add leading zero if needed
    const minutes = String(localTime.getMinutes()).padStart(2, '0'); // Add leading zero if needed
    const seconds = String(localTime.getSeconds()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const calculatePercentageForQuestionMultiple = (responses, questionId, answer) => {
    const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);
  
    // Parse the answer JSON to get the edited responses
    const editedResponsesNew = Array.isArray(answer) ? answer : [];
  
    // Calculate counts for each response value obtained from JSON parse
    const countsNew = {};
    let totalCount = 0; // Initialize total count for responses
  
    filteredResponsesNew.forEach((response) => {
      try {
        JSON.parse(response.response_text)
          .filter((value) => value && value.trim() !== '')
          .forEach((value) => {
            // Increment the count for each response value
            countsNew[value] = (countsNew[value] || 0) + 1;
            totalCount += 1; // Increment total count
          });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    });
  
    // Calculate percentage for each value
    const percentagesNew = {};
    Object.entries(countsNew).forEach(([responseValue, count]) => {
      // Check if the response value is edited
      const isEditedNew = editedResponsesNew.includes(responseValue);
  
      // Calculate percentage for each value based on total count of selected values
      const percentageNew = (count / totalCount) * 100;
  
      // Ensure percentage is limited to 100%
      percentagesNew[responseValue] = { percentageNew: Math.min(percentageNew, 100), isEditedNew };
    });
  
    return { percentagesNew };
  };
  
  const calculatePercentageForQuestionNew = (responses, questionId, questionType) => {
    const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);
    const editedResponsesNew = Array.isArray(questionType) ? questionType : [];

    const countsNew = {};
    filteredResponsesNew.forEach((response) => {
      countsNew[response.response_text] = (countsNew[response.response_text] || 0) + 1;
    });

    const percentagesNew = {};
    Object.entries(countsNew).forEach(([responseText, count]) => {
      const isEditedNew = editedResponsesNew.includes(responseText);
      const percentageNew = (count / filteredResponsesNew.length) * 100;
      percentagesNew[responseText] = { percentageNew, isEditedNew };
    });

    return percentagesNew;
  };

  let startRange = null;
  let endRange = null;
  const calculatePercentageForLinearScale = (responses, questionId, answer) => {
    const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);
    let editedResponsesNew = [];

    // Check if answer is a valid JSON string
    try {
      editedResponsesNew = JSON.parse(answer);
      // If answer is not an array, convert it to an array
      if (!Array.isArray(editedResponsesNew)) {
        editedResponsesNew = [editedResponsesNew];
      }
    } catch (error) {
      console.error('Error parsing answer JSON:', error);
    }

    const editedResponses1New = filteredResponsesNew.map((response) => response.response_text);
    const isEditNewlinear = editedResponsesNew.map((response, index) => response[index] === editedResponsesNew[index]);

    const countsNew = {};
    filteredResponsesNew.forEach((response) => {
      countsNew[response.response_text] = (countsNew[response.response_text] || 0) + 1;
    });

    const percentagesNew = {};
    Object.entries(countsNew).forEach(([responseText, count]) => {
      const isEditedNew = editedResponsesNew.includes(responseText); // Check if the response is edited
      const percentageNew = (count / filteredResponsesNew.length) * 100;
      percentagesNew[responseText] = { percentageNew, isEditedNew };
    });

    console.log('percentagesNew:', percentagesNew);

    Object.entries(percentagesNew).forEach(([index, { isEditedNew }]) => {
      if (isEditedNew && startRange === null) {
        startRange = parseInt(index, 10); // Set startRange when isEditedNew becomes true for the first time
      } else if (isEditedNew && startRange !== null) {
        endRange = parseInt(index, 10); // Set endRange when isEditedNew becomes true for the second time
      }
    });

    return percentagesNew;
  };

  const calculateMinMaxAvgForQuestion = (responses, questionId) => {
    const filteredResponses = responses.filter((response) => response.questionid === questionId);
    const values = filteredResponses.map((response) => parseInt(response.response_text, 10)); // Specify radix 10
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = values.reduce((acc, curr) => acc + curr, 0) / values.length;
    return { min, max, avg };
  };
  const MAX = 100;
  const AVG = 50;
  const MIN = 0;
  const [val, setVal] = React.useState(MIN);
  const handleChange = (_, newValue) => {
    setVal(newValue);
  };

  const calculateMinMaxAvgForDate = (responses, questionId) => {
    const filteredResponses = responses.filter((response) => response.questionid === questionId);
    const dates = filteredResponses.map((response) => new Date(response.response_text));
    const minDate = new Date(Math.min(...dates.map((date) => date.getTime())));
    const maxDate = new Date(Math.max(...dates.map((date) => date.getTime())));
    const avgTimestamp = dates.reduce((acc, curr) => acc + curr.getTime(), 0) / dates.length;
    const avgDate = new Date(avgTimestamp);
    return { min: minDate, max: maxDate, avg: avgDate };
  };

  const getColorForSegment = (() => {
    // Map to track assigned colors for each percentage
    const assignedColorsMap = {};

    return () => {
      // Define the available colors
      const availableColors = [
        '#D7C0AE',
        '#FFEBB4',
        '#F3CCFF',
        '#C8FFD4',
        '#a9c0cb',
        '#dba39a',
        '#CDFADB',
        '#F5EEE6',
        '#EFBC9B',
        '#FFFDCB',
        '#BED1CF',
        '#80BCBD',
        '#E5D4FF',
        '#89CFF3',
      ];

      // Shuffle the available colors array randomly
      let i = availableColors.length - 1;
      while (i > 0) {
        const j = Math.floor(Math.random() * (i + 1));
        [availableColors[i], availableColors[j]] = [availableColors[j], availableColors[i]];
        // Decrement i inside the loop body using -= operator
        i -= 1;
      }

      // Get the first color from the shuffled array
      const selectedColor = availableColors[0];

      // If the color has been assigned to any percentage, remove it from available colors
      if (assignedColorsMap[selectedColor]) {
        availableColors.shift();
        return availableColors[0];
      }

      // Return the selected color
      return selectedColor;
    };
  })();

  return (
    // matrix mcq
    <div>
      <div>
        <NavLink to={`/survey/${surveyidforallanswer}/result`}>
          {' '}
          <BiArrowBack style={{ fontSize: 'xx-large' }} />
        </NavLink>
      </div>
      {/* {questionType === '10' && (
        <table className="tableofShortAns text-muted">
          <thead>
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Row value</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {responses.map((response, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{response.created_date}</td>
                <td>
                  {response.response_text &&
                    JSON.parse(response.response_text).map((obj, index) =>
                      Object.entries(obj).map(([key, value]) => <div key={index}>{key}</div>)
                    )}
                </td>
                <td>
                  {response.response_text &&
                    JSON.parse(response.response_text).map((obj, index) =>
                      Object.entries(obj).map(([key, value]) => <div key={index}>{value}</div>)
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )} */}
{questionType === '10' && (
  <div>
    <table className="tableofShortAns text-muted">
      <thead>
        <tr>
          <th>Row Value</th>
          {answer && JSON.parse(answer)[1]?.map((input, index) => (
            <th key={input.id}>{input.columnvalue}</th>
          ))}
          {answer && JSON.parse(answer)[2] === true && <th>N/A</th>}
        </tr>
      </thead>
      <tbody>
        {(() => {
          const aggregatedResponses = {};

          responses.forEach((response) => {
            if (response.questionid === queid) {
              const rowValues = JSON.parse(response.response_text);

              rowValues.forEach((rowValue) => {
                Object.entries(rowValue).forEach(([key, value]) => {
                  if (!aggregatedResponses[key]) {
                    aggregatedResponses[key] = {};
                  }
                  if (!aggregatedResponses[key][value]) {
                    aggregatedResponses[key][value] = 0;
                  }
                  aggregatedResponses[key][value] += 1; // Increment count
                });
              });
            }
          });

          const totalResponses = responses.filter((res) => res.questionid === queid).length;

          return Object.entries(aggregatedResponses).map(([rowKey, rowValues], idx) => {
            const totalSelectedValuesForRow = Object.values(rowValues).reduce((acc, val) => acc + val, 0);

            return (
              <tr key={idx}>
                <td>{rowKey}</td>
                {answer && JSON.parse(answer)[1]?.map((input, columnIndex) => {
                  const columnValue = input.columnvalue;
                  const selectedColumnCount = rowValues[columnValue] || 0;
                  const percentage = totalSelectedValuesForRow === 0 ? 0 : (selectedColumnCount / totalSelectedValuesForRow) * 100;

                  return (
                    <React.Fragment key={columnIndex}>
                      <td >
                        {selectedColumnCount} - {percentage.toFixed(0)}%
                      </td>
                    </React.Fragment>
                  );
                })}
                {answer && JSON.parse(answer)[2] === true && <td >0%</td>}
              </tr>
            );
          });
        })()}
      </tbody>
    </table>
  </div>
)}


{questionType === '11' && (
  <div>
    <table className="tableofShortAns text-muted">
      <thead>
        <tr>
          <th>Row Value</th>
          {answer && JSON.parse(answer)[1]?.map((input, index) => (
            <th key={input.id}>{input.checkboxcolumn}</th>
          ))}
          {answer && JSON.parse(answer)[2] === true && <th>N/A</th>}
        </tr>
      </thead>
      <tbody>
        {(() => {
          const aggregatedResponses = {};

          responses.forEach((response) => {
            if (response.questionid === queid) {
              const rowValues = JSON.parse(response.response_text);

              rowValues.forEach((rowValue) => {
                Object.entries(rowValue).forEach(([key, value]) => {
                  if (!aggregatedResponses[key]) {
                    aggregatedResponses[key] = {};
                  }
                  if (!aggregatedResponses[key][value]) {
                    aggregatedResponses[key][value] = 0;
                  }
                  aggregatedResponses[key][value] += 1; // Increment count
                });
              });
            }
          });

          const totalResponses = responses.filter((res) => res.questionid === queid).length;

          return Object.entries(aggregatedResponses).map(([rowKey, rowValues], idx) => {
            const totalSelectedValuesForRow = Object.values(rowValues).reduce((acc, val) => acc + val, 0);

            return (
              <tr key={idx}>
                <td>{rowKey}</td>
                {answer && JSON.parse(answer)[1]?.map((input, columnIndex) => {
                  const columnValue = input.checkboxcolumn;
                  const selectedColumnCount = rowValues[columnValue] || 0;
                  const percentage = totalSelectedValuesForRow === 0 ? 0 : (selectedColumnCount / totalSelectedValuesForRow) * 100;

                  return (
                    <React.Fragment key={columnIndex}>
                      <td >
                        {selectedColumnCount} - {percentage.toFixed(0)}%
                      </td>
                    </React.Fragment>
                  );
                })}
                {answer && JSON.parse(answer)[2] === true && <td >0%</td>}
              </tr>
            );
          });
        })()}
      </tbody>
    </table>
  </div>
)}

      {/* short answer */}
      {questionType === '2' && (
        <div>
          <table className="tableofShortAns text-muted">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  {/* You can format the date as needed */}
                  <td>{renderLocalTime(response.created_date)}</td>
                  <td>{response.response_text}</td>
                </tr>
              ))}
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      )}
      {/* {questionType === '12' && (
        <div>
          <div className="multipleanserdisplay">
            <div className="headprogress">

              {Object.entries(
                calculatePercentageForQuestionMultiple(responses, queid, questionType).percentagesNew
              ).map(([responseValue, { percentageNew, isEditedNew }], index) => (
                <div key={index}>
                  <div className="progress" role="progressbar" aria-label={`${responseValue} example`}>
                    <div
                      className={`progress-bar ${isEditedNew ? 'edited' : ''}`}
                      style={{
                        width: `${percentageNew.toFixed(2)}%`,
                        backgroundColor: getColorForSegment(percentageNew),
                      }}
                      aria-valuenow={percentageNew}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {isEditedNew
                        ? `Deleted - ${percentageNew.toFixed(2)}%`
                        : `${responseValue} - ${percentageNew.toFixed(2)}%`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <table className="tableofShortAns text-muted">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((response, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                
                    <td>{response.created_date}</td>
                    <td>
                      {JSON.parse(response.response_text)
                        .filter((value) => value && value.trim() !== '')
                        .join(', ')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )} */}
{questionType === '12' && (
  <div>
    <div className="multipleanserdisplay">
      <div className="headprogress">
        {/* Render progress bars */}
        {Object.entries(
          calculatePercentageForQuestionMultiple(responses, queid, questionType).percentagesNew
        ).map(([responseValue, { percentageNew, isEditedNew }], index) => (
          <div key={index}>
            <div className="progress" role="progressbar" aria-label={`${responseValue} example`}>
              <div
                className={`progress-bar ${isEditedNew ? 'edited' : ''}`}
                style={{
                  width: `${percentageNew.toFixed(2)}%`, // Apply the percentage as the width
                  backgroundColor: getColorForSegment(percentageNew),
                }}
                aria-valuenow={percentageNew}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {isEditedNew
                  ? `Deleted - ${percentageNew.toFixed(2)}%`
                  : `${responseValue} - ${percentageNew.toFixed(2)}%`}
              </div>
            </div>
          </div>
        ))}
      </div>
      <table className="tableofShortAns text-muted">
        <thead>
          <tr>
            <th>No.</th>
            <th>Date</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {responses.map((response, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              {/* You can format the date as needed */}
              <td>{renderLocalTime(response.created_date)}</td>
              <td>
              {(() => {
  try {
    const parsedResponse = JSON.parse(response.response_text);
    if (Array.isArray(parsedResponse)) {
      return parsedResponse
        .filter((value) => value && value.trim() !== '')
        .join(', ');
    }
    // Handle non-array JSON data here
    return 'Invalid JSON data';
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return 'Error parsing JSON';
  }
})()}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)}

      {questionType === '3' && (
        <div>
          <div style={{ padding: '30px' }}>
            <div className="progress d-flex justify-content-around">
              {Object.entries(calculatePercentageForQuestionNew(responses, queid, questionType)).map(
                ([responseText, { percentageNew, isEditedNew }]) => {
                  return (
                    <div
                      key={responseText}
                      className={`progress-bar ${isEditedNew ? 'edited' : ''}`}
                      role="progressbar"
                      style={{
                        width: `${percentageNew}%`,
                        backgroundColor: getColorForSegment(percentageNew),
                      }}
                      aria-valuenow={percentageNew}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {isEditedNew ? 'Deleted' : `${responseText} - ${percentageNew.toFixed(2)}%`}
                    </div>
                  );
                }
              )}
            </div>
            <table className="tableofShortAns text-muted">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((response, idx) => {
                  const { isEditedNew } = calculatePercentageForQuestionNew(responses, queid, questionType)[
                    response.response_text
                  ];
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{renderLocalTime(response.created_date)}</td>
                      <td>{isEditedNew ? 'Deleted' : response.response_text}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {questionType === '4' && (
        <div>
          <div className="headprogress">
            {/* Render progress bars */}
            {Object.entries(calculatePercentageForQuestionNew(responses, queid, questionType)).map(
              ([responseText, { percentageNew, isEditedNew }]) => (
                <div key={responseText} className="progress" role="progressbar" aria-label={`${responseText} example`}>
                  <div
                    className={`progress-bar ${isEditedNew ? 'edited' : ''}`}
                    role="progressbar"
                    style={{
                      width: `${percentageNew}%`,
                      backgroundColor: getColorForSegment(percentageNew),
                    }}
                    aria-valuenow={percentageNew}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {isEditedNew
                      ? `Deleted - ${percentageNew.toFixed(2)}%`
                      : `${responseText} - ${percentageNew.toFixed(2)}%`}
                  </div>
                </div>
              )
            )}
          </div>
          <table className="tableofShortAns text-muted">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{renderLocalTime(response.created_date)}</td>
                  <td>{response.response_text}</td>
                </tr>
              ))}{' '}
            </tbody>
          </table>
        </div>
      )}

      {/* linear scale */}
      {questionType === '5' && (
        <div>
          <div className="headprogress">
            {Object.entries(calculatePercentageForLinearScale(responses, queid, questionType))

              .reduce((chunks, [responseText, { percentageNew, isEditNewlinear }], index) => {
                const chunkIndex = Math.floor(index / 2);
                if (!chunks[chunkIndex]) {
                  chunks[chunkIndex] = [];
                }
                const label =
                  index + 1 >= startRange && index + 1 <= endRange
                    ? ` Deleted - ${percentageNew.toFixed(2)}%`
                    : `${responseText}- ${percentageNew.toFixed(2)}%`;
                chunks[chunkIndex].push(
                  <div
                    key={responseText}
                    className="progress"
                    role="progressbar"
                    aria-label={`${responseText} example`}
                    style={{ flex: 1, marginRight: '10px' }}
                  >
                    <div
                      className={`progress-bar ${isEditNewlinear ? 'edited' : ''}`}
                      role="progressbar"
                      style={{
                        width: `${percentageNew}%`,
                        backgroundColor: getColorForSegment(percentageNew),
                      }}
                      aria-valuenow={percentageNew}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {label}
                    </div>
                  </div>
                );
                return chunks;
              }, [])
              .map((chunk, chunkIndex) => (
                <div key={chunkIndex} className="progress-row">
                  {chunk}
                </div>
              ))}
          </div>
        </div>
      )}

      {/* range slider */}
      {questionType === '6' && (
        <div>
          <Box className="rangeSlider">
            <Slider
              step={5}
              defaultValue={calculateMinMaxAvgForQuestion(responses, queid).avg}
              valueLabelDisplay="auto"
              min={answer ? Number(JSON.parse(answer)[0]) : 0}
              max={answer ? Number(JSON.parse(answer)[2]) : 100}
              value={calculateMinMaxAvgForQuestion(responses, queid).avg}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {answer &&
                JSON.parse(answer).map((value, index) => (
                  <span
                    key={index}
                    style={{
                      fontSize: '18px',
                    }}
                  >
                    {value}
                  </span>
                ))}
            </Box>
            <table className="tableofShortAns text-muted mt-4">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {responses
                  .filter((response) => response.questionid === queid)
                  .map((response, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{renderLocalTime(response.created_date)}</td>
                      <td>{response.response_text}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Box>
        </div>
      )}

      {/* Date & time */}
      {questionType === '7' && (
        <div>
          <Box className="date-time-container">
            <Slider
              step={10}
              min={calculateMinMaxAvgForDate(responses, queid).min}
              max={calculateMinMaxAvgForDate(responses, queid).max}
              value={
                (calculateMinMaxAvgForDate(responses, queid).min.getTime() +
                  calculateMinMaxAvgForDate(responses, queid).max.getTime()) /
                2
              } // Set the value to the average of min and max
            />
            <Box className="slider-labels" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" onClick={() => setVal(MIN)} sx={{ cursor: 'pointer' }}>
                {new Date(calculateMinMaxAvgForDate(responses, queid).min).toLocaleDateString()} Starting Date
              </Typography>
              <Typography variant="body2" onClick={() => setVal(AVG)} sx={{ cursor: 'pointer' }}>
                {new Date(calculateMinMaxAvgForDate(responses, queid).avg).toLocaleDateString()} avg
              </Typography>
              <Typography variant="body2" onClick={() => setVal(MAX)} sx={{ cursor: 'pointer' }}>
                {new Date(calculateMinMaxAvgForDate(responses, queid).max).toLocaleDateString()} End Date
              </Typography>
            </Box>
            <table className="tableofShortAns text-muted mt-4">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((response, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {/* You can format the date as needed */}
                    <td>{renderLocalTime(response.created_date)}</td>
                    <td>{response.response_text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </div>
      )}

{questionType === '9' && (
  <div>
    <div className="headprogress">
      {/* Gather all unique image URLs from all responses */}
      {Array.from(
        new Set(
          responses
            .filter((response) => response.questionid === queid)
            .flatMap((response) => response.response_text.split(","))
        )
      ).map((imageUrl, idx) => {
        // Count the total number of selections for the question ID
        const totalSelections = responses.filter(
          (response) => response.questionid === queid
        ).length;

        // Count the total occurrences of the current image URL across all response texts
        let totalOccurrences = 0;
        responses.forEach((response) => {
          if (response.questionid === queid) {
            const responseImages = response.response_text.split(",");
            if (responseImages.includes(imageUrl)) {
              totalOccurrences += 1 / responseImages.length;
            }
          }
        });

        // Calculate the percentage for the current image URL based on total occurrences
        const percentage = (totalOccurrences / totalSelections) * 100;

        return (
          <div key={idx} className="progressBarWithImg">
            <img
              className="imgForProgressBar"
              src={imageUrl}
              alt={`Choice preview ${idx}`}
              style={{ width: 100, height: 100 }}
            />
            <div className="progresswithImg">
              <div
                className="progress"
                role="progressbar"
                aria-label="Success example"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ display: "flex" }}
              >
                <div
                  className={`progress-bar ${getColorForSegment(
                    imageUrl
                  )}`}
                  style={{ width: `${percentage}%` }}
                >
                  {percentage.toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  
  </div>
)}

{questionType === '8' && (
              <div>
               <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                       .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          {/* You can format the date as needed */}
                          <td>{renderLocalTime(response.created_date)}</td>
                          <td>{response.response_text}</td>
                        </tr>
                      ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
            
              </div>
            )}

{/* {questionType === '11' && (
              <div>
                <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{response.created_date}</td>
                          <td>
                            {response.response_text &&
                              JSON.parse(response.response_text).map((obj, index) =>
                                Object.entries(obj).map(([key, value]) => <div key={index}>{key}</div>)
                              )}
                          </td>
                          <td>
                            {response.response_text &&
                              JSON.parse(response.response_text).map((obj, index) =>
                                Object.entries(obj).map(([key, value]) => <div key={index}>{value}</div>)
                              )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
               
              </div>
            )} */}


      {questionType === '1' && (
        <div>
          <table className="tableofLongAns text-muted">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  {/* You can format the date as needed */}
                  <td>{renderLocalTime(response.created_date)}</td>
                  <td>{response.response_text}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AllanswerDetail;
