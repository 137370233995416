import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import DashboardLayout from './layouts/dashboard';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/auth/Login';
// import Page404 from './pages/Page404';
import ProductsPage from './pages/SurveyListing';
import DashboardAppPage from './pages/DashboardAppPage';
import Signup from './pages/auth/Signup';
import ForgotPassword from './pages/auth/ForgotPassword';
import ProfilePage from './pages/UserProfile';
import SurveyCreateLayout from './layouts/dashboard/SurveyCreateLayout';
import QuestionDetailslayout from './layouts/dashboard/nav/QuestionDetailslayout';
import AllanswerLayout from './layouts/dashboard/nav/AllanswerLayout';
// import Questionlayout from './layouts/dashboard/Questionlayout';
import Thems from './pages/CreateSurvey_Siderbar/Custum';
import Setting from './pages/CreateSurvey_Siderbar/Setting';
import Preview from './pages/CreateSurvey_Siderbar/Preview';
import Share from './pages/CreateSurvey_Siderbar/Share';
import Questions from './pages/CreateSurvey_Siderbar/Questions';
import Result from './pages/CreateSurvey_Siderbar/Result';
import QuestionBranching from './pages/CreateSurvey_Siderbar/QuestionBranching';
import SuvryeForm1 from './User_Survey_form/SuvryeForm1';
import SurveyForm from './User_Survey_form/SurveyForm';
import ParticipantsDetails from './pages/RsultTabs/ParticipantsDetails';
import AllanswerDetail from './pages/RsultTabs/AllanswerDetail';

function DynamicRedirect(checkuser, navigate) {
  
  // useEffect(() => {
  //   if (!checkuser) {
  //     console.log("User not logged in");
  //     console.log("url", window.location.href);
  //     navigate('/');
  //   } else {
  //     console.log("User logged in");
  //   }
  // }, [checkuser]);
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!checkuser) {
      console.log('User not logged in ');
      // console.log("url", window.location.href);
      if (currentPath === '/signup' || currentPath === '/forgot') {
        // Redirect to /signup or /forgot if user is not logged in and on these pages
        navigate(currentPath);
      } else {
        // Redirect to home page or any other page if user is not logged in
        navigate('/login');
      }
    } else {
      // navigate('/login'); // it is for on reload go to this path

      // so when the userdata is save in localstorage it will work  checkuser = true
      console.log('User logged in');

      // Check if the user is on the login page, and redirect them to the dashboard
      if (currentPath === '/login' || currentPath === '/') {
        navigate('/my-surveys'); // Or your preferred default dashboard page
      }
    }
  }, [checkuser]);
}

export default function Router() {
  const navigate = useNavigate();
  if (!/surveyform/.test(window.location.href)) {
    const checkuser = localStorage.getItem('getuserid');
    DynamicRedirect(checkuser, navigate);
  }

  const routes = useRoutes([
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: 'surveyform/:id',
      // element: <SuvryeForm1 />,
      element: <SurveyForm />,
    },

    {
      path: '/',
      element: <DashboardLayout />,

      children: [
        { element: <Navigate to="/" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'my-surveys', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'profile', element: <ProfilePage /> },
      ],
    },

    // https://app.surveyslack.com/surveycreatelayout/setting/16665 -> https://app.surveyslack.com/survey/16665/setting
    {
      path: '/survey',
      element: <SurveyCreateLayout />,
      children: [
        { element: <Navigate to=":id/settings" /> },
        // { path: '/', element: <SurveyCreateLayout /> },
        { path: ':id/settings', element: <Setting /> },
        { path: ':id/questions', element: <Questions /> },
        { path: ':id/customise', element: <Thems /> },
        { path: ':id/preview', element: <Preview /> },
        { path: ':id/share', element: <Share /> },
        { path: ':id/result', element: <Result /> },
        { path: ':id/branching', element: <QuestionBranching /> },
        // { path: 'blog', element: <BlogPage /> },
        // { path: 'profile', element: <ProfilePage /> },
      ],
    },

    {
      path: '/QuestionDetailslayout/:id',
      element: <QuestionDetailslayout />,
      children: [
        // { element: <Navigate to="/surveycreatelayout/questions" />, index: true },
        // { path: 'surveycreatelayout', element: <SurveyCreateLayout /> },
        // { path: 'setting/:id', element: <Setting /> },
        // { path: 'questions/:id', element: <Questions /> },
        // { path: 'thems/:id', element: <Thems /> },
        // { path: 'preview/:id', element: <Preview /> },
        // { path: 'share/:id', element: <Share /> },
        // { path: 'result/:id', element: <Result /> },
        // { path: 'blog', element: <BlogPage /> },
        { path: '', element: <ParticipantsDetails /> },
      ],
    },
    {
      path: '/AllanswerLayout/:id',
      element: <AllanswerLayout />,
      children: [
        // { element: <Navigate to="/surveycreatelayout/questions" />, index: true },
        // { path: 'surveycreatelayout', element: <SurveyCreateLayout /> },
        // { path: 'setting/:id', element: <Setting /> },
        // { path: 'questions/:id', element: <Questions /> },
        // { path: 'thems/:id', element: <Thems /> },
        // { path: 'preview/:id', element: <Preview /> },
        // { path: 'share/:id', element: <Share /> },
        // { path: 'result/:id', element: <Result /> },
        // { path: 'blog', element: <BlogPage /> },
        { path: '', element: <AllanswerDetail /> },
      ],
    },

    // {
    //   path: '/Questions',
    //   element: <Questionlayout />,
    //   children: [
    //     { element: <Navigate to="/Questions" />, index: true },
    //     { path: '', element: <Questionlayout /> },
    //   ],
    // },
    // {
    //   path: '/Settings',
    //   element: <Questionlayout />,
    //   children: [
    //     { element: <Navigate to="/Questions" />, index: true },
    //     { path: '', element: <Questionlayout /> },
    //   ],
    // },

    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/forgot',
      element: <ForgotPassword />,
    },
    // {
    // path: '*',
    // element: <Navigate to="/404" replace />,
    // }
    // {
    //   path: '/first',
    //   element: <ForgotPassword />,
    // },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return routes;
}
