import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { json, useParams } from 'react-router';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { NavLink } from 'react-router-dom';


import ReactApexChart from 'react-apexcharts';
import { API_ENDPOINTS, BASE_URL } from '../../api_folder/config';
import '../../Style/AnswerAccordian.css';
import preview from '../../img/preview.jpg';

// Define the getColorForSegment function
const getColorForSegment = (() => {
  // Map to track assigned colors for each percentage
  const assignedColorsMap = {};

  return () => {
    // Define the available colors
    const availableColors = [
      '#D7C0AE',
      '#FFEBB4',
      '#F3CCFF',
      '#C8FFD4',
      '#a9c0cb',
      '#dba39a',
      '#CDFADB',
      '#F5EEE6',
      '#EFBC9B',
      '#FFFDCB',
      '#BED1CF',
      '#80BCBD',
      '#E5D4FF',
      '#89CFF3',
    ];

    // Shuffle the available colors array randomly
    let i = availableColors.length - 1;
    while (i > 0) {
      const j = Math.floor(Math.random() * (i + 1));
      [availableColors[i], availableColors[j]] = [availableColors[j], availableColors[i]];
      // Decrement i inside the loop body using -= operator
      i -= 1;
    }

    // Get the first color from the shuffled array
    const selectedColor = availableColors[0];

    // If the color has been assigned to any percentage, remove it from available colors
    if (assignedColorsMap[selectedColor]) {
      availableColors.shift();
      return availableColors[0];
    }

    // Return the selected color
    return selectedColor;
  };
})();

const ResultQuestions = ({ surveyId, question }) => {
  const [responses, setResponses] = useState([]);
  const [queslist, setQueslist] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [showAllAnswers, setShowAllAnswers] = useState(false);
  const [chartData, setChartData] = useState({});

  // const [chartData, setChartData] = useState({ series: [], options: {} });

  function PieChartComponent({ data }) {
    return (
      <PieChart
        series={[
          {
            data,
          },
        ]}
        height={300}
        slotProps={{
          legend: { hidden: true },
        }}
      />
    );
  }

// matrix radio
const handelSetMatrixmcq = (rowId, rowValues, columnValues, columnIndex, questionId, questionNumber, type) => {
  // ...

  if (type === 'columnValue') {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[questionNumber] = {
      rowId,
      columnIndex,
      columnValue: columnValues[columnIndex].columnvalue,
    };
    setSelectedValues(newSelectedValues);
  }
};


  // rating
  const calculatePercentageForQuestion = (responses, questionId, answer) => {
    const filteredResponses = responses.filter((response) => response.questionid === questionId);
    // Parse the answer JSON to get the edited responses
    const editedResponses = JSON.parse(answer)[3].map((data) => data);
    const editedResponses1 = filteredResponses.map((response) => response.response_text);
    const isEdit = editedResponses.includes(editedResponses1);
    // console.log('edited', isEdit);
    // console.log('editedResponses', editedResponses);
    // console.log('editedResponses1', editedResponses1);
    // Filter responses based on the question ID

    // Calculate counts for each response text
    const counts = {};
    filteredResponses.forEach((response) => {
      counts[response.response_text] = (counts[response.response_text] || 0) + 1;
    });

    // Calculate percentage for each response text and determine if it's edited
    const percentages = {};
    Object.entries(counts).forEach(([responseText, count]) => {
      const isEdited = editedResponses.includes(responseText); // Check if the response is edited

      const percentage = (count / filteredResponses.length) * 100;
      percentages[responseText] = { percentage, isEdited };
    });

    return percentages;
  };

  // multiple choice
  // const calculatePercentageForQuestionMultiple = (responses, questionId, answer) => {
  //   const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);
  
  //   // Parse the answer JSON to get the edited responses
  //   const editedResponsesNew = JSON.parse(answer).map((data) => data);
  
  //   // Calculate counts for each response value obtained from JSON parse
  //   const countsNew = {};
  //   let totalCount = 0; // Initialize total count for responses
  
  //   filteredResponsesNew.forEach((response) => {
  //     JSON.parse(response.response_text)
  //       .filter((value) => value && value.trim() !== '')
  //       .forEach((value) => {
  //         // Increment the count for each response value
  //         countsNew[value] = (countsNew[value] || 0) + 1;
  //         totalCount+=1; // Increment total count
  //       });
  //   });
 
    
  //   const getProgressPercentage = (responseText) => {
  //     // Implement your logic to calculate the progress percentage based on the response text
  //     // Example logic:
  //     return responseText.length * 10; // Just a placeholder, replace with your actual logic
  //   };
    

  //   // Calculate percentage for each value
  //   const percentagesNew = {};
  //   Object.entries(countsNew).forEach(([responseValue, count]) => {
  //     const isEditedNew = editedResponsesNew.includes(responseValue); // Check if the response is edited
  
  //     // Calculate percentage for each value based on total count of selected values
  //     const percentageNew = (count / totalCount) * 100;
  
  //     // Ensure percentage is limited to 100%
  //     percentagesNew[responseValue] = { percentageNew: Math.min(percentageNew, 100), isEditedNew };
  //   });
  
  //   return { percentagesNew };
  // };
  const calculatePercentageForQuestionMultiple = (responses, questionId, answer) => {
    const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);
  
    // Parse the answer JSON to get the edited responses
    const editedResponsesNew = JSON.parse(answer).map((data) => data);
  
    // Calculate counts for each response value obtained from JSON parse
    const countsNew = {};
    let totalCount = 0; // Initialize total count for responses
  
    filteredResponsesNew.forEach((response) => {
      try {
        JSON.parse(response.response_text)
          .filter((value) => value && value.trim() !== '')
          .forEach((value) => {
            // Increment the count for each response value
            countsNew[value] = (countsNew[value] || 0) + 1;
            totalCount += 1; // Increment total count
          });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    });
    
  
    // Calculate percentage for each value
    const percentagesNew = {};
    Object.entries(countsNew).forEach(([responseValue, count]) => {
      // Check if the response value is edited
      const isEditedNew = editedResponsesNew.includes(responseValue);
  
      // Calculate percentage for each value based on total count of selected values
      const percentageNew = (count / totalCount) * 100;
  
      // Ensure percentage is limited to 100%
      percentagesNew[responseValue] = { percentageNew: Math.min(percentageNew, 100), isEditedNew };
    });
  
    return { percentagesNew };
  };
  
  const calculatePercentageForQuestionNew = (responses, questionId, answer) => {
    const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);

    // Parse the answer JSON to get the edited responses
    const editedResponsesNew = JSON.parse(answer).map((data) => data);
    const editedResponses1New = filteredResponsesNew.map((response) => response.response_text);
    const isEditNew = editedResponsesNew.includes(editedResponses1New);
    // console.log('edited', isEditNew);
    // console.log('editedResponses', editedResponsesNew);
    // console.log('editedResponses1', editedResponses1New);

    // Calculate counts for each response text
    const countsNew = {};
    filteredResponsesNew.forEach((response) => {
      countsNew[response.response_text] = (countsNew[response.response_text] || 0) + 1;
    });

    // Calculate percentage for each response text and determine if it's edited
    const percentagesNew = {};
    Object.entries(countsNew).forEach(([responseText, count]) => {
      const isEditedNew = editedResponsesNew.includes(responseText); // Check if the response is edited

      const percentageNew = (count / filteredResponsesNew.length) * 100;
      percentagesNew[responseText] = { percentageNew, isEditedNew };
    });

    return percentagesNew;
  };

  // linear scale
  // Find the range where isEditedNew goes from true to true
  let startRange = null;
  let endRange = null;
  const calculatePercentageForLinearScale = (responses, questionId, answer) => {
    const filteredResponsesNew = responses.filter((response) => response.questionid === questionId);

    // Parse the answer JSON to get the edited responses
    const editedResponsesNew = JSON.parse(answer).map((data) => String(data));
    const editedResponses1New = filteredResponsesNew.map((response) => response.response_text);
    const isEditNewlinear = editedResponsesNew.map((response, index) => response[index] === editedResponsesNew[index]);



    // Calculate counts for each response text
    const countsNew = {};
    filteredResponsesNew.forEach((response) => {
      countsNew[response.response_text] = (countsNew[response.response_text] || 0) + 1;
    });

    // Calculate percentage for each response text and determine if it's edited
    const percentagesNew = {};
    Object.entries(countsNew).forEach(([responseText, count]) => {
      const isEditedNew = editedResponsesNew.includes(responseText); // Check if the response is edited

      const percentageNew = (count / filteredResponsesNew.length) * 100;
      percentagesNew[responseText] = { percentageNew, isEditedNew };
    });

    // Log percentages
    console.log('percentagesNew:', percentagesNew);

    Object.entries(percentagesNew).forEach(([index, { isEditedNew }]) => {
      if (isEditedNew && startRange === null) {
        startRange = parseInt(index, 10); // Set startRange when isEditedNew becomes true for the first time
      } else if (isEditedNew && startRange !== null) {
        endRange = parseInt(index, 10); // Set endRange when isEditedNew becomes true for the second time
      }
    });

    // Log the range
    // console.log('Start Range:', startRange);
    // console.log('End Range:', endRange);

    return percentagesNew;
  };

  const [array, setArray] = useState([20, 10, 50, 25]);
  const subValues = [5, 25];
  const MAX = 100;
  const AVG = 50;
  const MIN = 0;
  const [val, setVal] = React.useState(MIN);
  const handleChange = (_, newValue) => {
    setVal(newValue);
  };

  const marks = [
    {
      value: MIN,
      label: '',
    },
    {
      value: AVG,
      label: '',
    },
    {
      value: MAX,
      label: '',
    },
  ];
  const calculatePercentage = (responses, questionId) => {
    // Group responses by image source
    const groupedResponses = {};
    responses.filter(response => response.questionid === questionId).forEach(response => {
      if (!groupedResponses[response.response_text]) {
        groupedResponses[response.response_text] = [];
      }
      groupedResponses[response.response_text].push(response);
    });
  
    // Calculate percentage for each unique image source
    const percentages = {};
    Object.keys(groupedResponses).forEach(imageSrc => {
      const selectedResponses = groupedResponses[imageSrc];
      const percentage = (selectedResponses.length / responses.length) * 100;
      percentages[imageSrc] = percentage.toFixed(2);
    });
  
    return percentages;
  };
  
  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  const amtData = [2400, 2210, 2290, 2000, 2181, 2500, 2100];

  const xLabels = ['A', ' B', ' C', ' D', ' E', ' F', ' G'];
  const { id } = useParams();
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const endpoint = API_ENDPOINTS.questionListGET;
        const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${surveyId}`);
        if (response.ok) {
          const data = await response.json();
          setQueslist(data.data);
        } else {
          console.error(`Failed to fetch questions. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while fetching questions:', error);
      }
    };

    fetchQuestions();
  }, [surveyId]);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        console.log('Fetching getsurveyresponse for surveyId:', id);
        const endpoint = API_ENDPOINTS.getsurveyresponse;
        const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, { cache: 'no-cache' });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('Raw response data:', responseData);
  
          if (responseData.data && Array.isArray(responseData.data)) {
            console.log('ID:', id);
            const surveyData = responseData.data.filter((entry) => {
              console.log('Entry surveyid:', entry.surveyid);
              return String(entry.surveyid).trim() === String(id).trim();
            });
  
            console.log('Filtered survey data:', surveyData);
            setResponses(surveyData);
          } else {
            console.error('Fetched data is not in the expected format:', responseData);
          }
        } else {
          console.error(`Failed to fetch questions. Status: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while fetching questions:', error);
      }
    };
  
    if (id) {
      fetchAnswers();
    } else {
      console.error('Survey ID is not defined');
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  
  
  // useEffect(() => {
  //   const fetchAnswers = async () => {
  //     try {
  //       console.log('Fetching getsurveyresponse for surveyId:', id);
  //       const endpoint = API_ENDPOINTS.getsurveyresponse;
  //       const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`);
  //       if (response.ok) {
  //         const responseData = await response.json();
  //         if (responseData.data && Array.isArray(responseData.data)) {
  //           const surveyData = responseData.data.filter((entry) => entry.surveyid === id);
  //           setResponses(surveyData);
  //           console.log(surveyData);
  //         } else {
  //           console.error('Fetched data is not in the expected format:', responseData);
  //         }
  //       } else {
  //         console.error(`Failed to fetch questions. Status: ${response.status}`);
  //       }
  //     } catch (error) {
  //       console.error('An error occurred while fetching questions:', error);
  //     }
  //   };

  //   fetchAnswers();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);
 
 
  const calculatePercentageForResponseText = (responses, questionId, imageUrl) => {
    // Filter responses for the specified question ID
    const filteredResponses = responses.filter((response) => response.questionid === questionId);
    
    // Calculate the total number of responses for the specified question ID
    const totalResponses = filteredResponses.length;

    // Count the total occurrences of the image URL across all response texts
    const totalOccurrences = filteredResponses.reduce((count, response) => {
        // Split the response text by commas to extract individual image URLs
        const responseImages = response.response_text.split(",");
        // Count occurrences of the current image URL in the response text
        return count + responseImages.filter((img) => img === imageUrl).length;
    }, 0);

    // Calculate the percentage based on the total occurrences and total responses
    const percentage = (totalOccurrences / totalResponses) * 100;

    // Round the percentage to two decimal places
    const roundedPercentage = parseFloat(percentage.toFixed(2));

    return roundedPercentage;
};

const renderLocalTime = (dateTimeString) => {
  const date = new Date(dateTimeString); // Parse string into Date object
  const utcTime = date.getTime(); // Get UTC time in milliseconds
  const offset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds for UTC +5:30
  const localTime = new Date(utcTime + offset); // Adjust to local time zone
  const year = localTime.getFullYear();
  const month = String(localTime.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
  const day = String(localTime.getDate()).padStart(2, '0'); // Add leading zero if needed
  const hours = String(localTime.getHours()).padStart(2, '0'); // Add leading zero if needed
  const minutes = String(localTime.getMinutes()).padStart(2, '0'); // Add leading zero if needed
  const seconds = String(localTime.getSeconds()).padStart(2, '0'); // Add leading zero if needed
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};




  const calculateMinMaxAvgForQuestion = (responses, questionId) => {
    const filteredResponses = responses.filter((response) => response.questionid === questionId);
    const values = filteredResponses.map((response) => parseInt(response.response_text, 10)); // Specify radix 10
    const min = Math.min(...values);
    const max = Math.max(...values);
    const avg = values.reduce((acc, curr) => acc + curr, 0) / values.length;
    return { min, max, avg };
    
  };

  const calculateMinMaxAvgForDate = (responses, questionId) => {
    const filteredResponses = responses.filter((response) => response.questionid === questionId);
    const dates = filteredResponses.map((response) => new Date(response.response_text));
    const minDate = new Date(Math.min(...dates.map((date) => date.getTime())));
    const maxDate = new Date(Math.max(...dates.map((date) => date.getTime())));
    const avgTimestamp = dates.reduce((acc, curr) => acc + curr.getTime(), 0) / dates.length;
    const avgDate = new Date(avgTimestamp);
    return { min: minDate, max: maxDate, avg: avgDate };
  };

  const calculatePercentageForAnswer = (responseCount, totalCount) => {
    return (responseCount / totalCount) * 100;
  };
  const surveyidforallanswer = localStorage.getItem('SURVEY_ID');
  const filteredQuestionList = queslist.filter((survey) => survey.surveyid === id);

  return (
    <div>
      {filteredQuestionList.map((question, index) => (
        <Accordion key={question.questionid} defaultExpanded={index === 0}>
          <AccordionSummary className="custom-accordion-summary">
            <Typography>{`${index + 1}. ${question.question_text}`}</Typography>
            <div
              style={{
                color: '#b7b7b7',
                marginInlineStart: 'auto',
              }}
            >
              <>
                {question.question_type === 12 ? 'Multiple Choice' : null}
                {question.question_type === 1 ? 'Long Answers' : null}
                {question.question_type === 2 ? 'Short Answers  ' : null}
                {question.question_type === 3 ? 'Rating' : null}
                {question.question_type === 4 ? 'DropDown' : null}
                {question.question_type === 5 ? 'Linear-scale' : null}
                {question.question_type === 6 ? 'Range Slider' : null}
                {question.question_type === 7 ? 'Date & Time ' : null}
                {question.question_type === 8 ? 'File Upload' : null}
                {question.question_type === 9 ? 'Image-choice' : null}
                {question.question_type === 10 ? 'Matrix-MCQ' : null}
                {question.question_type === 11 ? 'Matrix-Checkbox' : null}
              </>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0px' }}>
            {/* long answer */}
            {question.question_type === 1 && (
              <div>
                <table className="tableofLongAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .filter((response) => response.questionid === question.questionid)
                      .slice(0, showAllAnswers ? undefined : 10) // Show only first 10 answers if showAllAnswers is false
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          {/* You can format the date as needed */}
                          <td>{renderLocalTime(response.created_date)}</td>
                          <td>{response.response_text}</td>
                        </tr>
                      ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}
            {/* 
<NavLink className="icon-link" to={`/QuestionDetailslayout/${responderdata.responderid}`}>
                      <FontAwesomeIcon icon={faEye} />
                    </NavLink> */}
            {/* Short answer */}
            {/* {question.question_type === '2' && (
              <div>
                <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .filter((response) => response.questionid === question.questionid)
                      .slice(0, showAllAnswers ? undefined : 10) 
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{renderLocalTime(response.created_date)}</td>
                          <td>{response.response_text}</td>
                        </tr>
                      ))}
                
                  </tbody>
                </table>
                {!showAllAnswers && ( 
                  <footer className="clearfix">
                    <nav className="result-item-nav">
                      <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
                        See all answers <span className="icon icon-right" />
                      </NavLink>
                    </nav>
                  </footer>
                )}
              </div>
            )} */}

{question.question_type === 2 && (
  <div>
    <table className="tableofShortAns text-muted">
      <thead>
        <tr>
          <th>No.</th>
          <th>Date</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        {responses
          .filter((response) => response.questionid === question.questionid)
          .slice(0, showAllAnswers ? undefined : 10)
          .map((response, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              {/* You can format the date as needed */}
              <td>{renderLocalTime(response.created_date)}</td>
              <td>{response.response_text}</td>
            </tr>
          ))}
        {/* Add more rows as needed */}
      </tbody>
    </table>
    {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      // Show "See all answers" button only if not already showing all answers and more than 10 responses
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
  </div>
)}



            {/* for rating */}
            {question.question_type === 3 && (
              <div>
                <div style={{ padding: '30px' }}>
                  <div className="progress d-flex justify-content-around">
                    {Object.entries(calculatePercentageForQuestion(responses, question.questionid, question.answer))
                      .slice(0, showAllAnswers ? undefined : 10)
                      .map(([responseText, { percentage, isEdited, isEdit }]) => {
                        return (
                          <div
                            key={responseText}
                            className={`progress-bar ${isEdited ? 'edited' : ''}`}
                            role="progressbar"
                            style={{
                              width: `${percentage}%`,
                              backgroundColor: getColorForSegment(percentage),
                            }}
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {isEdited ? 'Deleted':  `${responseText} - ${percentage.toFixed(2)}%` }
                          </div>
                        );
                      })}
                  </div>
                  <table className="tableofShortAns text-muted">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {responses
                        .filter((response) => response.questionid === question.questionid)
                        .slice(0, showAllAnswers ? undefined : 10)
                        .map((response, idx) => {
                          const { isEdited } = calculatePercentageForQuestion(
                            responses,
                            question.questionid,
                            question.answer
                          )[response.response_text];
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>{renderLocalTime(response.created_date)}</td>
                              <td>{isEdited ?  'Deleted':  response.response_text }</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}

            {/* Dropdown */}
            {question.question_type === 4 && (
              <div>
                <div className="headprogress">
                  {/* Render progress bars */}
                  {Object.entries(calculatePercentageForQuestionNew(responses, question.questionid, question.answer))
                    .slice(0, showAllAnswers ? undefined : 10)
                    .map(([responseText, { percentageNew, isEditedNew }]) => (
                      <div
                        key={responseText}
                        className="progress"
                        role="progressbar"
                        aria-label={`${responseText} example`}
                      >
                        <div
                          className={`progress-bar ${isEditedNew ? 'edited' : ''}`}
                          role="progressbar"
                          style={{
                            width: `${percentageNew}%`,
                            backgroundColor: getColorForSegment(percentageNew),
                          }}
                          aria-valuenow={percentageNew}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {isEditedNew
                            ? `${responseText} - ${percentageNew.toFixed(2)}%`
                            : `Deleted - ${percentageNew.toFixed(2)}%`}
                        </div>
                      </div>
                    ))}
                </div>
                <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .filter((response) => response.questionid === question.questionid)
                      .slice(0, showAllAnswers ? undefined : 10) // Show only first 10 answers if showAllAnswers is false
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          {/* You can format the date as needed */}
                          <td>{renderLocalTime(response.created_date)}</td>
                          <td>{response.response_text}</td>
                        </tr>
                      ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}

            {/* linear scale */}
            {question.question_type === 5 && (
              <div>
                <div className="headprogress">           
                  {Object.entries(calculatePercentageForLinearScale(responses, question.questionid, question.answer))
                    .slice(0, showAllAnswers ? undefined : 10)
                    .reduce((chunks, [responseText, { percentageNew, isEditNewlinear }], index) => {
                      const chunkIndex = Math.floor(index / 2); 
                      if (!chunks[chunkIndex]) {
                        chunks[chunkIndex] = [];
                      }
                      const label =
                        index + 1 >= startRange && index + 1 <= endRange
                          ? ` Deleted - ${percentageNew.toFixed(2)}%`
                          : `${responseText}- ${percentageNew.toFixed(2)}%`;
                      chunks[chunkIndex].push(
                        <div
                          key={responseText}
                          className="progress"
                          role="progressbar"
                          aria-label={`${responseText} example`}
                          style={{ flex: 1, marginRight: '10px' }}
                        >
                          <div
                            className={`progress-bar ${isEditNewlinear ? 'edited' : ''}`}
                            role="progressbar"
                            style={{
                              width: `${percentageNew}%`,
                              backgroundColor: getColorForSegment(percentageNew),
                            }}
                            aria-valuenow={percentageNew}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {label}
                          </div>
                        </div>
                      );
                      return chunks;
                    }, [])
                    .map((chunk, chunkIndex) => (
                      <div key={chunkIndex} className="progress-row">
                        {chunk}
                      </div>
                    ))}
                </div>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}

            {/* file upload */}
            {question.question_type === 8 && (
              <div>
               <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .filter((response) => response.questionid === question.questionid)
                      .slice(0, showAllAnswers ? undefined : 10) // Show only first 10 answers if showAllAnswers is false
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          {/* You can format the date as needed */}
                          <td>{renderLocalTime(response.created_date)}</td>
                          <td>{response.response_text}</td>
                        </tr>
                      ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}
            {/* image choice */}
            {/* {question.question_type === '9' && (
              <div>
                <div className="headprogress">
                  <div className="progressBarWithImg">
                    <img className="imgForProgressBar" src={preview} alt="Choice preview" />
                    <div className="progresswithImg">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Success example"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ display: 'flex' }}
                      >
                        <div className="progress-bar bg-info" style={{ width: '20%' }}>
                          20%
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="progressBarWithImg">
                    <img className="imgForProgressBar" src={preview} alt="Choice preview" />
                    <div className="progresswithImg">
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Success example"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ display: 'flex' }}
                      >
                        <div className="progress-bar bg-warning" style={{ width: '50%' }}>
                          50%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="clearfix">
                  <nav className="result-item-nav">
                    <a href="#" className="result-details-button">
                      See all answers <span className="icon icon-right" />
                    </a>
                  </nav>
                </footer>
              </div>
            )} */}
{question.question_type === 9 && (
  <div>
    <div className="headprogress">
      {/* Gather all unique image URLs from all responses */}
      {Array.from(
        new Set(
          responses
            .filter((response) => response.questionid === question.questionid)
            .flatMap((response) => response.response_text.split(","))
        )
      ).map((imageUrl, idx) => {
        // Count the total number of selections for the question ID
        const totalSelections = responses.filter(
          (response) => response.questionid === question.questionid
        ).length;

        // Count the total occurrences of the current image URL across all response texts
        let totalOccurrences = 0;
        responses.forEach((response) => {
          if (response.questionid === question.questionid) {
            const responseImages = response.response_text.split(",");
            if (responseImages.includes(imageUrl)) {
              totalOccurrences += 1 / responseImages.length;
            }
          }
        });

        // Calculate the percentage for the current image URL based on total occurrences
        const percentage = (totalOccurrences / totalSelections) * 100;

        return (
          <div key={idx} className="progressBarWithImg">
            <img
              className="imgForProgressBar"
              src={imageUrl}
              alt={`Choice preview ${idx}`}
              style={{ width: 100, height: 100 }}
            />
            <div className="progresswithImg">
              <div
                className="progress"
                role="progressbar"
                aria-label="Success example"
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ display: "flex" }}
              >
                <div
                  className={`progress-bar ${getColorForSegment(
                    imageUrl
                  )}`}
                  style={{ width: `${percentage}%`,backgroundColor: getColorForSegment(percentage) }}
                >
                  {percentage.toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
    {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
  </div>
)}




            {/* range slider */}
            {question.question_type === 6 && (
              <div>
                <Box className="rangeSlider">
                  <Slider
                    step={5}
                    defaultValue={calculateMinMaxAvgForQuestion(responses, question.questionid).avg}
                    valueLabelDisplay="auto"
                    min={Number(JSON.parse(question.answer)[0])}
                    max={Number(JSON.parse(question.answer)[2])}
                    value={calculateMinMaxAvgForQuestion(responses, question.questionid).avg}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {JSON.parse(question.answer).map((value, index) => (
                      <span
                        key={index}
                        style={{
                          // color: contentStyle?.[0]?.[1],
                          fontSize: '32pxpx',
                        }}
                      >
                        {value}
                      </span>
                    ))}
                  </Box>
                  <table className="tableofShortAns text-muted mt-4">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {responses
                        .filter((response) => response.questionid === question.questionid)
                        .slice(0, showAllAnswers ? undefined : 10) // Show only first 10 answers if showAllAnswers is false
                        .map((response, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            {/* You can format the date as needed */}
                            <td>{renderLocalTime(response.created_date)}</td>
                            <td>{response.response_text}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}

            {/* Date & time */}
            {question.question_type === 7 && (
              <div>
                <Box className="date-time-container">
                  <Slider
                    step={10}
                    min={calculateMinMaxAvgForDate(responses, question.questionid).min}
                    max={calculateMinMaxAvgForDate(responses, question.questionid).max}
                    value={
                      (calculateMinMaxAvgForDate(responses, question.questionid).min.getTime() +
                        calculateMinMaxAvgForDate(responses, question.questionid).max.getTime()) /
                      2
                    } // Set the value to the average of min and max
                  />
                  <Box className="slider-labels" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" onClick={() => setVal(MIN)} sx={{ cursor: 'pointer' }}>
                      {new Date(calculateMinMaxAvgForDate(responses, question.questionid).min).toLocaleDateString()}{' '}
                      Starting Date
                    </Typography>
                    <Typography variant="body2" onClick={() => setVal(AVG)} sx={{ cursor: 'pointer' }}>
                      {new Date(calculateMinMaxAvgForDate(responses, question.questionid).avg).toLocaleDateString()} avg
                    </Typography>
                    <Typography variant="body2" onClick={() => setVal(MAX)} sx={{ cursor: 'pointer' }}>
                      {new Date(calculateMinMaxAvgForDate(responses, question.questionid).max).toLocaleDateString()} End
                      Date
                    </Typography>
                  </Box>
                  <table className="tableofShortAns text-muted mt-4">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {responses
                        .filter((response) => response.questionid === question.questionid)
                        .slice(0, showAllAnswers ? undefined : 10) // Show only first 10 answers if showAllAnswers is false
                        .map((response, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            {/* You can format the date as needed */}
                            <td>{renderLocalTime(response.created_date)}</td>
                            <td>{response.response_text}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )}

            {/* matrix mcq */}

            {/* {question.question_type === '10' && (
              <div>
           
                <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Row value</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .filter((response) => response.questionid === question.questionid)
                      .slice(0, showAllAnswers ? undefined : 10)
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{renderLocalTime(response.created_date)}</td>
                    
                          <td>
                            {response.response_text &&
                              JSON.parse(response.response_text).map((obj, index) =>
                                Object.entries(obj).map(([key, value]) => <div key={index}>{key}</div>)
                              )}
                          </td>
                          <td>
                            {response.response_text &&
                              JSON.parse(response.response_text).map((obj, index) =>
                                Object.entries(obj).map(([key, value]) => <div key={index}>{value}</div>)
                              )}
                          </td>

                          
                        </tr>
                      ))}
                  </tbody>
                </table>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
     <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )} */}
{/* 
*/}
{/* {question.question_type === '10' && (
  <div>
    <table className="tableofShortAns text-muted">
      <thead>
        <tr>
          <th>Row Value</th>
          {JSON.parse(question.answer)[1].map((input, index) => (
            <th key={input.id}>{input.columnvalue}</th>
          ))}
          {JSON.parse(question.answer)[2] === true && <th>N/A</th>}
        </tr>
      </thead>
      <tbody>
        {(() => {
          // Aggregate all responses for this question
          const aggregatedResponses = {};

          responses.forEach((response) => {
            if (response.questionid === question.questionid) {
              const rowValues = JSON.parse(response.response_text);

              rowValues.forEach((rowValue) => {
                Object.entries(rowValue).forEach(([key, value]) => {
                  if (!aggregatedResponses[key]) {
                    aggregatedResponses[key] = {};
                  }
                  if (!aggregatedResponses[key][value]) {
                    aggregatedResponses[key][value] = 0;
                  }
                  aggregatedResponses[key][value] += 1;
                });
              });
            }
          });

          // Calculate total number of responses for this question
          const totalResponses = responses.filter((res) => res.questionid === question.questionid).length;

          return Object.entries(aggregatedResponses).map(([rowKey, rowValues], idx) => {
            const totalSelectedValuesForRow = Object.values(rowValues).reduce((acc, val) => acc + val, 0);

            return (
              <tr key={idx}>
                <td>{rowKey}</td>
                {JSON.parse(question.answer)[1].map((input, columnIndex) => {
                  const columnValue = input.columnvalue;
                  const selectedColumnCount = rowValues[columnValue] || 0;
                  const percentage = totalSelectedValuesForRow === 0 ? 0 : (selectedColumnCount / totalSelectedValuesForRow) * 100;

                  return (
                    <td key={columnIndex} style={{ backgroundColor: selectedColumnCount ? '#dff0d8' : 'white' }}>
                      {percentage.toFixed(0)}%
                    </td>
                  );
                })}
                {JSON.parse(question.answer)[2] === true && <td style={{ backgroundColor: 'white' }}>0%</td>}
              </tr>
            );
          });
        })()}
      </tbody>
    </table>
    {responses.filter((response) => response.questionid === question.questionid).length > 10 &&
      !showAllAnswers && (
        // Show "See all answers" button only if not already showing all answers and more than 10 responses
        <footer className="clearfix">
          <nav className="result-item-nav">
            <NavLink
              className="icon-link result-details-button"
              to={`/AllanswerLayout/${question.questionid}`}
            >
              See all answers <span className="icon icon-right" />
            </NavLink>
          </nav>
        </footer>
      )}
  </div>
)} */}


{question.question_type === 10 && (
  <div>
    <table className="tableofShortAns text-muted">
      <thead>
        <tr>
          <th>Row Value</th>
          {JSON.parse(question.answer)[1].map((input, index) => (
            <th key={input.id}>{input.columnvalue}</th>
          ))}
          {JSON.parse(question.answer)[2] === true && <th>N/A</th>}
        </tr>
      </thead>
      <tbody>
        {(() => {
          const aggregatedResponses = {};

          responses.forEach((response) => {
            if (response.questionid === question.questionid) {
              const rowValues = JSON.parse(response.response_text);

              rowValues.forEach((rowValue) => {
                Object.entries(rowValue).forEach(([key, value]) => {
                  if (!aggregatedResponses[key]) {
                    aggregatedResponses[key] = {};
                  }
                  if (!aggregatedResponses[key][value]) {
                    aggregatedResponses[key][value] = 0;
                  }
                  aggregatedResponses[key][value] += 1; // Increment count
                });
              });
            }
          });

          // Calculate total number of responses for this question
          const totalResponses = responses.filter((res) => res.questionid === question.questionid).length;

          return Object.entries(aggregatedResponses).map(([rowKey, rowValues], idx) => {
            const totalSelectedValuesForRow = Object.values(rowValues).reduce((acc, val) => acc + val, 0);

            return (
              <tr key={idx}>
                <td>{rowKey}</td>
                {JSON.parse(question.answer)[1].map((input, columnIndex) => {
                  const columnValue = input.columnvalue;
                  const selectedColumnCount = rowValues[columnValue] || 0;
                  const percentage = totalSelectedValuesForRow === 0 ? 0 : (selectedColumnCount / totalSelectedValuesForRow) * 100;

                  return (
                    <React.Fragment key={columnIndex}>
                      <td >
                        {selectedColumnCount} - {percentage.toFixed(0)}%
                      </td>
                    </React.Fragment>
                  );
                })}
                {JSON.parse(question.answer)[2] === true && <td >0%</td>}
              </tr>
            );
          });
        })()}
      </tbody>
    </table>
    {responses.filter((response) => response.questionid === question.questionid).length > 10 &&
      !showAllAnswers && (
        // Show "See all answers" button only if not already showing all answers and more than 10 responses
        <footer className="clearfix">
          <nav className="result-item-nav">
            <NavLink
              className="icon-link result-details-button"
              to={`/AllanswerLayout/${question.questionid}`}
            >
              See all answers <span className="icon icon-right" />
            </NavLink>
          </nav>
        </footer>
      )}
  </div>
)}


            {/* matrix checkbox  */}
            {/* {question.question_type === '11' && (
              <div>
                <table className="tableofShortAns text-muted">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses
                      .filter((response) => response.questionid === question.questionid)
                      .slice(0, showAllAnswers ? undefined : 10)
                      .map((response, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{renderLocalTime(response.created_date)}</td>
                   
                          <td>
                            {response.response_text &&
                              JSON.parse(response.response_text).map((obj, index) =>
                                Object.entries(obj).map(([key, value]) => <div key={index}>{key}</div>)
                              )}
                          </td>
                          <td>
                            {response.response_text &&
                              JSON.parse(response.response_text).map((obj, index) =>
                                Object.entries(obj).map(([key, value]) => <div key={index}>{value}</div>)
                              )}
                          </td>

                      
                        </tr>
                      ))}
                  </tbody>
                </table>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
              </div>
            )} */}

{question.question_type === 11 && (
  <div>
 <table className="tableofShortAns text-muted">
      <thead>
        <tr>
          <th>Row Value</th>
          {JSON.parse(question.answer)[1].map((input, index) => (
            <th key={input.id}>{input.checkboxcolumn}</th>
          ))}
          {JSON.parse(question.answer)[2] === true && <th>N/A</th>}
        </tr>
      </thead>
      <tbody>
        {(() => {
          const aggregatedResponses = {};

          responses.forEach((response) => {
            if (response.questionid === question.questionid) {
              const rowValues = JSON.parse(response.response_text);

              rowValues.forEach((rowValue) => {
                Object.entries(rowValue).forEach(([key, value]) => {
                  if (!aggregatedResponses[key]) {
                    aggregatedResponses[key] = {};
                  }
                  if (!aggregatedResponses[key][value]) {
                    aggregatedResponses[key][value] = 0;
                  }
                  aggregatedResponses[key][value] += 1; // Increment count
                });
              });
            }
          });

          // Calculate total number of responses for this question
          const totalResponses = responses.filter((res) => res.questionid === question.questionid).length;

          return Object.entries(aggregatedResponses).map(([rowKey, rowValues], idx) => {
            const totalSelectedValuesForRow = Object.values(rowValues).reduce((acc, val) => acc + val, 0);

            return (
              <tr key={idx}>
                <td>{rowKey}</td>
                {JSON.parse(question.answer)[1].map((input, columnIndex) => {
                  const columnValue = input.checkboxcolumn;
                  const selectedColumnCount = rowValues[columnValue] || 0;
                  const percentage = totalSelectedValuesForRow === 0 ? 0 : (selectedColumnCount / totalSelectedValuesForRow) * 100;

                  return (
                    <React.Fragment key={columnIndex}>
                      <td >
                        {selectedColumnCount} - {percentage.toFixed(0)}%
                      </td>
                    </React.Fragment>
                  );
                })}
                {JSON.parse(question.answer)[2] === true && <td >0%</td>}
              </tr>
            );
          });
        })()}
      </tbody>
    </table>
    {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
  </div>
)}


            {/* multiple choice */}
            {question.question_type === 12 && (
              <div>
                <div className="multipleanserdisplay">
                  <div className="headprogress">
                    {/* Render progress bars */}
                    {Object.entries(
                      calculatePercentageForQuestionMultiple(responses, question.questionid, question.answer)
                        .percentagesNew
                    )
                      .slice(0, showAllAnswers ? undefined : 10)
                      .map(([responseValue, { percentageNew, isEditedNew }], index) => (
                        <div key={index}>
                          <div className="progress" role="progressbar" aria-label={`${responseValue} example`}>
                            <div
                              className={`progress-bar ${isEditedNew ? 'edited' : ''}`}
                              style={{
                                width: `${percentageNew.toFixed(2)}%`, // Apply the percentage as the width
                                backgroundColor: getColorForSegment(percentageNew),
                              }}
                              aria-valuenow={percentageNew}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {isEditedNew
                                ? `${responseValue} - ${percentageNew.toFixed(2)}%`
                                : `Deleted - ${percentageNew.toFixed(2)}%`}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <table className="tableofShortAns text-muted">
  <thead>
    <tr>
      <th>No.</th>
      <th>Date</th>
      <th>Answer</th>
    </tr>
  </thead>
  <tbody>
    {responses
      .filter((response) => response.questionid === question.questionid)
      .slice(0, showAllAnswers ? undefined : 10) // Show only first 10 answers if showAllAnswers is false
      .map((response, idx) => (
        <tr key={idx}>
          <td>{idx + 1}</td>
          {/* You can format the date as needed */}
          <td>{renderLocalTime(response.created_date)}</td>
          <td>
            {(() => {
              try {
                const responseData = JSON.parse(response.response_text);
                if (Array.isArray(responseData)) {
                  return responseData
                    .filter((value) => value && value.trim() !== '')
                    .join(', ');
                }
              } catch (error) {
                console.error('Error parsing JSON:', error);
                return 'Error parsing JSON';
              }
              return 'Invalid JSON data';
            })()}
          </td>
        </tr>
      ))}
    {/* Add more rows as needed */}
  </tbody>
</table>

                 
                </div>
                <div>
                {responses.filter((response) => response.questionid === question.questionid).length > 10 && !showAllAnswers && (
      // Show "See all answers" button only if not already showing all answers and more than 10 responses
      <footer className="clearfix">
        <nav className="result-item-nav">
          <NavLink className="icon-link result-details-button" to={`/AllanswerLayout/${question.questionid}`}>
            See all answers <span className="icon icon-right" />
          </NavLink>
        </nav>
      </footer>
    )}
                </div>
              </div>
            )}



            {/* Add additional conditions for other question types */}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ResultQuestions;
