import { createStore } from 'redux';

// Action types
const SHOW_DIV = 'SHOW_DIV';
const SHOW_EDIT_PASS_MODAL = 'SHOW_EDIT_PASS_MODAL';
const CONFERMPSW_MODAL_CLOSE = 'CONFERMPSW_MODAL_CLOSE';
const SHOW_UPDATE_EMAIL_MODAL = 'SHOW_UPDATE_EMAIL_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const CONFERMPSW_MODAL = 'CONFERMPSW_MODAL';
const CLOSE_UPDATE_EMAIL_MODAL = 'CLOSE_UPDATE_EMAIL_MODAL';
const CLOSE_ERROR = 'CLOSE_ERROR';
const CREATE_SURVEY = 'CREATE_SURVEY';
const CREATE_SURVEY_POPUP = 'CREATE_SURVEY_POPUP';
const CLOSE_CREATE_SURVEY_POPUP = 'CLOSE_CREATE_SURVEY_POPUP';
const OPEN_QUESTION_DATA = 'OPEN_QUESTION_DATA';
const OPEN_QUESTION_DATA_CLOSE = 'OPEN_QUESTION_DATA_CLOSE';
const OPEN_CREATE_SURVEY = 'OPEN_CREATE_SURVEY';
const CLOSE_CREATE_SURVEY = 'CLOSE_CREATE_SURVEY';
const OPEN_CLICK_SURVEY = 'OPEN_CLICK_SURVEY';
const UPDATE_SURVEY_LIST = 'UPDATE_SURVEY_LIST';
const UPDATE_QUESTION_LIST = 'UPDATE_QUESTION_LIST';
const REFRESH_QUESTION_DATA = 'REFRESH_QUESTION_DATA';
const BACKDROP_LOADER = 'BACKDROP_LOADER';
const CLOSE_BACKDROP_LOADER = 'CLOSE_BACKDROP_LOADER';
const OPEN_CONFERMPSW_MODAL = 'OPEN_CONFERMPSW_MODAL';
const SET_BACKGROUND = 'SET_BACKGROUND';
const CREATE_QUESTION_ALERT = 'CREATE_QUESTION_ALERT';
const CREATE_QUESTION_ALERT_CLOSE = 'CREATE_QUESTION_ALERT_CLOSE';
const OPEN_SURVEY_FILTER = 'OPEN_SURVEY_FILTER';
const OPEN_SURVEY_FILTER_CLOSE = 'OPEN_SURVEY_FILTER_CLOSE';
const QUESTION_ORDER = 'OPEN_SURVEY_FILQUESTION_ORDERTER_CLOSE';
const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
const INCREASE_COUNT = 'INCREASE_COUNT';
const UPDATE_SURVEY_NAME = 'UPDATE_SURVEY_NAME';
const UPDATE_SURVEY_LOGO_DATA = 'UPDATE_SURVEY_LOGO_DATA';
const SHOW_AIQUESTION_MODAL = 'SHOW_AIQUESTION_MODAL';
const CLOSE_AIQUESTION_MODAL = 'CLOSE_AIQUESTION_MODAL';
const SHOW_AISURVEY_MODAL = 'SHOW_AISURVEY_MODAL';
const CLOSE_AISURVEY_MODAL  = 'CLOSE_AISURVEY_MODAL';
const SHOW_AISURVEYTITLE_MODAL = 'SHOW_AISURVEYTITLE_MODAL'
const CLOSE_AISURVEYTITLE_MODAL = 'CLOSE_AISURVEYTITLE_MODAL'
const SET_SURVEY_ID = 'SET_SURVEY_ID';
const CLEAR_SURVEY_ID = 'CLEAR_SURVEY_ID';
const SHOW_QUESTION_BRANCHING_MODAL = 'SHOW_QUESTION_BRANCHING_MODAL';
const CLOSE_QUESTION_BRANCHING_MODAL = 'CLOSE_QUESTION_BRANCHING_MODAL';
const EDIT_SHOW_QUESTION_BRANCHING_MODAL ='EDIT_SHOW_QUESTION_BRANCHING_MODAL';


// Initial state
const initialState = {
  isDivVisible: false,
  isEditModal: false,
  isshowConfermPsw: false,
  isEditEmailModal: false,
  updatequestionlist: null,
  showcreatsurveypopup: false,
  openQuestiondata: false,
  openSurvey: false,
  openedSurveyId: null,
  surveyList1: null,
  updatequestionid: null,
  refreshquestion: false,
  createQueAlert: false,
  showsurveyloaderbackdrop: false,
  background: null,
  togglesurveyfilter: false,
  questionorder: null,
  questionId: null,
  userData: '',
  createsurvey: false,
  isEditMode: false,
  usersurveyData: '',
  usersurveylogoData: '',
  count: 0,
  showAIquestion: false,
  showAISurvey: false,
  showAISurveyTitle: false,
  surveyId: null,
  showQuestionBranchingModal:false,
  isEditModeForRule:false,
  ruleId : null,
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BACKGROUND:
      return {
        ...state,
        background: action.payload,
      };

    case SHOW_DIV:
      return {
        ...state,
        isDivVisible: true,
      };

    case CLOSE_ERROR:
      return {
        ...state,
        isDivVisible: false,
      };

    case SHOW_EDIT_PASS_MODAL:
      return {
        ...state,
        isEditModal: true,
      };

    case SHOW_UPDATE_EMAIL_MODAL:
      return {
        ...state,
        isEditEmailModal: true,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        isEditModal: false,
      };

    case CONFERMPSW_MODAL:
      return {
        ...state,
        isshowConfermPsw: true,
      };
    case CONFERMPSW_MODAL_CLOSE:
      return {
        ...state,
        isshowConfermPsw: false,
      };
    case CREATE_QUESTION_ALERT:
      return {
        ...state,
        createQueAlert: true,
      };

    // case SET_SURVEY_TITLE:
    // return {
    //   ...state,
    //   surveyTitle: action.payload,
    // };

    case CREATE_QUESTION_ALERT_CLOSE:
      return {
        ...state,
        createQueAlert: false,
      };

    case OPEN_CONFERMPSW_MODAL:
      return {
        ...state,
        isConfermPswModal: true,
      };

    case CLOSE_UPDATE_EMAIL_MODAL:
      return {
        ...state,
        isEditEmailModal: false,
      };

    case CREATE_SURVEY:
      return {
        ...state,
        productCount: state.productCount + 1,
      };

    case CREATE_SURVEY_POPUP:
      console.log('Reducer: isEditMode', action.payload.isEditMode);
      return {
        ...state,
        showcreatsurveypopup: true,
        isEditMode: action.payload.isEditMode,
        ...(action.payload.isEditMode && { questionId: action.payload.surveyData.questionid }),
      };

    case CLOSE_CREATE_SURVEY_POPUP:
      return {
        ...state,
        showcreatsurveypopup: false,
        isEditMode: action.payload.isEditMode,
      };
    case OPEN_QUESTION_DATA:
      return {
        ...state,
        openQuestiondata: true,
        updatequestionid: action.payload,
      };
    case OPEN_QUESTION_DATA_CLOSE:
      return {
        ...state,
        openQuestiondata: false,
      };

    // case SET_QUESTION_TAB:
    // return {
    //   ...state,
    //   questiontab:true,
    // };
    case OPEN_CREATE_SURVEY:
      return {
        ...state,
        openSurvey: true,
      };
    case CLOSE_CREATE_SURVEY:
      return {
        ...state,
        openSurvey: false,
      };

    case OPEN_CLICK_SURVEY:
      return {
        ...state,
        openedSurveyId: action.payload,
      };

    case UPDATE_SURVEY_LIST:
      return {
        ...state,
        surveyList1: action.payload,
      };

    case UPDATE_QUESTION_LIST:
      return {
        ...state,
        updatequestionlist: state.updatequestionlist + 1,
      };
    // case UPDATE_QUESTION_LIST:
    //   return {
    //     ...state,
    //     updatequestionlist: action.payload,
    //   };
    case REFRESH_QUESTION_DATA:
      return {
        ...state,
        refreshquestion: true,
      };

    case BACKDROP_LOADER:
      return {
        ...state,
        showsurveyloaderbackdrop: true,
      };

    case CLOSE_BACKDROP_LOADER:
      return {
        ...state,
        showsurveyloaderbackdrop: false,
      };
    case OPEN_SURVEY_FILTER:
      return {
        ...state,
        togglesurveyfilter: true,
      };
    case OPEN_SURVEY_FILTER_CLOSE:
      return {
        ...state,
        togglesurveyfilter: false,
      };
    case UPDATE_USER_NAME:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_SURVEY_NAME:
      return {
        ...state,
        usersurveyData: action.payload,
      };

    case UPDATE_SURVEY_LOGO_DATA:
      return {
        ...state,
        usersurveylogoData: action.payload,
      };

    case INCREASE_COUNT:
      return {
        ...state,
        count: state.count + 1,
      };

    case SHOW_AIQUESTION_MODAL:
      return {
        ...state,
        showAIquestion: true,
      };

    case CLOSE_AIQUESTION_MODAL:
      return {
        ...state,
        showAIquestion: false,
      };

      case SHOW_AISURVEY_MODAL:
        return {
          ...state,
          showAISurvey: true,
        };
  
      case CLOSE_AISURVEY_MODAL:
        return {
          ...state,
          showAISurvey: false,
        };
      case SHOW_AISURVEYTITLE_MODAL:
        return {
          ...state,
          showAISurveyTitle: true,
        };
  
      case CLOSE_AISURVEYTITLE_MODAL:
        return {
          ...state,
          showAISurveyTitle: false,
        };
        case SET_SURVEY_ID:
          return {
            ...state,
            surveyId: action.payload,
          };
        case CLEAR_SURVEY_ID:
          return {
            ...state,
            surveyId: null,
          };

          case SHOW_QUESTION_BRANCHING_MODAL:
            return {
              ...state,
              showQuestionBranchingModal: true,
              isEditModeForRule: false,
              // ruleId: action.payload.rulesData.rule_id
            };
      
          case CLOSE_QUESTION_BRANCHING_MODAL:
            return {
              ...state,
              showQuestionBranchingModal: false,
              isEditModeForRule: false,
              ruleId: null
            };
      
          case EDIT_SHOW_QUESTION_BRANCHING_MODAL:
            return {
              ...state,
              showQuestionBranchingModal: true,
              isEditModeForRule: true,
              ruleId: action.payload.rulesData.rule_id
            };
      
    default:
      return state;
  }
};

// Store
const store = createStore(reducer, window.REDUX_DEVTOOLS_EXTENSION && window.REDUX_DEVTOOLS_EXTENSION());

export default store;
