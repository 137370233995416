// import React, { useState, useEffect } from 'react';
// import '../../Style/Custumization.css';
// import { RiFileAddLine } from 'react-icons/ri';
// import { BiLoader } from 'react-icons/bi';
// import { FaAffiliatetheme, FaIcon } from 'react-icons/fa';  // Combine the imports from 'react-icons/fa'
// import Box from '@mui/material/Box';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import { useParams } from 'react-router-dom';
// import PositionedSnackbar from '../../components/component-part/Alert';
// import Theme1 from '../../img/Theme1[pink].jpg';
// import Theme2 from '../../img/Theme2[blue].jpg';
// import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';

// const App = () => {
//   const { id } = useParams();
//   const [selectedFont, setSelectedFont] = useState('');
//   const [selectBackgroundColor,setselectBackgroundColor] = useState('');
//   const [fontcolor, setFontColor] = useState('');
//   const [selectColor, setColor] = useState('');
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [fontstyle, setFontStyle] = useState([]);
//   const [postimg, setPostBgImg] = useState(null);
//   const [selectedTab, setSelectedTab] = useState('desktop');
//   const [isloader, setLoader] = useState(false);
//   const fontFamilies = [
//     'Arial',
//     'Times New Roman',
//     'Verdana',
//     'Georgia',
//     'Courier New',
//     'Impact',
//     'Comic Sans MS',
//     'Trebuchet MS',
//     'Arial Black',
//     'Lucida Console',
//     'Helvetica',
//     'Palatino',
//     'Geneva',
//     'Tahoma',
//     'Cursive',
//     'Fantasy',
//     'Monospace',
//     'Sakal Marathi',
//     'Segoe UI',
//     'Playfair Display',
//     'Garamond',
//   ];
//   const [theme, setTheme] = useState('');
//   const [selectedImageFile, setSelectedImageFile] = useState(null);
//   const themeOptions = [
//     {
//       name: 'Theme 1',
//       backgroundImage:Theme1,
//       fontColor: '#ffffff',
//       fontFamily: 'Palatino',
//     },
//     {
//       name: 'Theme 2',
//       backgroundImage: Theme2,
//       fontColor: '#ffffff',
//       fontFamily: 'Playfair Display',
//     },
//   ];

//   useEffect(()=>{
//     setSelectedFont(fontstyle?.[0]);
//     setColor(fontstyle?.[1]);
//     setselectBackgroundColor(fontstyle?.[2]);
//   },[fontstyle])
//   const handleFontChange = (e) => {
//     setSelectedFont(e.target.value);
//   };
//   const handelColorset = (e) => {
//     setColor(e.target.value);
//   };
//   const handelColorsetBackground = (e) => {
//     setselectBackgroundColor(e.target.value);
//   };

//   const handleImageChange = (e) => {
//     setSelectedImage(URL.createObjectURL(e.target.files[0]));
//     setSelectedImageFile(e.target.files[0]);
//     setPostBgImg(e.target.files[0]);
//   };
//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };

//   const [survey, setSurveyList] = useState([]);
//   useEffect(() => {
//     const endpoint = API_ENDPOINTS.surveyListGET;
//     fetch(`${BASE_URL}${endpoint}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setSurveyList(data.data);
//       })
//       .catch((error) => console.error(error));
//   }, []);

//   useEffect(() => {
//     const getuserid = localStorage.getItem('uid');
//     const filteredData = survey.filter((item) => item.userid === getuserid);
//     const getBackgoundImg = filteredData.filter((bgimg) => bgimg.surveyid === id);
//     const getFontstyle = getBackgoundImg.map((fontstyle) => JSON.parse(fontstyle.fonts))
//     setFontStyle(getFontstyle[0])
//     setSelectedImage(getBackgoundImg.map((img) => img.background));
    
//     // if (!getFontstyle.length<0) {
//     //   SetFontFamily()
//     // }
//   }, [survey]);

//   const Updatesurveystyle = async (event) => {
//     setLoader(true);
//     event.preventDefault();

//     const uid = localStorage.getItem('uid');
//     const formData = new FormData();
//     formData.append('backgroundFile', postimg || selectedImage); 
//     formData.append('surveyid', id);
//     formData.append('isBackgound', true);
//     formData.append('userid', uid);

//     try {
//       const endpoint = API_ENDPOINTS.answerImagePOST;
//       const fontstyle = [selectedFont, selectColor,selectBackgroundColor];
//       const res = await fetch(`${BASE_URL}${endpoint}`, {
//         method: 'POST',
//         body: formData,
//       });
//       if (res.status === 200) {
//         const responseJson = await res.json();

//         try {
//           const endpoint = API_ENDPOINTS.surveyEdit;
//           const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//               fonts: JSON.stringify(fontstyle),
//               background: responseJson.background,
//             }),
//           });

//           if (response.status === 200) {
//             setLoader(false);
//             console.log('Survey font And Background Updated');
//           }
//         } catch (error) {
//           console.error('An error occurred while Updating Survey font And Background', error);
//         }
//       } else {
//         console.error('Error posting Image:', res.status);
//       }
//     } catch (error) {
//       console.error('Error posting Image:', error);
//     }
//   };
//   const successdeleteAlert = [
//     {
//       type: 'Customizations are applied on your survey.',
//     },
//   ];

//   const handleThemeChange = (event) => {
//     setTheme(event.target.value);
//     const selectedTheme = themeOptions.find((option) => option.name === event.target.value);
//     setSelectedFont(selectedTheme.fontFamily);
//     setColor(selectedTheme.fontColor);
//     setSelectedImage(selectedTheme.backgroundImage);
//   };

//   const desktopPreview = (
//     <div
//       className="desktop-preview"
//       style={{
//         fontSize: '30px',
//         fontFamily: selectedFont,
//         color: selectColor,
//         backgroundImage:`url(${selectedImage})`,
//         backgroundColor:`${selectedImage===null && selectBackgroundColor}` ,
//         backgroundSize: 'cover',
//         minHeight: '400px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'flex-start',
//         borderRadius: '10px',
//       }}
//     >
//       <div style={{ width: '50%', marginLeft: '30px' }}>
//         This Is Survey Name.
//         <div className="mt-3">
//           <p className="mb-0" style={{ fontSize: '16px', textAlign: 'start' }}>
//             1. What is the Name Of the World's Largest country?
//           </p>
//           <ul className="option ms-4">
//             <li>Japan</li>
//             <li>Japan</li>
//             <li>Japan</li>
//             <li>Japan</li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );

//   const mobilePreview = (
//     <div
//       className="Mobile-preview "
//       style={{
//         fontSize: '25px',
//         fontFamily: selectedFont,
//         backgroundImage: selectedImage
//           ? `url(${selectedImage}`
//           : `url('#1b1b1b')`,
//         backgroundColor:`${selectedImage===null && selectBackgroundColor}` ,
//         backgroundSize: 'cover',
//         minHeight: '500px',
//         maxHeight: '500px',
//         display: 'flex',
//         alignItems: 'flex-start',
//         justifyContent: 'flex-start',
//         color: selectColor,
//         backgroundPosition: 'center',
//         borderRadius: '10px',
//       }}
//     >
//       <div className="p-4" style={{ marginTop: '100px' }}>
//         This Is Survey Name.
//         <div className="mt-2 mobileQues">
//           <p className="mb-0">1. What is the Name Of the World's Largest country?</p>
//           <ul className="option ms-4">
//             <li>Japan</li>
//             <li>Japan</li>
//             <li>Japan</li>
//             <li>Japan</li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
//   const tabletpreview = (
//     <div
//       className="tablet-preview "
//       style={{
//         fontSize: '25px',
//         fontFamily: selectedFont,
//         backgroundImage: selectedImage
//           ? `url(${selectedImage}`
//           : `url("https://wallpapers.com/images/hd/4k-eagle-in-mountains-3hx4ybfus71fibgd.webp")`,
//         backgroundColor:`${selectedImage===null && selectBackgroundColor}` ,
//         backgroundSize: 'cover',
//         minHeight: '500px',
//         maxHeight: '500px',
//         display: 'flex',
//         alignItems: 'flex-start',
//         justifyContent: 'flex-start',
//         color: selectColor,
//         borderRadius: '10px',
//       }}
//     >
//       <div className="p-5" style={{ marginTop: '100px' }}>
//         This Is Survey Name.
//         <div className="mt-2 mobileQues">
//           <p className="mb-0">1. What is the Name Of the World's Largest country?</p>
//           <ul className="option ms-4">
//             <li>Japan</li>
//             <li>Japan</li>
//             <li>Japan</li>
//             <li>Japan</li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
//   return (
    
//     <div className="container " style={{marginLeft:'0px',paddingLeft:'0px'}} >
//       <div  className='iconcontainer ' >
//     <FaAffiliatetheme className='iconOfTab'  />
//     <h1 className='headingofTab' >Customizations</h1>
//     </div>
//       {isloader && <PositionedSnackbar alerttext={successdeleteAlert} />}
//       <div className="row">
//         <div className="col-8 col-md-8 col-lg-8 p-3">
//           <Tabs value={selectedTab} onChange={handleTabChange} centered>
//             <Tab label="Desktop" value="desktop" />
//             <Tab label="Mobile" value="mobile" />
//             <Tab label="tablet" value="tablet" />
//           </Tabs>

//           {selectedTab === 'desktop' && <div className="p-3 border rounded-4">{desktopPreview}</div>}
//           {selectedTab === 'mobile' && <div className="p-3 border rounded-4  Mobilepreview"> {mobilePreview} </div>}
//           {selectedTab === 'tablet' && <div className="p-3 border rounded-4 tabpreview"> {tabletpreview} </div>}
//         </div>
//         <div className="col-4 col-md-4 col-lg-4 p-3 position-relative ">
//           <div className="row">
//           <div className="col-12 col-md-12 col-lg-12" style={{marginTop:'60px'}}>
//               <h6> Select Background:</h6>
//               <div
//                 style={{
//                   position: 'relative',
//                   border: '2px solid #a7a7a7',
//                   borderRadius: '12px',
//                   borderStyle: 'dashed',
//                   background: '#fffce8',
//                   height: '80px',
//                   width: '180px',
//                 }}
//               >
//                 <input
//                   style={{
//                     cursor: 'pointer',
//                     opacity: '0',
//                     height: '100%',
//                     width: '100%',
//                     position: 'relative',
//                     zIndex: '1',
//                   }}
//                   type="file"
//                   onChange={handleImageChange}
//                   accept="image/*"
//                 />
//                 <RiFileAddLine
//                   style={{
//                     fontSize: '40px',
//                     color: '#8c8c8c',
//                     position: 'absolute',
//                     top: '50%',
//                     right: '50%',
//                     transform: 'translate(46%, -50%)',
//                   }}
//                 />
//               </div>
//             </div>
//             <div className="col-12 col-md-12 col-lg-12 mt-4">
//               <h6>Font Family:</h6>
//               <Box sx={{ minWidth: 120 }}>
//                 <FormControl fullWidth>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={selectedFont}
//                     onChange={handleFontChange}
//                     label=""
//                   >
//                     {fontFamilies.map((font, index) => (
//                       <MenuItem key={index} value={font}>
//                         {font}
//                       </MenuItem>
//                     ))}
//                   </Select>
                
//                 </FormControl>
          
//               </Box>
//             </div>
//             <div className="col-12 col-md-12 col-lg-12 mt-4">
//               <h6> Select Font color:</h6>
//               <div style={{ position: 'relative', border: '2px solid #a7a7a7', height: '30px', width: '50px' }}>
//                 <input
//                   style={{ height: '100%', width: '100%', position: 'relative', zIndex: '1' }}
//                   type="color"
//                   value={fontstyle?.[1]}
//                   onChange={handelColorset}
//                 />
//               </div>
           
//             </div>
//             <div className="col-12 col-md-12 col-lg-12 mt-4">
//               <h6> Select background color:</h6>
//               <div style={{ position: 'relative', border: '2px solid #a7a7a7', height: '30px', width: '50px' }}>
//                 <input
//                   style={{ height: '100%', width: '100%', position: 'relative', zIndex: '1' }}
//                   type="color"
//                   value={fontstyle?.[2]}
//                   onChange={handelColorsetBackground}
//                 />
//               </div>
           
//             </div>
//                {/* Theme dropdown */}
//           <div className="col-12 col-md-12 col-lg-12 mt-4">
//             <h6>Select Theme:</h6>
//             <Box sx={{ minWidth: 120 }}>
//               <FormControl fullWidth>
//                 <Select
//                   labelId="demo-simple-select-label-theme"
//                   id="demo-simple-select-theme"
//                   value={theme}
//                   onChange={handleThemeChange}
//                   label="Select Theme"
//                 >
//                   <MenuItem value="Theme 1">Theme 1</MenuItem>
//                   <MenuItem value="Theme 2">Theme 2</MenuItem>
//                 </Select>
//               </FormControl>
//             </Box>
//           </div>


//             <button
//             onClick={Updatesurveystyle}
//             className="btn btn-primary me-2 mb-2 mb-lg-0  bottom-0"
//             type="button" style={{marginTop:'20px'}}
//           >
//             Update {isloader && <BiLoader className="BiLoader ms-1 " />}
//           </button>
//           </div>
        
//         </div>
//       </div>
//     </div>
//   );
// };

// export default App;
import React, { useState, useEffect } from 'react';
import '../../Style/Custumization.css';
import { FaAffiliatetheme } from 'react-icons/fa';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import PositionedSnackbar from '../../components/component-part/Alert';
import { BASE_URL, API_ENDPOINTS } from '../../api_folder/config';

const App = () => {
  const { id } = useParams();
  const [selectedFont, setSelectedFont] = useState('');
  const [selectBackgroundColor, setSelectBackgroundColor] = useState('#ffffff');
  const [fontColor, setFontColor] = useState('#000000');
  const [fontStyle, setFontStyle] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [selectedTab, setSelectedTab] = useState('desktop');
  const [isLoader, setLoader] = useState(false);

  const fontFamilies = [
    'Arial', 'Times New Roman', 'Verdana', 'Georgia', 'Courier New', 'Impact',
    'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Lucida Console', 'Helvetica',
    'Palatino', 'Geneva', 'Tahoma', 'Cursive', 'Fantasy', 'Monospace',
    'Sakal Marathi', 'Segoe UI', 'Playfair Display', 'Garamond'
  ];

  const customise  = [
    {
      name: 'Theme 1',
      backgroundColor: '#C9B5C8', 
      fontFamily: 'Verdana',
      fontColor: '#442A54' 
    },
    {
      name: 'Theme 2',
      backgroundColor: '#A3EBB1',  
      fontFamily: 'Monospace',
      fontColor: '#116530'
    },
    {
      name: 'Theme 3',
      backgroundColor: '#B1D4E0', 
      fontFamily: 'Segoe UI',
      fontColor: '#0C2D48'
    },
    {
      name: 'Theme 4',
      backgroundColor: '#F3CA40',  
      fontFamily: 'Trebuchet MS',
      fontColor: '#616569'
    },
    {
      name: 'Theme 5',
      backgroundColor: '#A75E3C',  
      fontFamily: 'Trebuchet MS',
      fontColor: '#E1DFDE'
    },
  ];

  useEffect(() => {
    setSelectedFont(fontStyle?.[0] || 'Arial');
    setFontColor(fontStyle?.[1] || '#000000');
    setSelectBackgroundColor(fontStyle?.[2] || '#ffffff');
  }, [fontStyle]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme) {
      const parsedTheme = JSON.parse(savedTheme);
      setSelectedFont(parsedTheme.fontFamily);
      setFontColor(parsedTheme.fontColor);
      setSelectBackgroundColor(parsedTheme.backgroundColor);
      setSelectedTheme(parsedTheme.name);
    }
  }, []);

  const handleFontChange = (e) => {
    setSelectedFont(e.target.value);
  };

  const handleColorChange = (e) => {
    setFontColor(e.target.value);
  };

  const handleBackgroundColorChange = (e) => {
    setSelectBackgroundColor(e.target.value);
  };

 const handleThemeChange = (e) => {
    const selectedTheme = customise .find(theme => theme.name === e.target.value);
    if (selectedTheme) {
      setSelectedFont(selectedTheme.fontFamily);
      setFontColor(selectedTheme.fontColor);
      setSelectBackgroundColor(selectedTheme.backgroundColor);
      setSelectedTheme(selectedTheme.name);
      localStorage.setItem('selectedTheme', JSON.stringify(selectedTheme));
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [survey, setSurveyList] = useState([]);
  useEffect(() => {
    const endpoint = API_ENDPOINTS.surveyListGET;
    fetch(`${BASE_URL}${endpoint}`)
      .then((response) => response.json())
      .then((data) => {
        setSurveyList(data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const getUserId = localStorage.getItem('uid');
    const filteredData = survey.filter((item) => item.userid === getUserId);
    const getBackgroundImg = filteredData.filter((bgimg) => bgimg.surveyid === id);
    const getFontStyle = getBackgroundImg.map((fontStyle) => JSON.parse(fontStyle.fonts));
    setFontStyle(getFontStyle[0]);
  }, [survey]);

  const updateSurveyStyle = async (event) => {
    setLoader(true);
    event.preventDefault();

    const uid = localStorage.getItem('uid');
    const fontStyle = [selectedFont, fontColor, selectBackgroundColor];

    try {
      const endpoint = API_ENDPOINTS.surveyEdit;
      const response = await fetch(`${BASE_URL}${endpoint}?surveyid=${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fonts: JSON.stringify(fontStyle),
        }),
      });

      if (response.status === 200) {
        setLoader(false);
        console.log('Survey font and background color updated');
      }
    } catch (error) {
      console.error('An error occurred while updating survey font and background color', error);
    }
  };

  const successDeleteAlert = [
    {
      type: 'Customizations are applied on your survey.',
    },
  ];

  const desktopPreview = (
    <div
      className="desktop-preview"
      style={{
        fontSize: '30px',
        fontFamily: selectedFont,
        color: fontColor,
        backgroundColor: selectBackgroundColor,
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: '10px',
      }}
    >
      <div style={{ width: '50%', marginLeft: '30px' }}>
        This Is Survey Name.
        <div className="mt-3">
          <p className="mb-0" style={{ fontSize: '16px', textAlign: 'start' }}>
            1. What is the Name Of the World's Largest country?
          </p>
          <ul className="option ms-4">
            <li>Japan</li>
            <li>Japan</li>
            <li>Japan</li>
            <li>Japan</li>
          </ul>
        </div>
      </div>
    </div>
  );

  const mobilePreview = (
    <div
      className="mobile-preview"
      style={{
        fontSize: '25px',
        fontFamily: selectedFont,
        backgroundColor: selectBackgroundColor,
        minHeight: '500px',
        maxHeight: '500px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        color: fontColor,
        borderRadius: '10px',
      }}
    >
      <div className="p-4" style={{ marginTop: '100px' }}>
        This Is Survey Name.
        <div className="mt-2 mobileQues">
          <p className="mb-0">1. What is the Name Of the World's Largest country?</p>
          <ul className="option ms-4">
            <li>Japan</li>
            <li>Japan</li>
            <li>Japan</li>
            <li>Japan</li>
          </ul>
        </div>
      </div>
    </div>
  );

  const tabletPreview = (
    <div
      className="tablet-preview"
      style={{
        fontSize: '25px',
        fontFamily: selectedFont,
        backgroundColor: selectBackgroundColor,
        minHeight: '500px',
        maxHeight: '500px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        color: fontColor,
        borderRadius: '10px',
      }}
    >
      <div className="p-5" style={{ marginTop: '100px' }}>
        This Is Survey Name.
        <div className="mt-2 mobileQues">
          <p className="mb-0">1. What is the Name Of the World's Largest country?</p>
          <ul className="option ms-4">
            <li>Japan</li>
            <li>Japan</li>
            <li>Japan</li>
            <li>Japan</li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container" style={{ marginLeft: '0px', paddingLeft: '0px' }}>
      <div className="iconcontainer">
        <FaAffiliatetheme className="iconOfTab" />
        <h1 className="headingofTab">Customizations</h1>
      </div>
      {isLoader && <PositionedSnackbar alerttext={successDeleteAlert} />}
      <div className="row">
        <div className="col-8 col-md-8 col-lg-8 p-3">
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab label="Desktop" value="desktop" />
            <Tab label="Mobile" value="mobile" />
            <Tab label="Tablet" value="tablet" />
          </Tabs>

          {selectedTab === 'desktop' && <div className="p-3 border rounded-4">{desktopPreview}</div>}
          {selectedTab === 'mobile' && <div className="p-3 border rounded-4 Mobilepreview">{mobilePreview}</div>}
          {selectedTab === 'tablet' && <div className="p-3 border rounded-4 tabpreview">{tabletPreview}</div>}
        </div>
        <div className="col-4 col-md-4 col-lg-4 p-3 position-relative">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 mt-4">
              <h6>Select Theme:</h6>
              <FormControl fullWidth>
                <Select value={selectedTheme} onChange={handleThemeChange} displayEmpty>
                  <MenuItem value="" disabled>Select Theme</MenuItem>
                  {customise .map((theme, index) => (
                    <MenuItem key={index} value={theme.name}>
                      {theme.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-12 col-lg-12 mt-4">
              <h6>Select Font family:</h6>
              <FormControl fullWidth>
                <Select value={selectedFont} onChange={handleFontChange} displayEmpty>
                  <MenuItem value="" disabled>Select Font Family</MenuItem>
                  {fontFamilies.map((font, index) => (
                    <MenuItem key={index} value={font}>
                      {font}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-12 col-lg-12 mt-4">
              <h6>Select Font color:</h6>
              <div style={{ position: 'relative', border: '2px solid #a7a7a7', height: '30px', width: '50px' }}>
                <input
                  style={{ height: '100%', width: '100%', position: 'relative' }}
                  type="color"
                  value={fontColor}
                  onChange={handleColorChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 mt-4">
              <h6>Select Background color:</h6>
              <div style={{ position: 'relative', border: '2px solid #a7a7a7', height: '30px', width: '50px' }}>
                <input
                  style={{ height: '100%', width: '100%', position: 'relative' }}
                  type="color"
                  value={selectBackgroundColor}
                  onChange={handleBackgroundColorChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 mt-4">
              <Button className="bgButton" color="success" variant="contained" onClick={updateSurveyStyle} style={{width:'100%'}}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
