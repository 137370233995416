

import React from 'react'
import General from './Generalcomponent'

const NotificationScreen = () => {
  const formdata = [
    {
      mainheading: 'NotificationScreen Setting',
      subheading: ' Configure the main settings for your survey.',
      surveytitle: 'Survey Title*',
      // inputtype:"number",
      Surveylogo: 'Survey Logo',
      Display: 'Display',
      option1: 'One Question at time',
      option2: 'All Question at once',
      Language: 'Language',
      Languagedropdown: 'Text Message',
      Language1: 'English',
      Language2: 'French',
      Language3: 'Russian',
      logourl: 'Logo Click URL',
      Categories: 'Categories',
      Save: 'Save Settings',
      input15:'d-none',
      input12:'d-none',
      input3:'d-none',
      input4:'d-none',
      input13:'d-none',
      input14:'d-none',
      input7:'d-none',
      input8:'d-none',
    },
  ];
  return (
    <div>
      <General formdata={formdata}/>
    </div>
  )
}

export default NotificationScreen